import { Sale } from '@apps/card/routes/CardCustomizer/useSalesConfig';
import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useSaleColor } from '../utils/useSaleColor';

type CardTileDiscountedPriceLabelProps = {
  discountedPrice?: string;
  preDiscountPrice: string;
  formatString?: string;
  showCode?: boolean;
  sale: Sale;
  alignment?: 'flex-start' | 'center';
};

export const CardTileDiscountedPriceLabel = (props: CardTileDiscountedPriceLabelProps) => {
  const { discountedPrice, showCode = true, formatString, sale, alignment, preDiscountPrice } = props;
  const saleColor = useSaleColor();

  return (
    <Flex flexDirection="column" gap="5px" justifyContent="flex-start" alignItems="flex-start">
      <Flex flexDirection="row" gap="4px" justifyContent={alignment ?? 'center'} alignItems="center" width="100%">
        <TextV2 typographyVariant="label2">{formatString ?? 'From'} </TextV2>
        <TextV2 as="span" textDecoration="line-through" typographyVariant="label2">
          {preDiscountPrice}
        </TextV2>
        <TextV2 as="span" color={saleColor} textAlign="center" typographyVariant="hed4">
          {discountedPrice}
        </TextV2>
      </Flex>

      {showCode && (
        <TextV2 as="span" color={saleColor} typographyVariant="hed2" fontSize="14px">
          Use code:{' '}
          <TextV2
            as="a"
            color={saleColor}
            typographyVariant="hed2"
            fontSize="14px"
            textDecoration="none"
            _hover={{ textDecoration: 'underline' }}
            href="https://withjoy.com/help/en/articles/9312890-paper-and-invitations-promotions-and-discounts?srsltid=AfmBOoqodWdx4sZJJ0623p5wzZNRUo7nLcnz3emL1A543KiIZeT4kD5v"
          >
            {sale?.code}
          </TextV2>
        </TextV2>
      )}
    </Flex>
  );
};
