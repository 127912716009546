import React, { LegacyRef, useRef } from 'react';
import { OptionType, SelectV1 } from '@withjoy/joykit';
import { useEffect, useMemo, useState } from 'react';
import { withWindow } from '@shared/utils/withWindow';
import { CloseIcon } from '@shared/components/LocationInput/LocationInput.styles';
import { debounce } from 'lodash-es';
import { HotelProduct, MapMarker } from '@withjoy/joykit/icons';
import { FormikProps } from 'formik';
import { getPlaceInfoBasic } from '@shared/core/places';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { addAction, addError } from '@shared/utils/logger';
import { usePreDefinedGooglePlaces } from '@shared/utils/hooks/usePreDefinedGooglePlaces';

export function useDebouncedValue<T>(wait: number = 500) {
  const [value, setValue] = useState<T>();

  const throttleFn = useMemo(() => debounce(setValue, wait), [wait]);

  return {
    setValue: throttleFn,
    value: value
  };
}

export interface Location {
  place: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  detailedPlace?: string;
  country?: string;
  placeType?: string[];
  address2?: string;
  stateRegionProvince?: string;
  city?: string;
  postalCode?: string;
}

export type LocationInputProps<T> = Readonly<{
  allowCustomPlace?: boolean;
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  defaultValue?: string;
  formik?: FormikProps<T>;
  handleChange?: (val: string) => void;
  iconSize?: number;
  includeCloseIcon?: boolean;
  includeHotelIcon?: boolean;
  includeMapIcon?: boolean;
  name: string;
  noBorder?: boolean;
  noOptionsMessage?: string;
  onLocationChange?: (val: Location | null) => void;
  onLocationOpen?: () => void;
  onInputChange?: (val: string) => void;
  placeholder?: string;
  placePredictionTypes?: string[];
  refInput?: LegacyRef<SelectV1<OptionType>>;
  telemetryPlacesSearched?: (input: string, placeResults: string[]) => void;
  telemetryPlaceSelected?: (input: string, placeSelected: string, locationAutocompleteRequestsMade: number) => void;
  value?: Location | null;
  loading?: boolean;
}>;

const placeToOption = (place: google.maps.places.AutocompletePrediction): OptionType => ({ label: place.description, value: place.place_id });

export const useLocationInput = <T extends {}>({
  onLocationChange,
  value,
  onLocationOpen,
  includeCloseIcon = true,
  includeMapIcon = false,
  includeHotelIcon = false,
  placeholder,
  placePredictionTypes = ['(cities)'],
  formik,
  defaultValue,
  allowCustomPlace = true,
  componentRestrictions = undefined,
  telemetryPlacesSearched = undefined,
  telemetryPlaceSelected = undefined,
  iconSize,
  onInputChange
}: LocationInputProps<T>) => {
  const autocompleteService = useMemo(() => withWindow(() => new window.google.maps.places.AutocompleteService(), undefined), []);
  const { preDefinedPlacesEnabled, getSearchPreDefinedPlaces, getPreDefinedPlaceById } = usePreDefinedGooglePlaces();

  // Log results if searched for place but not selected any.
  const selectedFromAutocompleteRef = useRef<{ isSelected: boolean; input?: String; selectOptions: OptionType[] }>({ isSelected: true, input: defaultValue, selectOptions: [] });
  // Prevent unwanted autocomplete api call, After select location from autocomplete list. And make api call only after manually change value from input field.
  const [isInputUpdatedFromApi, setIsInputUpdatedFromApi] = useState(false);
  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);
  const [autocompleteQueriesMade, setAutocompleteQueriesMade] = useState<number>(0);
  const { value: debouncedValue, setValue } = useDebouncedValue<string>();
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? '');
  const iconRight =
    value && includeCloseIcon ? (
      <CloseIcon
        size="sm"
        onClick={() => {
          setInputValue('');
          onLocationChange?.(null);
          setSelectOptions([]);
        }}
      />
    ) : (
      // replace fallback search icon with empty fragment
      <></>
    );

  useEffect(() => {
    setInputValue(defaultValue ?? '');
  }, [defaultValue]);

  useEffect(() => {
    return () => {
      if (!selectedFromAutocompleteRef.current.isSelected) {
        addAction('LocationAutocompleteNotSelected', {
          context: 'LocationInput',
          input: selectedFromAutocompleteRef.current.input,
          places: selectedFromAutocompleteRef.current.selectOptions.map(place => place.label)
        });
      }
    };
  }, [selectOptions]);

  const iconLeft = includeHotelIcon ? <HotelProduct size={iconSize || 'md'} /> : includeMapIcon ? <MapMarker size={iconSize || 'md'} /> : undefined;

  useEffect(() => {
    if (!autocompleteService || !debouncedValue || debouncedValue.length < 3 || isInputUpdatedFromApi) {
      return;
    }

    const placePredictionsParams: google.maps.places.AutocompletionRequest = {
      input: debouncedValue,
      types: placePredictionTypes
    };

    if (componentRestrictions) {
      placePredictionsParams.componentRestrictions = componentRestrictions;
    }

    autocompleteService.getPlacePredictions(placePredictionsParams, (places, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        addError('LocationAutocompleteFailed', { context: 'LocationInput', status });
        setSelectOptions([]);
        return;
      }

      addAction('LocationAutocomplete', { context: 'LocationInput', status, input: debouncedValue, places: places?.map(place => place.description) || [] });
      telemetryPlacesSearched?.(debouncedValue, places?.map(place => place.description) || []);

      const placesPredictions = places?.map(placeToOption) || [];
      if (preDefinedPlacesEnabled) {
        const results = getSearchPreDefinedPlaces(debouncedValue, placePredictionTypes);
        if (results.length) {
          results.forEach(({ placeId, name, address, formattedAddress, shouldOverrideExisting }) => {
            if (!name || !address) return;
            const placePredictionIndex = placesPredictions.findIndex(prediction => prediction.value === placeId);
            if (placePredictionIndex !== -1) {
              if (shouldOverrideExisting) {
                placesPredictions[placePredictionIndex] = { value: placeId, label: formattedAddress || `${name}, ${address}` };
              }
            } else {
              placesPredictions.push({ value: placeId, label: formattedAddress || `${name}, ${address}` });
            }
          });
        }
      }
      setSelectOptions(placesPredictions);
      // To make delay to prevent log for recent request.
      setTimeout(() => {
        selectedFromAutocompleteRef.current = {
          isSelected: false,
          input: debouncedValue,
          selectOptions: placesPredictions
        };
      }, 500);
    });
    setAutocompleteQueriesMade(prev => prev + 1);
  }, [debouncedValue, autocompleteService]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputValue = (value: string) => {
    formik?.setFieldValue('locationValue', value);
    setValue(value);
    setInputValue(value);
    onInputChange?.(value);
    setIsInputUpdatedFromApi(false);
  };

  const handleChange = (option: OptionType | null): void => {
    setIsInputUpdatedFromApi(true);
    selectedFromAutocompleteRef.current.isSelected = true;
    if (!option) {
      setInputValue('');
      onLocationChange?.(null);
      return;
    }

    const { value, label } = option;

    const isPredictedPlace = selectOptions.some(v => v.value === value);

    if (!isPredictedPlace) {
      setInputValue(value);
      onLocationChange?.({ place: value });
      addAction('LocationAutocompleteRequestMade', { context: 'LocationInput', input: debouncedValue, place: '', autocompleteQueriesMade });
      telemetryPlaceSelected?.(debouncedValue || '', '', autocompleteQueriesMade);
      return;
    }

    if (preDefinedPlacesEnabled) {
      const preDefinedPlace = getPreDefinedPlaceById(value);
      if (preDefinedPlace && preDefinedPlace.shouldOverrideExisting) {
        telemetryPlaceSelected?.(debouncedValue || '', preDefinedPlace.formattedAddress || preDefinedPlace.address, autocompleteQueriesMade);
        addAction('LocationAutocompleteRequestMade', {
          context: 'LocationInput',
          input: debouncedValue,
          place: preDefinedPlace.formattedAddress || preDefinedPlace.address,
          autocompleteQueriesMade
        });
        onLocationChange?.({
          ...preDefinedPlace,
          detailedPlace: label,
          placeType: preDefinedPlace.placeTypes,
          place: preDefinedPlace.formattedAddress || ''
        });
        return;
      }
    }
    getPlaceInfoBasic(value).then(place => {
      if (!place) {
        if (preDefinedPlacesEnabled) {
          const preDefinedPlace = getPreDefinedPlaceById(value);
          if (preDefinedPlace) {
            telemetryPlaceSelected?.(debouncedValue || '', preDefinedPlace.formattedAddress || preDefinedPlace.address, autocompleteQueriesMade);
            addAction('LocationAutocompleteRequestMade', {
              context: 'LocationInput',
              input: debouncedValue,
              place: preDefinedPlace.formattedAddress || preDefinedPlace.address,
              autocompleteQueriesMade
            });
            onLocationChange?.({
              ...preDefinedPlace,
              detailedPlace: label,
              placeType: preDefinedPlace.placeTypes,
              place: preDefinedPlace.formattedAddress || ''
            });
          }
        }
        addError('LocationDetailsFailed', { context: 'LocationInput', message: 'No place found' });
        return;
      }
      const lat = place.location.latitude;
      const lng = place.location.longitude;
      const countryComponent = place.addressComponents.find(component => component.types.includes('country'));
      const address2Component =
        place?.addressComponents?.find(component => component.types.includes('administrative_area_level_2')) ||
        place?.addressComponents?.find(component => component.types.includes('administrative_area_level_3'));
      const stateRegionProvinceComponent = place?.addressComponents?.find(component => component.types.includes('administrative_area_level_1'));
      const postalCodeComponent = place?.addressComponents?.find(component => component.types.includes('postal_code'));
      const localityComponent = place?.addressComponents?.find(component => component.types.includes('locality'));

      addAction('LocationAutocompleteRequestMade', { context: 'LocationInput', input: debouncedValue, place: place.formattedAddress, autocompleteQueriesMade });
      telemetryPlaceSelected?.(debouncedValue || '', place.formattedAddress || '', autocompleteQueriesMade);

      onLocationChange?.({
        place: place.formattedAddress,
        lat,
        lng,
        placeId: place.id,
        detailedPlace: label,
        country: countryComponent?.longText ?? '',
        placeType: place.types,
        address2: address2Component?.longText ?? '',
        stateRegionProvince: stateRegionProvinceComponent?.longText ?? '',
        city: localityComponent?.longText ?? '',
        postalCode: postalCodeComponent?.longText ?? ''
      });
    });
  };

  // Prevent unwanted autocomplete api call, when edit form with pre-filled value. And make api call, when click on autocomplete input field.
  const handleOnLocationOpen = useEventCallback(() => {
    if (inputValue) {
      setValue(inputValue);
    }
    onLocationOpen?.();
  });

  return {
    options: [...(allowCustomPlace && inputValue ? [{ label: `Use "${inputValue}"`, value: inputValue }] : []), ...selectOptions],
    handleChange,
    handleInputValue,
    onLocationOpen: handleOnLocationOpen,
    iconRight,
    iconLeft,
    inputValue,
    placeholder,
    placePredictionTypes
  };
};
