import React, { FC, useEffect, useRef, useState } from 'react';
import { Flex } from '@withjoy/joykit';
import { PagePhotoSettingsProps } from './FocusPointEditor.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { EventPageType, SetAssetPayload, useGetPageIdsQuery, useSetEventPageAssetMutation } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useFilestack } from '@shared/utils/filestack';
import { TempSpinner } from '../TempSpinner';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';
import { findMatchingPageType } from './utils';
import { EditorConsole } from './EditorConsole';

export const PagePhotoSettings: FC<PagePhotoSettingsProps> = props => {
  const [isLoadingPhoto, setLoadingPhoto] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.imageData?.url);
  const [uploadedImageData, setUploadedImageData] = useState<SetAssetPayload>();

  const isOpenFileStack = useRef(false);
  const isImageUploaded = useRef(!!props.imageData?.url);

  const { eventInfo, eventHandle } = useEventInfo();
  const telemetry = useFocusPointEditorTelemetry();

  const { data: pageIds } = useGetPageIdsQuery({ variables: { name: eventHandle || '' }, batchMode: 'fast', skip: !eventHandle });

  const [setEventPageAssetMutation, { loading: isUpdatingEventPhoto }] = useSetEventPageAssetMutation();

  const { open: openFilePicker, getPhotoPreviewUrl } = useFilestack({
    containerId: eventInfo?.eventFirebaseId || '',
    accept: 'image/*',
    onUploadDone: async (res): Promise<void> => {
      setLoadingPhoto(true);
      const { filename, mimetype, size, url } = res.filesUploaded[0];
      const previewUrl = await getPhotoPreviewUrl({ url });
      isImageUploaded.current = !!previewUrl;
      const loadImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> =>
        new Promise(resolve => {
          const image = new Image();

          image.onload = () => {
            const height = image.height;
            const width = image.width;
            resolve({ width, height });
          };

          image.src = imageUrl;
        });

      const dimensions = await loadImageDimensions(previewUrl);
      setImageUrl(previewUrl);
      setUploadedImageData({ assetId: filename, mimeType: mimetype, fileSize: size, width: dimensions.width, height: dimensions.height });
      setLoadingPhoto(false);
    },
    onClose: () => {
      if (!isLoadingPhoto) isOpenFileStack.current = false;
      setTimeout(() => {
        props.onFileStackClose && props.onFileStackClose(!!isImageUploaded.current);
      }, 500);
    },
    maxFiles: 1
  });

  useEffect(() => {
    if (props.isOpen) {
      telemetry.photoEditorOpened(props.telemetryCategory, 'opened');
    }
    if (props.isOpen && !props.imageData?.url && !isOpenFileStack.current) {
      openFilePicker();
      isOpenFileStack.current = true;
    } else if (!props.isOpen) {
      isOpenFileStack.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleSaveUploadedPhoto = async () => {
    if (uploadedImageData) {
      // Custom and Normal page photo have different end points, we have to maintain both in global level
      if (props.pageType !== EventPageType.custom) {
        const pageTypeToUpdate = findMatchingPageType(props.pageType);
        // TODO: https://withjoy.atlassian.net/browse/PRNT-2050
        const pageIdToUse = pageIds?.eventByName?.pages.find(page => page.type === pageTypeToUpdate)?.id || props.pageId;
        telemetry.pagePhotoUpdated(props.telemetryCategory, 'Update', 'succeeded', { pageType: pageTypeToUpdate, actualPageType: props.pageType });
        await setEventPageAssetMutation({
          variables: {
            id: pageIdToUse || '',
            payload: uploadedImageData
          },
          refetchQueries: ['WebsiteInfo'],
          awaitRefetchQueries: true
        });
      }
    }
  };

  const onCancelPhotoFocus = useEventCallback(() => {
    setImageUrl('');
    setUploadedImageData(undefined);
    props.handleOnCancel();
  });

  const imageData =
    imageUrl && uploadedImageData
      ? {
          id: 'id',
          height: uploadedImageData.height,
          width: uploadedImageData.width,
          url: imageUrl,
          layout: {
            alignX: props.imageData?.layout.alignX || 'center',
            alignY: props.imageData?.layout.alignY || 'center'
          }
        }
      : props.imageData;

  const shouldRender = !!imageData && !!eventInfo?.eventId;

  return shouldRender ? (
    <EditorConsole
      {...props}
      imageData={imageData}
      handleOnCancel={onCancelPhotoFocus}
      openFilePicker={openFilePicker}
      eventId={eventInfo.eventId}
      isLoadingPhoto={isLoadingPhoto}
      handleSaveUploadedPhoto={handleSaveUploadedPhoto}
      isUpdatingEventPhoto={isUpdatingEventPhoto}
      uploadedImageData={uploadedImageData}
      pageIdsData={pageIds?.eventByName?.pages || []}
      photoPresentation={props.photoPresentation}
    />
  ) : (
    <Flex height={pxToRem(300)}>
      <TempSpinner />
    </Flex>
  );
};
