import React from 'react';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { Seo } from '@shared/components/Seo';
import { useTranslation } from '@shared/core';

type GallerySeoProps = {
  currentCategory: StationeryTemplateCategoryEnum;
};

export const GallerySeo = ({ currentCategory }: GallerySeoProps) => {
  const { t } = useTranslation('stationery');
  const tPublicShopMeta = t('publicShop', 'meta');

  let title = tPublicShopMeta.fallback.title();
  let description = '';
  let image = '';

  if (currentCategory === StationeryTemplateCategoryEnum.saveTheDate) {
    title = tPublicShopMeta.saveTheDate.title();
    description = tPublicShopMeta.saveTheDate.description();
    image = 'https://withjoy.com/assets/public/stationery-og-save-the-date-2.png';
  }

  if (currentCategory === StationeryTemplateCategoryEnum.invitation) {
    title = tPublicShopMeta.invitation.title();
    description = tPublicShopMeta.invitation.description();
    image = 'https://withjoy.com/assets/public/stationery-og-invitations.png';
  }

  return <Seo title={title} description={description} ogImage={image} />;
};
