import { Box } from '@withjoy/joykit';
import { CardCarouselSlide } from '../../useCardCarouselValues';
import React from 'react';

type CarouselImageProps = {
  slide: CardCarouselSlide;
  isCarouselActive: boolean;
  isActiveSlide: boolean;
};

export const CarouselImage = (props: CarouselImageProps) => {
  const { slide } = props;

  if (slide.type !== 'image') {
    return null;
  }

  return <Box as="img" width="100%" height="100%" src={slide.src} objectFit="cover" objectPosition="center" />;
};
