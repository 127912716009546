import React, { useCallback, useRef, useState } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFeatureValue } from '@shared/core/featureFlags';
import { RestrictedInternationalShippingWarningDialog } from './RestrictedInternationalShippingWarningDialog';
import { StationeryTemplateIdentifiers } from './types';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { StationeryDraftFormat } from '@graphql/generated';

type RestrictedInternationalShippingWarningContext = {
  /**
   * If the user is restricted based on the printInternationalShipping and printDigitalInternationalShipping FF, show a warning dialog.
   * Else, call the nextDialogCallback.
   */
  checkIfUserIsRestricted: (
    draftFormat: StationeryDraftFormat,
    templateIdentifiers: StationeryTemplateIdentifiers,
    onShowWarningDialog: () => void,
    nextDialogCallback?: () => void
  ) => void;
};

const [Provider, useRestrictedInternationalShippingWarningContext] = createContext<RestrictedInternationalShippingWarningContext>({
  name: 'RestrictedInternationalShippingWarning'
});

const RestrictedInternationalShippingWarningProvider: React.FC<{ eventId: string | undefined }> = ({ children, eventId }) => {
  const { value: printValue } = useFeatureValue('printInternationalShipping');
  const { value: digitalValue } = useFeatureValue('printDigitalInternationalShipping');
  //TODO: split telemetry by draftFormat
  const { restrictedInternationalShippingWarningDialogConfirmClicked, restrictedInternationalShippingWarningDialogDismiss } = useCardTelemetry();
  const { isOpen: isOpenPrint, onOpen: onOpenPrint, onClose: onClosePrint } = useDisclosure();
  const { isOpen: isOpenDigital, onOpen: onOpenDigital, onClose: onCloseDigital } = useDisclosure();

  const isUserRestrictedForPrint = printValue === 'restricted';
  const isUserRestrictedForDigital = digitalValue === 'restricted';

  // Store the nextDialogCallback in a ref so that it can be called when the user
  // confirms they want to move forward in the warning dialog
  const nextDialogCallbackRef = useRef<(() => void) | null | undefined>(null);

  const [currentTemplateIdentifiers, setCurrentTemplateIdentifiers] = useState<StationeryTemplateIdentifiers | null>(null);

  const checkIfUserIsRestricted = useCallback<RestrictedInternationalShippingWarningContext['checkIfUserIsRestricted']>(
    (draftFormat, templateIdentifiers, onShowWarningDialog, nextDialogCallback) => {
      const isUserRestricted = draftFormat === StationeryDraftFormat.print ? isUserRestrictedForPrint : isUserRestrictedForDigital;
      // If the user is restricted, show the dialog
      if (isUserRestricted) {
        // Store the nextDialogCallback in a ref so that it can be called after the dialog is closed
        // and the user confirms they want to move forward
        nextDialogCallbackRef.current = nextDialogCallback;
        setCurrentTemplateIdentifiers(templateIdentifiers);
        onShowWarningDialog();
        draftFormat === StationeryDraftFormat.print ? onOpenPrint() : onOpenDigital();
        return;
      }

      // If the user is not restricted, move forward
      nextDialogCallback?.();
    },
    [isUserRestrictedForDigital, isUserRestrictedForPrint, onOpenPrint, onOpenDigital]
  );

  const handleOnClickPrint = useEventCallback(() => {
    onClosePrint();
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogConfirmClicked({ themeId: currentTemplateIdentifiers.themeId });
    // Call the nextDialogCallback that we collected when we determined that the user was restricted
    // and should see the dialog
    nextDialogCallbackRef.current?.();
  });

  const handleOnClickDigital = useEventCallback(() => {
    onCloseDigital();
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogConfirmClicked({ themeId: currentTemplateIdentifiers.themeId });
    // Call the nextDialogCallback that we collected when we determined that the user was restricted
    // and should see the dialog
    nextDialogCallbackRef.current?.();
  });

  const handleOnClosePrint = () => {
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogDismiss({ themeId: currentTemplateIdentifiers.themeId });
    onClosePrint();
  };

  const handleOnCloseDigital = () => {
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogDismiss({ themeId: currentTemplateIdentifiers.themeId });
    onCloseDigital();
  };

  return (
    <Provider value={{ checkIfUserIsRestricted }}>
      {children}
      <RestrictedInternationalShippingWarningDialog
        isOpen={isOpenPrint}
        templateIdentifiers={currentTemplateIdentifiers}
        handleOnClose={handleOnClosePrint}
        handleOnClick={handleOnClickPrint}
        draftFormat={StationeryDraftFormat.print}
      />
      <RestrictedInternationalShippingWarningDialog
        isOpen={isOpenDigital}
        templateIdentifiers={currentTemplateIdentifiers}
        handleOnClose={handleOnCloseDigital}
        handleOnClick={handleOnClickDigital}
        draftFormat={StationeryDraftFormat.digital}
      />
    </Provider>
  );
};

export { RestrictedInternationalShippingWarningProvider, useRestrictedInternationalShippingWarningContext };
