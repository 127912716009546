import qs from 'query-string';

export const FILTER_QS_PARSE_OPTIONS: qs.ParseOptions = { arrayFormat: 'separator', arrayFormatSeparator: '|' };

type SearchQueryParam =
  | {
      enabled: false;
      query: null;
    }
  | {
      enabled: true;
      query: string;
    };

export const deriveActiveSearchFromUrl = (url: string): SearchQueryParam => {
  const { query } = qs.parseUrl(url, FILTER_QS_PARSE_OPTIONS);
  const productSearchParam = query.ps;

  if (typeof productSearchParam === 'string') {
    return {
      enabled: true,
      query: productSearchParam
    };
  }

  return {
    enabled: false,
    query: null
  };
};
