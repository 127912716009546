import { CatalogStorefront, ProductHeroGradientMode, ProductHeroHorizontalAlignment, ProductHeroRedirectUrlType, ProductHeroTextColorMode } from '@graphql/generated';
import {
  ConfigByStorefront,
  ValuePropsTopBarData,
  ValuePropCardsData,
  BrandMarqueeData,
  DepartmentNavSectionData,
  OverviewBillboardCurations,
  ValuePropSectionLinks,
  OverviewHighlights,
  AddFromFriendsRegistryBannerData,
  AddFromFriendsRegistryHowItWorksData
} from './storefront.config.types';
import { v4 as uuid } from 'uuid';
import { HeroDataProps } from '../../routes/CatalogProductsIndex/components/ShopHeroSlider/components/Hero';

export const STATIC_HERO_AT_START: ConfigByStorefront<HeroDataProps> = {
  wedding: {
    id: '77625e9c-bf70-4f6f-94e7-621c8ab7662c',
    title: 'Meet the best registry on earth.',
    watchVideoUrl: null,
    gradientCustomColorValue: null,
    textCustomColorValue: null,
    storefront: CatalogStorefront.wedding,
    horizontalAlignment: ProductHeroHorizontalAlignment.left,
    gradientMode: ProductHeroGradientMode.dark,
    isOverlayGradient: true,
    textColorMode: ProductHeroTextColorMode.light,
    disableCtaOnAuth: true,
    cta: {
      text: 'See All Perks',
      publicUrlPath: 'https://withjoy.com/registry/',
      redirectUrlType: ProductHeroRedirectUrlType.external
    },
    ctaLink: {
      text: 'Create Your Registry',
      publicUrlPath: 'https://withjoy.com/createwedding?scenario=registry',
      redirectUrlType: ProductHeroRedirectUrlType.external
    },
    checklist: [
      'Shop the top brands, gift cards and experiences in one place.',
      'Create unlimited cash funds with zero fees.',
      'Add gifts from any store and share them with one link.'
    ],
    desktopMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/913035817?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-wedding/registry_wedding_desktop.png'
        }
      }
    },
    tabletMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/912365442?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-wedding/registry_wedding_tablet.png'
        }
      }
    },
    mobileMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/911740881?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-wedding/registry_wedding_mobile.png'
        }
      }
    }
  },
  baby: {
    id: '81bf22a5-8be0-4311-8432-ec3531ab340d',
    title: 'Meet the best registry on earth.',
    watchVideoUrl: null,
    gradientCustomColorValue: null,
    textCustomColorValue: null,
    storefront: CatalogStorefront.baby,
    horizontalAlignment: ProductHeroHorizontalAlignment.left,
    gradientMode: ProductHeroGradientMode.dark,
    isOverlayGradient: true,
    textColorMode: ProductHeroTextColorMode.light,
    cta: {
      text: 'See All Perks',
      publicUrlPath: 'https://withjoy.com/baby-registry/',
      redirectUrlType: ProductHeroRedirectUrlType.external
    },
    ctaLink: {
      text: 'Create Your Registry',
      publicUrlPath: 'https://withjoy.com/createbabyregistry?scenario=baby-registry',
      redirectUrlType: ProductHeroRedirectUrlType.external
    },
    checklist: [
      'Shop the top brands, gift cards and experiences in one place.',
      'Create unlimited cash funds with zero fees.',
      'Add gifts from any store and share them with one link.'
    ],
    desktopMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/913072781?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-baby/registry_baby_desktop.png'
        }
      }
    },
    tabletMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/911739502?badge=0&autopause=0&player_id=0&app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-baby/registry_baby_tablet.png'
        }
      }
    },
    mobileMedia: {
      __typename: 'ProductHeroVideo',
      video: {
        videoUrl: 'https://player.vimeo.com/video/912415983?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        fallbackImage: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/uvp-baby/registry_baby_mobile.png'
        }
      }
    }
  }
};
export const FALLBACK_HERO: ConfigByStorefront<HeroDataProps> = {
  wedding: {
    id: '4b92c0c0-ab11-4397-80c5-9f991caec763',
    title: 'Welcome to the Joy Wedding Shop.',
    watchVideoUrl: null,
    gradientCustomColorValue: null,
    textCustomColorValue: null,
    storefront: CatalogStorefront.wedding,
    horizontalAlignment: ProductHeroHorizontalAlignment.left,
    gradientMode: ProductHeroGradientMode.none,
    isOverlayGradient: true,
    textColorMode: ProductHeroTextColorMode.dark,
    cta: {
      text: 'Get Started',
      publicUrlPath: 'https://withjoy.com/createwedding?scenario=registry',
      redirectUrlType: ProductHeroRedirectUrlType.inApp
    },
    description: 'Enjoy a seamless registry experience with easy returns, free shipping, an exclusive 20% completion discount, and cash funds.',
    desktopMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_wedding-fallback_desktop.png'
        }
      }
    },
    tabletMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_wedding-fallback_tablet.png'
        }
      }
    },
    mobileMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_wedding-fallback_mobile.png'
        }
      }
    }
  },
  baby: {
    id: '1f226f28-7aec-4129-bbd4-51eadff3f843',
    eyebrow: 'Joy Baby Registry',
    title: 'Every little thing your baby needs.',
    watchVideoUrl: null,
    gradientCustomColorValue: null,
    textCustomColorValue: null,
    storefront: CatalogStorefront.baby,
    horizontalAlignment: ProductHeroHorizontalAlignment.right,
    gradientMode: ProductHeroGradientMode.none,
    textColorMode: ProductHeroTextColorMode.dark,
    cta: {
      text: 'Get Started',
      publicUrlPath: 'https://withjoy.com/createbabyregistry?scenario=baby-registry',
      redirectUrlType: ProductHeroRedirectUrlType.inApp
    },
    description: "Get exactly what you want, choosing items from our shop, your favorite stores, and even your friends' baby registries.",
    desktopMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_baby-fallback_desktop.png'
        }
      }
    },
    tabletMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_baby-fallback_tablet.png'
        }
      }
    },
    mobileMedia: {
      __typename: 'ProductHeroImage',
      photo: {
        image: {
          url: 'https://withjoy.com/assets/public/shop/homeHero/fallback-heroes/hero_baby-fallback_mobile.png'
        }
      }
    }
  }
};

export const BRAND_MARQUEE_DATA_BY_STOREFRONT: ConfigByStorefront<BrandMarqueeData> = {
  wedding: [
    {
      id: '4',
      title: 'Fellow',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/fellow.svg',
      link: '/shop/brand/fellow'
    },
    {
      id: '12',
      title: 'Yeti',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/yeti.svg',
      link: '/shop/brand/yeti'
    },
    {
      id: '5',
      title: 'Irobot',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/irobot.svg',
      link: '/shop/brand/irobot'
    },
    {
      id: '10',
      title: 'Samsonite',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/samsonite.svg',
      link: '/shop/brand/samsonite'
    },
    {
      id: '6',
      title: 'KitchenAid',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/kitchenaid.svg',
      link: '/shop/brand/kitchenaid'
    },
    {
      id: '8',
      title: 'Le-creuset',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/lecreuset.svg',
      link: '/shop/brand/le-creuset'
    },
    {
      id: '2',
      title: 'Cuisinart',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/cuisinart.svg',
      link: '/shop/brand/cuisinart'
    },
    {
      id: '1',
      title: 'Airbnb',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/2/airbnb.svg',
      link: '/shop/item/36e71fb5-0ce2-5539-87a2-b14a16335c9f'
    },
    {
      id: uuid(),
      title: 'Schmidt Brothers Cutlery',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/schmidt-bros-cutlery-2.svg',
      link: '/shop/brand/schmidt-brothers-cutlery'
    },
    {
      id: uuid(),
      title: 'Ooni',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/ooni.svg',
      link: '/shop/brand/ooni'
    },
    {
      id: uuid(),
      title: 'Parachute',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/parachute-1.svg',
      link: '/shop/brand/parachute'
    },
    {
      id: uuid(),
      title: 'SMEG',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/smeg.svg',
      link: '/shop/brand/smeg'
    },
    {
      id: uuid(),
      title: 'Caraway',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/caraway.svg',
      link: '/shop/brand/caraway'
    },
    {
      id: uuid(),
      title: 'Breville',
      imageUrl: 'https://withjoy.com/assets/public/shop/brandLogos/3/breville.svg',
      link: '/shop/brand/breville'
    }
  ],
  baby: [
    // To Do - W'll add them back in January or February.
    // {
    //   id: '1',
    //   title: 'Uppababy',
    //   imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/7/uppababy.svg',
    //   link: '/shop/baby/brand/uppababy'
    // },
    // {
    //   id: '2',
    //   title: 'Doona',
    //   imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/6/doona.svg',
    //   link: '/shop/baby/brand/doona'
    // },
    // {
    //   id: '3',
    //   title: 'Stokke',
    //   imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/6/stokke.svg',
    //   link: '/shop/baby/brand/stokke'
    // },
    {
      id: '4',
      title: 'Maxi Cosi',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/1/maxi-cosi.svg',
      link: '/shop/baby/brand/maxi-cosi'
    },
    {
      id: '5',
      title: 'Halo',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/7/halo.svg',
      link: '/shop/baby/brand/halo'
    },
    {
      id: '6',
      title: 'Nanit',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/7/nanit.svg',
      link: '/shop/baby/brand/nanit'
    },
    {
      id: '7',
      title: '4moms',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/1/4moms.svg',
      link: '/shop/baby/brand/4moms'
    },
    {
      id: '8',
      title: 'Newton',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/1/newton-baby.svg',
      link: '/shop/baby/brand/newton'
    },
    {
      id: '9',
      title: 'Oxo',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/5/oxo.svg',
      link: '/shop/baby/brand/oxo'
    },
    {
      id: '10',
      title: 'Frida',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/5/frida.svg',
      link: '/shop/baby/brand/frida'
    },
    {
      id: '11',
      title: 'Munchkin',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/5/munchkin.svg',
      link: '/shop/baby/brand/munchkin'
    },
    {
      id: '12',
      title: 'Skiphop',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/5/skiphop.svg',
      link: '/shop/baby/brand/skip-hop'
    },
    {
      id: '13',
      title: 'Drbrown',
      imageUrl: 'https://withjoy.com/assets/public/baby/BrandLogotypes/5/drbrowns.svg',
      link: '/shop/baby/brand/drbrown'
    }
  ]
};

/**
 * Currently hardcoded because server does not handle `imageUrl` just yet, and is not in the roadmap for the near future
 */
export const DEPARTMENT_NAV_SECTION_DATA_BY_STOREFRONT: ConfigByStorefront<DepartmentNavSectionData> = {
  wedding: [
    {
      id: 'f2efed8d-648f-548d-8e66-a24d61d4dca9',
      title: 'Kitchen',
      publicUrlPath: '/shop/catalog/kitchen',
      imageUrl: 'https://withjoy.com/media/product-items/cdfa32c1-37a9-512a-882b-24aaf8c15edc'
    },
    {
      id: 'ab0f9449-37dd-5562-9786-0fb17fc74e35',
      title: 'Tabletop',
      publicUrlPath: '/shop/catalog/tabletop',
      imageUrl: 'https://withjoy.com/media/product-items/05e9d986-db3b-5e30-99ba-c1d1cc77c77a'
    },
    {
      id: 'bb2cbca1-0006-5ecb-8ca3-a44d5aa532c1',
      title: 'Bed & Bath',
      publicUrlPath: '/shop/catalog/bed-bath',
      imageUrl: 'https://withjoy.com/media/product-items/407eef33-b4b7-5e4c-b356-ae2b5d356534'
    },
    {
      id: '7b796d6a-e36f-5204-8af4-1b06da989d8e',
      title: 'Home',
      publicUrlPath: '/shop/catalog/home',
      imageUrl: 'https://withjoy.com/media/product-items/39e43143-4695-505b-a742-7c71e234aa8d'
    },
    {
      id: '82c0d396-06eb-5d15-a555-83ac89372c4a',
      title: 'Outdoor',
      publicUrlPath: '/shop/catalog/outdoor',
      imageUrl: 'https://withjoy.com/media/product-items/2b0890c2-4a90-5547-b5e9-1a1c1aa7aec4'
    },
    {
      id: '5b325244-496e-594f-8dbb-a75608175dad',
      title: 'Experiences & Events',
      publicUrlPath: '/shop/catalog/experiences-events',
      imageUrl: 'https://withjoy.com/media/product-items/c1e266e9-f940-5a4d-b4c3-d47913a567e8'
    },
    {
      id: '4f19bc7b-c260-5ade-b0c0-f5d9240f7b38',
      title: 'Cash Funds & Gift Cards',
      publicUrlPath: '/shop/catalog/cash-funds-gift-cards',
      imageUrl: 'https://withjoy.com/media/product-items/51929832-abad-5039-b8f2-e2d02b9150c0'
    },
    {
      id: '4f19bc7b-c260-5ade-b0c0-f5d9240f7b38',
      title: 'Gifts & Cocktails',
      publicUrlPath: '/shop/catalog/gifts-cocktails',
      imageUrl: 'https://withjoy.com/media/product-items/d8abfcd2-025b-59af-8003-38ef88133726'
    }
  ],
  baby: [
    {
      id: '1fb99ddd-84af-533d-8cdf-7721e2a1e6c0',
      title: 'Feeding & Nursing',
      publicUrlPath: '/shop/baby/catalog/feeding-nursing',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/feeding.jpg'
    },
    {
      id: '8ce5eddf-b019-5bc4-af6d-f999976e5ed2',
      title: 'Nursery',
      publicUrlPath: '/shop/baby/catalog/nursery',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/nursery.jpg'
    },
    {
      id: '4f98d81b-ddcc-5a61-b3ac-4826edcb47b2',
      title: 'Strollers & Car Seats',
      publicUrlPath: '/shop/baby/catalog/strollers-car-seats',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/strollers.jpg'
    },
    {
      id: 'e0529fb9-8c84-5530-846a-10f3b16f5920',
      title: 'Health & Safety',
      publicUrlPath: '/shop/baby/catalog/health-safety',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/health.jpg'
    },
    {
      id: '84e47bf7-9465-53e3-bde8-721ecc68e49e',
      title: 'Bath & Potty',
      publicUrlPath: '/shop/baby/catalog/bath-potty',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/bath.jpg'
    },
    {
      id: '591c62fc-4541-56a8-ae03-831c7487b3bc',
      title: 'Clothing',
      publicUrlPath: '/shop/baby/catalog/clothing',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/clothing.jpg'
    },
    {
      id: '5ad4bae8-944e-5179-bca9-efa4732bcc3c',
      title: 'Toys & Activity',
      publicUrlPath: '/shop/baby/catalog/toys-activity',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/toys.jpg'
    },
    {
      id: 'd3457947-0083-51f5-9b13-49414db4555d',
      title: 'Travel & Gear',
      publicUrlPath: '/shop/baby/catalog/travel-gear',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/travel.jpg'
    },
    {
      id: 'd3be60fa-618c-5ce2-af13-a3dd30edf693',
      title: 'Cash Funds',
      publicUrlPath: '/shop/baby/catalog/cash-experiences',
      imageUrl: 'https://withjoy.com/assets/public/baby/departmentImages/3/cash.jpg'
    }
  ]
};

export const VALUE_PROPS_TOP_BAR_BY_STOREFRONT: ConfigByStorefront<ValuePropsTopBarData> = {
  baby: {
    shipping: {
      background: ['#5ee3bb', '#6adc82'],
      cardImageUrl: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Free-Shipping-Desktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Free-Shipping-Mobile.png`,
      targetUrl: 'https://help.withjoy.com/baby-registry/free-shipping',
      hasLink: true
    },
    completionDiscount: {
      background: ['#885199', '#f0bf9f'],
      cardImageUrl: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Completion-Discount-Desktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Completion-Discount-Mobile.png`,
      targetUrl: 'https://help.withjoy.com/baby-registry/20-post-baby-registry-discount',
      hasLink: true
    },
    priceMatchPromise: {
      background: ['#6165e7', '#d29bde'],
      cardImageUrl: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Price-Match-Desktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Price-Match-Mobile.png`,
      targetUrl: 'https://help.withjoy.com/baby-registry/baby-registry-price-matching',
      hasLink: true
    },
    easyReturns: {
      background: ['#edbe7b', '#DB96A6'],
      cardImageUrl: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Easy-Returns-Desktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/baby/ValuePropsModal/1/Top-Bar-Cards-Easy-Returns-Mobile.png`,
      targetUrl: 'https://help.withjoy.com/baby-registry/easy-returns',
      hasLink: true
    }
  },
  wedding: {
    shipping: {
      background: ['#5ee3bb', '#6adc82'],
      cardImageUrl: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerFreeShippingDesktop_v2.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerFreeShippingMobile_v2.png`,
      targetUrl: 'https://withjoy.com/shipping-policy',
      hasLink: true
    },
    completionDiscount: {
      background: ['#885199', '#f0bf9f'],
      cardImageUrl: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerPostWeddingDiscountDesktopv2.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerPostWeddingDiscountMobile.png`,
      targetUrl: 'https://help.withjoy.com/knowledge-base/20-percent-post-wedding-discount',
      hasLink: true
    },
    priceMatchPromise: {
      background: ['#6165e7', '#d29bde'],
      cardImageUrl: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerPriceMatchDesktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerPriceMatchMobile.png`,
      targetUrl: 'https://help.withjoy.com/knowledge-base/price-matching',
      hasLink: true
    },
    easyReturns: {
      background: ['#edbe7b', '#DB96A6'],
      cardImageUrl: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerEasyReturnsDesktop.png`,
      cardImageUrlMobile: `https://withjoy.com/assets/public/shop/value-props3/TopBarDrawerEasyReturnsMobile.png`,
      targetUrl: 'https://withjoy.com/return-policy',
      hasLink: true
    }
  }
};

export const VALUE_PROP_CARDS_BY_STOREFRONT: ConfigByStorefront<ValuePropCardsData> = {
  wedding: [
    {
      id: '1',
      title: 'Free Shipping',
      desktopImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardFreeShippingDesktop_v2.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardFreeShippingMobile_v3.png',
      link: 'https://withjoy.com/shipping-policy',
      isExternal: true
    },
    {
      id: '2',
      title: 'Registry Completion Discount',
      desktopImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardPostWeddingDiscountDesktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardPostWeddingDiscountMobile.png',
      link: 'https://help.withjoy.com/knowledge-base/20-percent-post-wedding-discount',
      isExternal: true
    },
    {
      id: '3',
      title: 'Hassle Free Returns',
      desktopImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardEasyReturnsDesktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardEasyReturnsMobile.png',
      link: 'https://withjoy.com/return-policy',
      isExternal: true
    },
    {
      id: '4',
      title: 'Price Match Promise',
      desktopImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardPriceMatchDesktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/shop/value-props3/ValuePropCardPriceMatchMobile.png',
      link: 'https://help.withjoy.com/knowledge-base/price-matching',
      isExternal: true
    }
  ],
  baby: [
    {
      id: '1',
      title: 'Free Shipping',
      desktopImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Free-Shipping-Desktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Free-Shipping-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/free-shipping',
      isExternal: true
    },
    {
      id: '2',
      title: '20% Completion Discount',
      desktopImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Completion-Discount-Desktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Completion-Discount-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/20-post-baby-registry-discount',
      isExternal: true
    },
    {
      id: '3',
      title: 'Easy Returns',
      desktopImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Easy-Returns-Desktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Easy-Returns-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/easy-returns',
      isExternal: true
    },
    {
      id: '4',
      title: 'Price Match Promise',
      desktopImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Price-Match-Desktop.png',
      mobileImageUrl: 'https://withjoy.com/assets/public/baby/ValuePropsGridCards/1/Grid-Cards-Price-Match-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/baby-registry-price-matching',
      isExternal: true
    }
  ]
};

export const VALUE_PROPS_SECTION_LINKS_BY_STOREFRONT: ConfigByStorefront<ValuePropSectionLinks> = {
  wedding: {
    delayShipping: 'https://withjoy.com/help/en/articles/9147314-delay-shipping-u-s-only',
    thankYouNotes: 'https://withjoy.com/help/en/articles/8328353-sending-thank-you-notes',
    shipping: 'https://withjoy.com/shipping-policy',
    completionDiscount: 'https://help.withjoy.com/knowledge-base/20-percent-post-wedding-discount',
    priceMatchPromise: 'https://help.withjoy.com/knowledge-base/price-matching',
    easyReturns: 'https://withjoy.com/return-policy',
    zeroFeeCashFunds: 'https://withjoy.com/help/en/articles/8328215-adding-a-honeymoon-or-cash-fund-to-your-registry',
    universalRegistry: 'https://withjoy.com/blog/how-to-get-the-most-from-your-all-in-one-wedding-registry/'
  },
  baby: {
    delayShipping: 'https://withjoy.com/help/en/articles/9147314-delay-shipping-u-s-only',
    thankYouNotes: 'https://withjoy.com/help/en/articles/8328353-sending-thank-you-notes',
    shipping: 'https://help.withjoy.com/baby-registry/free-shipping',
    completionDiscount: 'https://help.withjoy.com/baby-registry/20-post-baby-registry-discount',
    priceMatchPromise: 'https://help.withjoy.com/baby-registry/baby-registry-price-matching',
    easyReturns: 'https://help.withjoy.com/baby-registry/easy-returns',
    zeroFeeCashFunds: 'https://help.withjoy.com/baby-registry/are-there-any-fees-when-using-baby-cash-funds',
    universalRegistry: 'https://help.withjoy.com/baby-registry/adding-external-baby-registries-to-joy'
  }
};

export const OVERVIEW_BILLBOARD_CURATIONS: ConfigByStorefront<OverviewBillboardCurations> = {
  wedding: [
    {
      id: 'a6634484-425c-5edf-ba6d-6a8b9e214169',
      videoUrl: null,
      header: {
        backgroundImage: {
          id: '3cc64cc9-b3f1-5ab3-8b1d-e32e303c3936',
          url: 'https://dev.withjoy.com/media/product-items/71fc79f1-d735-5e86-a3a4-dda12f09520b',
          __typename: 'Photo'
        },
        logoImage: null,
        title: 'Registry Essentials',
        eyebrow: 'All-Time Faves',
        description: 'Start your party of two with these well-deserved gifts',
        video: null,
        videoThumbnail: null,
        __typename: 'ProductCatalogCurationHeader'
      },
      category: {
        id: 'a6634484-425c-5edf-ba6d-6a8b9e214169',
        title: 'Registry Essentials',
        publicUrlPath: '/shop/curations/registry-essentials',
        thumbnail: null,
        __typename: 'ProductCatalogCategory'
      },
      __typename: 'ProductCatalogCuration'
    },
    {
      id: 'ac4a5758-78ad-59c0-9cf5-c4597d689d83',
      videoUrl: null,
      header: {
        backgroundImage: {
          id: '28261c30-e829-505f-93f6-cfead587ad96',
          url: 'https://withjoy.com/media/product-items/fdc684b1-b752-545d-8018-acbc955fcced',
          __typename: 'Photo'
        },
        logoImage: null,
        title: 'Gifts Under $100',
        eyebrow: 'Totally Thoughtful',
        description: 'Fabulous and fun ideas for everyone.',
        video: null,
        videoThumbnail: null,
        __typename: 'ProductCatalogCurationHeader'
      },
      category: {
        id: '712dcc6e-828c-59f9-840e-9980a7c5a520',
        title: 'Gifts Under $100',
        publicUrlPath: '/shop/curations/gifts-under-100',
        thumbnail: null,
        __typename: 'ProductCatalogCategory'
      },
      __typename: 'ProductCatalogCuration'
    }
  ],
  baby: [
    {
      id: 'ed611eb4-ecc2-4415-b8c8-d5b269195be3',
      videoUrl: null,
      header: {
        backgroundImage: {
          id: '264a7a6e-7e1a-425c-8d43-82f86c0bbcf9',
          url: 'https://withjoy.com/assets/public/baby/heros/3/overview_baby_20_discount_3x.jpg',
          __typename: 'Photo'
        },
        logoImage: null,
        title: 'Expect the best.',
        eyebrow: '20% Registry Completion Discount',
        video: null,
        __typename: 'ProductCatalogCurationHeader'
      },
      category: {
        id: 'ed611eb4-ecc2-4415-b8c8-d5b269195be3',
        publicUrlPath: '/shop/baby',
        title: '20% Registry Completion Discount',
        thumbnail: null,
        __typename: 'ProductCatalogCategory'
      },
      customButton: 'Explore Gifts',
      disableBackgroundGradient: true,
      colorMode: 'dark',
      __typename: 'ProductCatalogCuration'
    },
    {
      id: 'cf793adf-7bff-42d8-9dc4-19a1e831b57d',
      videoUrl: null,
      header: {
        backgroundImage: {
          id: '15ac77c9-0996-4f24-84a9-37d789e3703b',
          url: 'https://withjoy.com/assets/public/baby/heros/3/overview_baby_registry_launch_hero_3x.jpg',
          __typename: 'Photo'
        },
        logoImage: null,
        title: 'All your dreams in one place.',
        eyebrow: 'Universal Baby Registry',
        video: null,
        videoThumbnail: null,
        __typename: 'ProductCatalogCurationHeader'
      },
      category: {
        id: 'cf793adf-7bff-42d8-9dc4-19a1e831b57d',
        title: 'Universal Baby Registry',
        publicUrlPath: '/get-quick-add-button',
        thumbnail: null,
        __typename: 'ProductCatalogCategory'
      },
      customButton: 'Get Joy Quick Add Button',
      disableBackgroundGradient: true,
      colorMode: 'dark',
      __typename: 'ProductCatalogCuration'
    }
  ]
};

export const ADD_FROM_FRIENDS_REGISTRY_BANNER: ConfigByStorefront<AddFromFriendsRegistryBannerData> = {
  [CatalogStorefront.wedding]: {
    mobile: {
      video: 'https://player.vimeo.com/video/912695330?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: `https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/Joy_CopyWeddingRegistry_v1_390x390.jpg`
    },
    desktop: {
      video: 'https://player.vimeo.com/video/912695767?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: `https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/Joy_CopyWeddingRegistry_v1_600x680.jpg`
    },
    borderColor: '#C9C9C9'
  },
  [CatalogStorefront.baby]: {
    mobile: {
      video: 'https://player.vimeo.com/video/885396251?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: `https://withjoy.com/assets/public/shop/features/add-from-registry-url/Joy_CopyFriend'sRegistry_v3_390x390_placeholder.png`
    },
    desktop: {
      video: 'https://player.vimeo.com/video/885396263?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: `https://withjoy.com/assets/public/shop/features/add-from-registry-url/Joy_CopyFriend'sRegistry_v3_600x680_placeholder.png`
    },
    borderColor: '#FDD1F4'
  }
};

export const ADD_FROM_FRIENDS_REGISTRY_SCANNER_DIALOG: ConfigByStorefront<AddFromFriendsRegistryBannerData> = {
  [CatalogStorefront.wedding]: {
    mobile: {
      video: 'https://player.vimeo.com/video/925191712?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/add-from-friends_background_wedding.jpg'
    },
    desktop: {
      video: 'https://player.vimeo.com/video/925191701?badge=0&autopause=0&player_id=0&app_id=58479&background=1',
      placeholder: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/add-from-friends_background_wedding.jpg'
    }
  },
  [CatalogStorefront.baby]: {
    mobile: {
      video: 'https://player.vimeo.com/video/901015563?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1',
      placeholder: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/mobile_fallback.jpg'
    },
    desktop: {
      video: 'https://player.vimeo.com/video/896263436?badge=0&autopause=0&player_id=0&app_id=58479&background=1',
      placeholder: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/desktop_fallback.jpg'
    }
  }
};

export const ADD_FROM_FRIENDS_REGISTRY_HOW_IT_WORJS: ConfigByStorefront<AddFromFriendsRegistryHowItWorksData> = {
  [CatalogStorefront.wedding]: {
    step1: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_1_desktop_2.jpg',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_1_mobile.jpg'
      }
    },
    step2: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_2_desktop.jpg',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_2_mobile.jpg'
      }
    },
    step3: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_3_desktop.jpg',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_3_mobile.jpg'
      }
    },
    step4: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_4_desktop.jpg',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/step_4_mobile.jpg'
      }
    }
  },
  [CatalogStorefront.baby]: {
    step1: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step1-Desktop.png',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step1-Mobile2.png'
      }
    },
    step2: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step2-Desktop.png',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step2-Mobile2.png'
      }
    },
    step3: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step3-Desktop.png',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step3-mobile2.png'
      }
    },
    step4: {
      image: {
        desktop: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step4-Desktop.png',
        mobile: 'https://withjoy.com/assets/public/shop/features/add-from-registry-url/Step4-Mobile2.png'
      }
    }
  }
};

export const OVERVIEW_HIGHLIGHTS: ConfigByStorefront<OverviewHighlights> = {
  wedding: [
    // { Replaced by Brand carousel
    //   name: 'babyFriendsRegistry',
    //   type: 'primary',
    //   variant: 'babyOverview',
    //   backgroundImage: [
    //     'url(https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/Joy_CopyWeddingRegistry_v1_390x390.jpg)',
    //     'url(https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/Joy_CopyWeddingRegistry_v1_390x390.jpg)',
    //     'url(https://withjoy.com/assets/public/shop/features/add-from-registry-url/wedding/Joy_CopyWeddingRegistry_v1_1200x376_R_4x.jpg)'
    //   ],
    //   backgroundVideo: {
    //     playerId: 'friends-registry-background',
    //     desktopVideoUrl: 'https://player.vimeo.com/video/912696573?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479&background=1',
    //     mobileVideoUrl: 'https://player.vimeo.com/video/912695330?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1'
    //   },
    //   actionButton: {
    //     variant: 'friendsRegistryLink',
    //     component: 'Wedding Registry Feature Highlights',
    //     method: 'Add From Friends Registry'
    //   }
    // },
    {
      name: 'weddingChecklist',
      showCtaIcon: false,
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/mobile/checklist@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/tablet/checklist@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/tablet/checklist@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/desktop/checklist@2x.jpg)'
      ],
      actionButton: {
        variant: 'checklist',
        component: 'Feature Highlights',
        method: 'Checklist'
      }
    },
    {
      name: 'weddingCashFunds',
      showCtaIcon: false,
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/mobile/cash-funds@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/tablet/cash-funds@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/tablet/cash-funds@2x.jpg)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/highlights/desktop/cash-funds@2x.jpg)'
      ],
      actionButton: {
        variant: 'shopLink',
        component: 'Feature Highlights',
        method: 'Cash Funds',
        path: '/shop/catalog/cash-funds'
      }
    },
    {
      name: 'weddingBrowserButton',
      showCtaIcon: false,
      screenType: 'desktop',
      backgroundImage: ['url(https://withjoy.com/assets/public/apps/registry/overview/highlights/desktop/register-anything@2x.png)'],
      actionButton: {
        variant: 'quickAddLink',
        component: 'Feature Highlights',
        method: 'Quick Add Button'
      }
    },
    {
      name: 'weddingMobileApp',
      showCtaIcon: false,
      screenType: 'mobile',
      backgroundImage: ['url(https://withjoy.com/assets/public/apps/registry/overview/highlights/mobile/register_anything_mobile_2x.png)'],
      backgroundPosition: 'bottom',
      actionButton: {
        variant: 'mobileApp',
        component: 'Feature Highlights',
        method: 'Mobile App'
      }
    }
  ],
  baby: [
    {
      name: 'babyFriendsRegistry',
      type: 'primary',
      variant: 'babyOverview',
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/add-from-friends-registry-v2.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/add-from-friends-registry-v2.png)',
        'url(https://withjoy.com/assets/public/shop/features/add-from-registry-url/Joy_AddFromFriendsRegistry_v3_1200x376_R_v2.png)'
      ],
      backgroundVideo: {
        playerId: 'friends-registry-background',
        desktopVideoUrl: 'https://player.vimeo.com/video/906859171?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479&background=1',
        mobileVideoUrl: 'https://player.vimeo.com/video/885396251?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&background=1'
      },
      actionButton: {
        variant: 'friendsRegistryLink',
        component: 'Baby Registry Feature Highlights',
        method: 'Add From Friends Registry'
      }
    },
    {
      name: 'babyChecklists',
      variant: 'babyOverview',
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/try_checklist_mobile@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/try_checklist_mobile@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/desktop/try_checklist.png)'
      ],
      actionButton: {
        variant: 'checklist',
        component: 'Baby Registry Feature Highlights',
        method: 'Checklist'
      }
    },
    {
      name: 'babyCashFunds',
      variant: 'babyOverview',
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/cash-fund-v2@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/cash-fund-v2@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/desktop/cash-fund-v2@2x.png)'
      ],
      actionButton: {
        variant: 'shopLink',
        component: 'Baby Registry Feature Highlights',
        method: 'Cash Funds',
        path: '/shop/baby/catalog/baby-cash-funds'
      }
    },
    {
      name: 'babyBrowserButton',
      variant: 'babyOverview',
      backgroundImage: [
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/register-anything@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/mobile/register-anything@2x.png)',
        'url(https://withjoy.com/assets/public/apps/registry/overview/babyRegistryHighlights/desktop/register-anything-v2@2x.png)'
      ],
      actionButton: {
        variant: 'quickAddLink',
        component: 'Baby Registry Feature Highlights',
        method: 'Quick Add Button'
      }
    }
  ]
};
