import { CardPaperType } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { Box, ButtonV2, DialogV2, DrawerV2, Flex, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React from 'react';
import { CARD_TYPES } from '../../config';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { closeButtonOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';
import { useTranslation } from '@shared/core';

interface CardProps {
  type: CardPaperType;
}

const Card: React.FC<CardProps> = ({ type }) => {
  return (
    <Flex flexDirection="column" gap={5}>
      <Box as="img" src={CARD_TYPES[type].imageUrl} objectFit="cover" objectPosition="center bottom" width="100%" height="124px" borderRadius={3} backgroundColor="mono3" />
      <Flex flexDirection="column" gap={3}>
        <TextV2 typographyVariant="button1">{CARD_TYPES[type].label}</TextV2>
        <TextV2 typographyVariant="label2">{CARD_TYPES[type].description}</TextV2>
        <Box as="ul" css={{ listStyle: 'disc' }} paddingLeft={pxToRem(15)} paddingTop={3}>
          <TextV2 as="li" typographyVariant="label2">
            Weight: {CARD_TYPES[type].weight}
          </TextV2>
          {!!CARD_TYPES[type].thickness && (
            <TextV2 as="li" typographyVariant="label2">
              Thickness: {CARD_TYPES[type].thickness}
            </TextV2>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

interface PaperTypesDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const PaperTypesDialog: React.FC<PaperTypesDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('stationery');
  const tPaperTypesDialog = t('cardCustomizer', 'paperTypesDialog');
  const isMobile = useIsMobileScreen();

  if (isMobile) {
    return (
      <DrawerV2
        isOpen={isOpen}
        onClose={onClose}
        anchor="bottom"
        size={0.95}
        stickyBehavior="header-footer"
        overrides={{
          Content: {
            props: {
              borderRadius: '0.5rem 0.5rem 0 0'
            }
          }
        }}
      >
        <DrawerV2.Header borderBottom="1px solid #EBEBEB">
          <Flex justifyContent="space-between" alignItems="center">
            <TextV2 typographyVariant="hed3">{tPaperTypesDialog.title()}</TextV2>
            <DrawerV2.CloseButton overrides={closeButtonOverrides} />
          </Flex>
        </DrawerV2.Header>
        <DrawerV2.Body>
          <TextV2 typographyVariant="body1" marginBottom={9}>
            {tPaperTypesDialog.description()}
          </TextV2>
          <Flex flexDirection="column" gap={7}>
            {[CardPaperType.signature, CardPaperType.smooth, CardPaperType.pearlescent, CardPaperType.doubleThick].map(type => (
              <Card key={type} type={type} />
            ))}
          </Flex>
        </DrawerV2.Body>
        <DrawerV2.Footer borderTop="1px solid #EBEBEB">
          <ButtonV2 flex="1" intent="neutral" shape="rounded" onClick={onClose}>
            {tPaperTypesDialog.done()}
          </ButtonV2>
        </DrawerV2.Footer>
      </DrawerV2>
    );
  }

  return (
    <DialogV2
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      overrides={{
        Body: {
          props: {
            paddingBottom: '4rem !important'
          }
        },
        CloseButton: {
          props: {
            top: pxToRem(28),
            right: pxToRem(12)
          }
        }
      }}
    >
      <DialogV2.Header paddingX={9} paddingY={8}>
        <TextV2 typographyVariant="hed6" fontWeight={600} marginBottom={5}>
          {tPaperTypesDialog.title()}
        </TextV2>
        <TextV2 typographyVariant="body2">{tPaperTypesDialog.description()}</TextV2>
        <DialogV2.CloseButton />
      </DialogV2.Header>
      <DialogV2.Body paddingX={9}>
        <Box width="100%" display="grid" gridTemplateColumns="1fr 1fr" gap={6}>
          {[CardPaperType.signature, CardPaperType.smooth, CardPaperType.pearlescent, CardPaperType.doubleThick].map(type => (
            <Flex key={type} padding={5} paddingBottom={6}>
              <Card type={type} />
            </Flex>
          ))}
        </Box>
      </DialogV2.Body>
    </DialogV2>
  );
};

export default PaperTypesDialog;
