import { createTelemetry, createTelemetryObject } from '@shared/core';

type SearchBarInteractedArgs = {
  query: string;
};

type SearchResultsReturnedArgs = {
  query: string;
  resultsCount: number;
  tags?: string[];
};

type SearchResultsErrorArgs = {
  query: string;
};

const searchTelemetry = createTelemetryObject({
  actions: {
    searchBarInteracted: ({ query, ...extraInfo }: SearchBarInteractedArgs) => ({
      action: 'SearchBarInteracted',
      category: 'MessagingButtonInteracted',
      extraInfo: { query: query.toLowerCase(), ...extraInfo }
    }),
    searchResultsReturned: ({ query, tags, resultsCount, ...extraInfo }: SearchResultsReturnedArgs) => ({
      action: 'SearchResultsReturned',
      category: 'MessagingButtonInteracted',
      extraInfo: { query: query.toLowerCase(), tags, resultsCount, ...extraInfo }
    }),
    searchResultsError: ({ query, ...extraInfo }: SearchResultsErrorArgs) => ({
      action: 'SearchResultsError',
      category: 'MessagingButtonInteracted',
      extraInfo: { query: query.toLowerCase(), ...extraInfo }
    })
  }
});

export const { useTelemetry: useSearchTelemetry } = createTelemetry(searchTelemetry);
