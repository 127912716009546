import { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client';
import { AutoPopulateAccommodationPlacesPayload, useAutoPopulateAccommodationPlacesMutation } from '@graphql/generated';
import { AnalyticsContext } from '@shared/core';
import { addAction } from '@shared/utils/logger';
import { useContext } from 'react';

interface AutoPopulateAccommodationPlacesArgs {
  onCompleted?: () => void;
  onError?: () => void;
  refetchQueries?: ((result: FetchResult) => InternalRefetchQueriesInclude) | InternalRefetchQueriesInclude;
}

export const useAutoPopulateAccommodationPlaces = ({ onCompleted, onError, refetchQueries }: AutoPopulateAccommodationPlacesArgs) => {
  const analytics = useContext(AnalyticsContext);
  const [autoPopulateAccommodationPlaces, { loading }] = useAutoPopulateAccommodationPlacesMutation({ onCompleted, onError });

  const callAutoPopulateAccommodationPlaces = (
    payloadArgs: AutoPopulateAccommodationPlacesPayload & { locationName?: string | null; locationAddress?: string | null; locationPlaceId?: string | null; source: string }
  ) => {
    const { locationName, locationAddress, locationPlaceId, source, ...payload } = payloadArgs;
    return new Promise(resolve => {
      autoPopulateAccommodationPlaces({ variables: { payload }, refetchQueries, awaitRefetchQueries: true })
        .then(response => {
          if (response.data) {
            analytics.track({
              category: 'accommodations',
              action: `TravelButtonInteracted`,
              label: 'autoPopulateAccommodationPlaces',
              extraInfo: {
                name: `autoPopulateAccommodationPlaces`,
                eventId: payload.eventId,
                latitude: payload.location?.latitude,
                longitude: payload.location?.longitude,
                placesCount: response.data?.autoPopulateAccommodationPlaces?.length,
                locationPlaceId,
                locationName,
                locationAddress,
                source
              }
            });
            addAction('autoPopulateAccommodationPlaces', {
              message: 'success',
              context: 'AutoPopulate',
              eventId: payload.eventId,
              latitude: payload.location?.latitude,
              longitude: payload.location?.longitude,
              placesCount: response.data?.autoPopulateAccommodationPlaces?.length,
              locationPlaceId,
              locationName,
              locationAddress,
              source
            });
            resolve(true);
          }
          if (response.errors) {
            const message = response.errors[0].message;
            addAction('autoPopulateAccommodationPlacesError', {
              message,
              context: 'AutoPopulate',
              eventId: payload.eventId,
              latitude: payload.location?.latitude,
              longitude: payload.location?.longitude,
              locationPlaceId,
              locationName,
              locationAddress,
              source
            });
            resolve(true);
          }
        })
        .catch(error => {
          const message = error instanceof Error ? error.message : '';
          addAction('autoPopulateAccommodationPlacesError', {
            message,
            context: 'AutoPopulate',
            eventId: payload.eventId,
            latitude: payload.location?.latitude,
            longitude: payload.location?.longitude,
            locationPlaceId,
            locationName,
            locationAddress,
            source
          });
          resolve(true);
        });
    });
  };

  return { callAutoPopulateAccommodationPlaces, loading };
};
