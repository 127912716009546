import React from 'react';
import { StationeryTemplate } from '../../../DesignsGallery.types';
import { GalleryTile, TagVariant } from '../../../../GalleryTile';
import { templateToTitle } from '@apps/card/Card.utils';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { Box, TextV2 } from '@withjoy/joykit';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { CardTilePriceLabel } from '../../CardTilePriceLabel';
import { desktopAspect, mobileAspect, tileRowGap } from '../../CardsConfig';
import { CardTileCarousel } from './CardTileCarousel/CardTileCarousel';
import { CardCarouselSlide } from '../useCardCarouselValues';
import { useFoilConfig } from '@apps/card/hooks/useFoilConfig';
import { useDisplayPrice } from '../../../../ProductDetailsPage/useDisplayPrice';

export type CardTileSource = 'shop' | 'favoritesGallery';

type CardTileProps = {
  data: StationeryTemplate & { type: 'template'; matchingTagCount?: number };
  eventId?: string;
  eventDesignThemeId?: string;
  onFavoriteClick?: (template: StationeryTemplate) => Promise<void>;
  hideTag?: boolean;
  cardTileSource: CardTileSource;
  tagCount?: number;
  showSearchDebugInfo?: boolean;
  disableAssetRotationOnHover: boolean;
  cardCarouselSlides?: CardCarouselSlide[];
  enableFreePriceString?: boolean;
};

export const CardTile = (props: CardTileProps) => {
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin;
  const { t } = useTranslation('stationery');
  const isMobile = useIsMobileScreen();

  const { value: printEnableDigitalEditorEnabled } = useFeatureValue('printEnableDigitalEditor');
  const isFavoritesRouteEnabled = useFeatureValue('printFavoritesRouteEnabled').value === 'on';

  const { value: printDesignGalleryTileAssetRotationOnHoverExperiment } = useFeatureValue('printDesignGalleryTileAssetRotationOnHoverExperiment', {
    skip: props.disableAssetRotationOnHover
  });
  const isAssetRotationOnHoverEnabled = printDesignGalleryTileAssetRotationOnHoverExperiment === 'treatment';

  const translations = t('dashboard', 'designsGallery');
  const { cardTileSource, data, eventDesignThemeId, hideTag, cardCarouselSlides, tagCount, showSearchDebugInfo, enableFreePriceString } = props;
  const { category, approved, format, thumbnailUrl, enabled, digitalDesignThumbnailUrl } = data;

  const { designGalleryTileClicked, markTemplateAsFavorite } = useCardTelemetry();

  const { goToProductDetailsPage } = useCardsRouterContext();

  const handleOnViewTemplatePdpClick = useEventCallback((template: StationeryTemplate) => {
    designGalleryTileClicked({ themeId: template.themeId, templateCategory: template.category, format: template.format, isPremiumDesign: template.premium });
    goToProductDetailsPage('wedding', category, template.themeId);
  });

  const isPremium = printEnableDigitalEditorEnabled === 'on' && (data.premium || format === 'paper');
  const hasBasic = !isPremium;

  const tagVariant: TagVariant | undefined = enabled
    ? approved
      ? // No tag if enabled
        undefined
      : 'comingSoon'
    : 'disabled';

  const { pricePerCardInMinorUnits: foilPrice } = useFoilConfig();
  const displayPrice = useDisplayPrice({ taylor: data.isFoil ? foilPrice : 0 });

  const priceElement = <CardTilePriceLabel paperPrice={displayPrice.paper} premiumDigitalPrice={displayPrice.premiumDigital} hasBasic={hasBasic && enableFreePriceString} />;

  const handleOnToggleFavorite = useEventCallback((isFavorite: boolean) => {
    markTemplateAsFavorite({ themeId: data.themeId, templateCategory: data.category, isFavorite, source: cardTileSource });
  });

  return enabled || isSuperAdmin ? (
    <GalleryTile
      aspectRatio={isMobile ? mobileAspect : desktopAspect}
      alignContent="start"
      rowGap={tileRowGap}
      onClick={maybeApplyCallback(!!(approved || isSuperAdmin), () => handleOnViewTemplatePdpClick(data))}
    >
      {eventDesignThemeId === data.themeId && (
        <Box
          typographyVariant="label1"
          fontWeight={600}
          position="absolute"
          paddingX={4}
          paddingY={2}
          left={5}
          top={4}
          backgroundColor="mono14"
          color="white"
          userSelect="none"
          borderRadius={5}
          zIndex={1}
        >
          {translations.websiteMatch()}
        </Box>
      )}
      {isAssetRotationOnHoverEnabled && cardCarouselSlides ? (
        <CardTileCarousel data={data} slides={cardCarouselSlides}>
          {isPremium && <GalleryTile.PremiumBadge />}
          {isFavoritesRouteEnabled && <GalleryTile.FavoriteBadge eventId={props.eventId} onToggle={handleOnToggleFavorite} template={data} />}
          {data.isFoil && <GalleryTile.FoilBadge />}
        </CardTileCarousel>
      ) : (
        <GalleryTile.AssetPreview assetUrl={(thumbnailUrl || digitalDesignThumbnailUrl) ?? ''}>
          {isPremium && <GalleryTile.PremiumBadge />}
          {isFavoritesRouteEnabled && <GalleryTile.FavoriteBadge eventId={props.eventId} onToggle={handleOnToggleFavorite} template={data} />}
          {data.isFoil && <GalleryTile.FoilBadge />}
        </GalleryTile.AssetPreview>
      )}
      {showSearchDebugInfo && tagCount && <TextV2 textAlign="center">{`${data.matchingTagCount ?? '0'}/${tagCount ?? 0}`}</TextV2>}
      <GalleryTile.Content title={templateToTitle(data)} label={priceElement}>
        {!hideTag && tagVariant && <GalleryTile.Tag variant={tagVariant} />}
      </GalleryTile.Content>
    </GalleryTile>
  ) : null;
};
