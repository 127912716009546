import { GetStationaryPrintOrderByOrderNumberQuery, GetStationeryCardDraftByIdQuery } from '@graphql/generated';
import React from 'react';
import { createContext, useContext } from 'react';

interface Context {
  mode: 'order' | 'draft';
  orderNumber?: string;
  orderId?: string;
  draftId?: string;
  orderIssues?: NonNullable<GetStationaryPrintOrderByOrderNumberQuery['stationeryPrintOrderByOrderNumber']>['issues'];
}

const EditingModeContext = createContext<Context>({ mode: 'draft' });

interface Props {
  orderData?: GetStationaryPrintOrderByOrderNumberQuery;
  draftData?: GetStationeryCardDraftByIdQuery;
}

export const EditingModeProvider: React.FC<Props> = ({ children, orderData, draftData }) => {
  if (!orderData && !draftData) {
    throw new Error('Either orderData or draftData must be provided');
  }

  const value = orderData?.stationeryPrintOrderByOrderNumber
    ? ({
        mode: 'order',
        orderNumber: orderData.stationeryPrintOrderByOrderNumber.orderNumber,
        orderId: orderData.stationeryPrintOrderByOrderNumber.id,
        orderIssues: orderData.stationeryPrintOrderByOrderNumber.issues
      } as const)
    : ({ mode: 'draft', draftId: draftData?.stationeryDraftById?.id } as const);

  return <EditingModeContext.Provider value={value}>{children}</EditingModeContext.Provider>;
};

export const useEditingMode = () => {
  return useContext(EditingModeContext);
};
