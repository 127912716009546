import React from 'react';
import { ExistingDraftsWarningProvider } from '@apps/card/routes/Dashboard/components/ExistingDraftsWarningProvider';
import { RestrictedInternationalShippingWarningProvider } from '@apps/card/routes/Dashboard/components/RestrictedInternationalShippingWarningProvider';
import { Flex } from '@withjoy/joykit';
import { usePublicShopCustomizeNowClickHandler } from '@apps/card/PublicShop/routes/Gallery/usePublicShopCustomizeNowClickHandler';
import { MarcomAppNavBar } from '../../components/MarcomAppNavBar';
import { ShopRouter } from '@apps/card/routes/Dashboard/components/DesignsGallery/ShopRouter';
import { Redirect, Route, Switch } from '@react-router';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useScrollToTopAfterNavigation } from '../../../hooks/useScrollToTopAfterNavigation';

interface GalleryProps {
  eventId: string;
}
/**
 * TODO:
 * - Consolidate required providers into one wrapper component
 */
export const Gallery = (props: GalleryProps) => {
  const { eventId } = props;
  const { goToCustomizeDraftOrLogin } = usePublicShopCustomizeNowClickHandler();

  const { routes, getShopPath } = useCardsRouterContext();
  useScrollToTopAfterNavigation();

  return (
    <RestrictedInternationalShippingWarningProvider eventId={eventId}>
      <ExistingDraftsWarningProvider eventId={eventId}>
        <Flex flexDirection="column">
          <MarcomAppNavBar />
          <Switch>
            <Route
              path={routes.designsGallery.paths}
              render={({ match }) => {
                return (
                  <ShopRouter
                    match={match}
                    productDetailsComponentHealthNameOverride="PublicStationeryProductDetailsPage"
                    designsGalleryComponentHealthNameOverride="PublicStationeryDesignsGallery"
                    onCustomizeClickCardOverride={goToCustomizeDraftOrLogin}
                  />
                );
              }}
            />
            <Redirect to={getShopPath('wedding', StationeryTemplateCategoryEnum.saveTheDate)} />
          </Switch>
        </Flex>
      </ExistingDraftsWarningProvider>
    </RestrictedInternationalShippingWarningProvider>
  );
};
