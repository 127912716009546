import { useEffect } from 'react';
import { useLocation } from '@react-router';
import type { Location } from 'history';

import { deriveActiveSearchFromUrl } from './utils/deriveActiveSearchFromUrl';

const syncSearchFromLocation = (location: Location, onSet: (query: string) => void) => {
  const composedUrl = `${location.pathname}${location.search}${location.hash}`;
  const { enabled: nextEnabled, query: nextQuery } = deriveActiveSearchFromUrl(composedUrl);

  if (nextEnabled) {
    onSet(nextQuery);
  }
};

export const useUrlSyncedSearch = (setGallerySearch: (query: string) => void) => {
  const location = useLocation();

  useEffect(() => {
    // Only want to run once on mount
    syncSearchFromLocation(location, setGallerySearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
