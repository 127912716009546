import React, { useState } from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { ChoiceGroup } from '../../components/ChoiceGroup';
import { useFoilConfig } from '@apps/card/hooks/useFoilConfig';
import { animationTransition } from '@shared/joykit/packages/core/utils';
import { useTranslation } from '@shared/core';
import { getFoilColorName } from '@apps/card/Card.utils';

export const FoilChoiceGroup: React.FC<{ selected?: string; onSelect: (colorId: string) => void; hideLearnMore?: boolean; hidePriceLabel?: boolean }> = ({
  selected,
  onSelect,
  hideLearnMore = false,
  hidePriceLabel = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('stationery');
  const tFoilTooltip = t('pdpModal', 'customizationPane', 'foilTooltip');
  const foilConfig = useFoilConfig();

  return (
    <ChoiceGroup title="Foil" value={getFoilColorName(selected, foilConfig.offerings)} price={hidePriceLabel ? undefined : 'FREE'}>
      <Flex gap={3}>
        {foilConfig.offerings.map(foilOffer => {
          const isSelected = foilOffer.colorId === selected;

          return (
            <Flex
              borderRadius={44}
              justifyContent="center"
              alignItems="center"
              position="relative"
              width={44}
              height={44}
              key={foilOffer.colorId}
              as="button"
              backgroundColor={isSelected ? 'mono14' : 'mono2'}
              onClick={() => onSelect(foilOffer.colorId)}
            >
              <Box
                position="absolute"
                width={38}
                height={38}
                borderRadius={38}
                backgroundImage={`url(${foilOffer.assetThumbnailUrl})`}
                backgroundSize="cover"
                backgroundPosition="center"
                outline={isSelected ? '1px solid white' : '2px solid white'}
              />
            </Flex>
          );
        })}
      </Flex>
      {!hideLearnMore && (
        <Box marginTop={3} position="relative" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
          <TextV2 typographyVariant="label2" textDecoration="underline" textDecorationColor="#D9D9D9" cursor="default">
            {tFoilTooltip.cta()}
          </TextV2>
          <Flex
            position="absolute"
            flexDirection="column"
            bottom="-0.5rem"
            right="0"
            left="0"
            background="white"
            transform="translateY(100%)"
            boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06)"
            borderRadius={4}
            padding={5}
            paddingBottom={6}
            gap={5}
            zIndex={10}
            opacity={isVisible ? 1 : 0}
            visibility={isVisible ? 'visible' : 'hidden'}
            transition={animationTransition('opacity', 'visibility')}
            transitionDelay="0.3s"
            maxWidth="310px"
          >
            <Box as="img" src="https://withjoy.com/assets/public/stationery-print-foil-preview.png" width="100%" backgroundColor="mono3" borderRadius={3} />
            <Flex flexDirection="column" gap={3}>
              <TextV2 typographyVariant="button1">{tFoilTooltip.title()}</TextV2>
              <TextV2 typographyVariant="label2">{tFoilTooltip.description()}</TextV2>
            </Flex>
          </Flex>
        </Box>
      )}
    </ChoiceGroup>
  );
};
