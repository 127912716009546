import { useCardDiscountContext, useGetDiscountedPrice } from '../DesignsGallery/useCardDiscountContext';
import { usePremiumDigitalPriceConfig } from '@apps/card/routes/DigitalCardCustomizer/hooks/usePremiumDigitalPriceConfig';
import { PRICE_CONFIG } from '@apps/card/config';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { PromotionVendor } from '@apps/card/routes/CardCustomizer/usePromotionConfig';

export const useDisplayPrice = (extra?: Partial<Record<PromotionVendor, number>>) => {
  const { currentCategory } = useCardCategoryContext();
  const cardDiscounts = useCardDiscountContext()!;

  const { priceEachInMinorUnits: digitalPrice } = usePremiumDigitalPriceConfig();
  const paperPrice = PRICE_CONFIG.quantity[currentCategory][100].individualPriceInMinorUnits;

  const paper = cardDiscounts.taylor.getPrice(paperPrice + (extra?.taylor || 0));
  const premiumDigital = cardDiscounts.joy_digital.getPrice(digitalPrice + (extra?.joy_digital || 0));
  const digital = ({ isEnabled: false, originalPrice: 'Free' } as unknown) as ReturnType<ReturnType<typeof useGetDiscountedPrice>>;

  return {
    paper,
    premiumDigital,
    digital
  };
};
