import { useLocation } from '@react-router';
import qs from 'query-string';

export const useSearchToQueryParam = () => {
  const location = useLocation();
  const baseUrl = `${location.pathname}${location.search}${location.hash}`;

  const getSearchURL = (searchTerm?: string) => {
    return qs.stringifyUrl(
      {
        url: baseUrl,
        query: {
          ps: searchTerm ?? '',
          page: null
        }
      },
      { skipNull: true, skipEmptyString: true }
    );
  };

  return { getSearchURL };
};
