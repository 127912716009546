import { Box, Flex, LinkV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { Filters } from './Filters';
import { FilterFacet } from './Filter.types';
import { SearchBar } from '../Search/SearchBar';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useApplySearchFiltersToTemplates } from '../../useApplySearchFiltersToTemplates';
import { useFeatureValue } from '@shared/core/featureFlags';
import { animationTransition } from '@shared/utils/animationTransition';
import { Restart } from '@withjoy/joykit/icons';
import { useDesignGalleryFiltersContext } from '../../DesignGalleryFiltersProvider';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

type SearchAndFiltersContainerProps = {
  showFilters: boolean;
  filterFacets: FilterFacet[];
};

const NoSearchResultsText = ({ onClick }: { onClick?: () => void }) => {
  return (
    <TextV2 paddingLeft={['1.5rem', '2.5rem']} marginY="auto">
      No search results.{' '}
      <span>
        <LinkV2 onClick={onClick} textDecoration="none">
          Clear
        </LinkV2>
      </span>
    </TextV2>
  );
};

const ResetAllFiltersButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('stationery');
  const tFiltersDrawer = t('dashboard', 'designsGallery', 'filtersDrawer');

  return (
    <Box paddingLeft={6} paddingRight={{ _: 6, sm2: 8 }} marginLeft="auto" flex="none">
      <Box onClick={onClick} backgroundColor="mono2" color="mono14" as="button" height={40} paddingX={5} borderRadius={24} transition={animationTransition('background-color')}>
        <Box as="span" display="inline-flex" borderRadius="inherit" alignItems="center" columnGap={3} typographyVariant="button1">
          <Restart size={16} />
          {tFiltersDrawer.resetCta()}
        </Box>
      </Box>
    </Box>
  );
};

export const SearchAndFiltersContainer = (props: SearchAndFiltersContainerProps) => {
  const { showFilters, filterFacets } = props;
  const isMobileOrTablet = useIsMobileOrTablet();
  const { galleryResetFilters } = useCardTelemetry();
  const { clearAllFilters, getTotalActiveFilterCount } = useDesignGalleryFiltersContext();

  const { value } = useFeatureValue('printGalleryEnableSearch');
  const searchEnabled = value === 'on';

  const { useSearchResults, clearSearch } = useApplySearchFiltersToTemplates();
  const searchResults = useSearchResults();
  const { searchError } = searchResults;

  const handleClearAllFilters = () => {
    galleryResetFilters({ activeFiltersCount: getTotalActiveFilterCount() });
    clearAllFilters();
  };

  return (
    <>
      <Flex>
        {showFilters && <Filters facets={filterFacets} />}
        {searchEnabled && <SearchBar showFilters={showFilters} />}
        {!isMobileOrTablet && searchError && <NoSearchResultsText onClick={clearSearch} />}
        {Boolean(getTotalActiveFilterCount()) && <ResetAllFiltersButton onClick={handleClearAllFilters} />}
      </Flex>
      {isMobileOrTablet && searchError && <NoSearchResultsText onClick={clearSearch} />}
    </>
  );
};
