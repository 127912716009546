import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { IRevealType, Reveal } from '@apps/greetingCard/components/Reveal/Reveal';
import { Box, Flex } from '@withjoy/joykit';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { InteractiveCard } from '../../../GalleryTile/InteractiveCard';
import { StationeryDraftFormat } from '@graphql/generated';
import { getDimensions } from '@apps/card/Card.utils';

type PremiumPDPRevealProps = {
  cardJSON: ThemeJson;
};

const OPEN_DELAY = 1500;

// Percentage of container the envelope width should be for portrait cards
const ENVELOPE_WIDTH = 0.6;
// Percentage of container the envelope height should be for landscape cards
const ENVELOPE_HEIGHT = 0.6;

const ENVELOPE_ASPECT_RATIO = 0.714;
const CARD_PADDING = 10;

export const PremiumPDPReveal = (props: PremiumPDPRevealProps) => {
  const { cardJSON } = props;

  const revealContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    const container = revealContainerRef.current;
    if (!container) {
      return;
    }

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      if (container) {
        const width = container.getBoundingClientRect().width;
        setContainerWidth(width);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, []);

  const dimensions = getDimensions(cardJSON.card);
  const cardAspectRatio = dimensions.height / dimensions.width;
  const cardFront = cardJSON.card.front;
  const shape = cardJSON.customizations.shape;

  let envelopeWidth = (containerWidth ?? 0) * ENVELOPE_WIDTH;

  if (cardAspectRatio < 1) {
    envelopeWidth = ((containerWidth ?? 0) * ENVELOPE_HEIGHT) / ENVELOPE_ASPECT_RATIO;
  }

  const cardWidth = envelopeWidth - CARD_PADDING;
  const cardHeight = cardWidth * cardAspectRatio;

  return (
    <Box position="relative" margin="auto" ref={revealContainerRef} width="100%" height="100%" marginTop={'-10px'}>
      {containerWidth && (
        <Reveal
          preventToggleOnContentClick
          width={envelopeWidth}
          themeColor="#ffffff"
          type={IRevealType.PremiumEnvelope}
          autoPlayDelay={OPEN_DELAY}
          loading={false}
          bottomOffset={10}
          verticalOffset={containerWidth}
        >
          <Flex flexDirection="column" marginX="auto" width={cardWidth} gap={6}>
            <InteractiveCard width={cardWidth} height={cardHeight} page={cardFront} shape={shape} format={StationeryDraftFormat.digital} />
          </Flex>
        </Reveal>
      )}
    </Box>
  );
};
