import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { FullWidthEditorProps } from './FocusPointEditor.types';
import { EditorLayout } from './EditorLayout';
import { Box, useToast } from '@withjoy/joykit';
import { WarningTriangle } from '@withjoy/joykit/icons';
import { CheckIcon } from '@assets/icons';
import { useTranslation } from '@shared/core';
import { addError } from '@shared/utils/logger';
import { useWebsiteCreateOrUpdatePagePhotoPresentationMutation } from '@graphql/generated';
import { TempSpinner } from '../TempSpinner';
import { SkeletonThumbnail } from '../Skeleton';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';

import 'react-image-crop/dist/ReactCrop.css';
import './FullWidthEditor.css';

export const FullWidthPhotoEditor = (props: FullWidthEditorProps) => {
  const {
    crop,
    setCrop,
    isLoadingPhoto,
    imageData,
    handleCloseClick,
    isUpdatingEventPhoto,
    isDeletingPagePhoto,
    handleRemovePhoto,
    handleChangePhoto,
    handleCancelClick,
    onCropComplete,
    pageId,
    pageType,
    eventId,
    telemetryCategory,
    handleSaveUploadedPhoto,
    uploadedImageData,
    updatePagePhotoPreview
  } = props;

  const [isLoadingLatestImage, setIsLoadingLatestImage] = useState(true);

  const previousImageURL = useRef(imageData.url);

  const { saveFullWidthPhotoChangesButtonClicked } = useFocusPointEditorTelemetry();

  const { toast } = useToast();
  const { t2 } = useTranslation('joykit');
  const dialogTrans = t2('focusPointDialog');

  const [createOrUpdatePagePhotoPresentation, { loading: isSavingPagePhotoPresentation }] = useWebsiteCreateOrUpdatePagePhotoPresentationMutation({
    onCompleted: data => {
      switch (data.websiteCreateOrUpdatePagePhotoPresentation?.__typename) {
        // Success
        case 'websiteCreateOrUpdatePagePhotoPresentationResponse': {
          toast(`${dialogTrans.photoSettingsUpdateSuccess}`, { icon: <CheckIcon /> });
          // Using `handleCancelClick` since that's also used to just close the editor (as previously done).
          handleCancelClick();
          break;
        }

        // Error
        case 'websiteCreateOrUpdatePagePhotoPresentationResponseError': {
          toast(`${dialogTrans.photoSettingsUpdateFailure}`, { icon: <WarningTriangle /> });
          const { message, id, pageId } = data.websiteCreateOrUpdatePagePhotoPresentation;
          addError('WebsitePagePhotoEditor - FullWidthEditor - set page photo presentation mutation', {
            status: 'error',
            message,
            eventId,
            id,
            pageId,
            photoId: imageData.id,
            telemetryCategory,
            pageType
          });
          break;
        }
      }
    },

    onError: error => {
      toast(`${dialogTrans.photoSettingsUpdateFailure}`, { icon: <WarningTriangle /> });
      addError('WebsitePagePhotoEditor - FullWidthEditor - set page photo presentation mutation', {
        status: 'fail',
        error,
        eventId,
        photoId: imageData.id,
        telemetryCategory,
        pageType
      });
    }
  });

  const handleOnSaveClick = useEventCallback(async () => {
    // Saving page photo presentation directly within this editor instead of `EditorConsole`
    // because this component is only ever enabled within the website designer (as oppposed to the travel map).

    if (!pageId) {
      // `pageId` should be provided or else this editor is being used in an unknown context.
      // Track in new relic.
      addError('WebsitePagePhotoEditor - FullWidthEditor - missing pageId', { eventId, photoId: imageData.id, telemetryCategory, pageType });
      return;
    }

    saveFullWidthPhotoChangesButtonClicked(telemetryCategory, { pageId, pageType, cropData: crop });
    await handleSaveUploadedPhoto();

    createOrUpdatePagePhotoPresentation({
      variables: {
        payload: {
          pageId,
          cropInfo: {
            ...crop,
            unit: 'percent'
          },
          presentationLayout: 'fullWidth'
        }
      }
    }).then(info => {
      if (!info.errors) {
        if (
          updatePagePhotoPreview &&
          info.data?.websiteCreateOrUpdatePagePhotoPresentation?.__typename === 'websiteCreateOrUpdatePagePhotoPresentationResponse' &&
          info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation &&
          info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation.photo
        ) {
          updatePagePhotoPreview(
            uploadedImageData?.assetId || '',
            info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation.photo,
            info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation
          );
        }
      }
    });
  });

  // Set loading to true when the image URL changes
  useEffect(() => {
    if (previousImageURL.current !== imageData.url) {
      setIsLoadingLatestImage(true);
      previousImageURL.current = imageData.url;
    }
  }, [imageData.url]);

  const aspectRatio = imageData.width / imageData.height;

  return (
    <EditorLayout
      note={dialogTrans.fullWidthCropNote}
      isDeletePhotoButtonDisabled={isLoadingPhoto || isDeletingPagePhoto}
      isDeletePhotoButtonLoading={isDeletingPagePhoto}
      onDeletePhotoButtonClick={handleRemovePhoto}
      onReplacePhotoButtonClick={handleChangePhoto}
      isReplacePhotoButtonDisabled={isLoadingPhoto || isSavingPagePhotoPresentation}
      onSavePhotoButtonClick={handleOnSaveClick}
      onCancelButtonClick={handleCancelClick}
      isCancelButtonDisabled={isUpdatingEventPhoto || isSavingPagePhotoPresentation}
      onCloseButtonClick={handleCloseClick}
      isCloseButtonDisabled={isUpdatingEventPhoto || isSavingPagePhotoPresentation}
      isSavePhotoButtonDisabled={isSavingPagePhotoPresentation || isUpdatingEventPhoto || isLoadingPhoto}
      isSavePhotoButtonLoading={isUpdatingEventPhoto || isSavingPagePhotoPresentation}
      content={() => {
        return (
          <>
            <ReactCrop
              key={imageData.url}
              crop={crop}
              aspect={16 / 9}
              minHeight={100}
              minWidth={100}
              keepSelection
              style={{
                display: isLoadingLatestImage ? 'none' : 'inline-block'
              }}
              disabled={isLoadingPhoto}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(_, pCrop) => {
                onCropComplete?.();
              }}
            >
              <Box as="img" src={imageData.url} onLoad={() => setIsLoadingLatestImage(false)} />
              {isLoadingPhoto && <TempSpinner position={'absolute'} className="loading" />}
            </ReactCrop>

            {/* for showing image skeleton */}
            <Box display={isLoadingLatestImage ? 'block' : 'none'} position="relative" width="100%" maxWidth={imageData.width} margin="0 auto">
              <Box top="0" left="0" width="100%" height="0" paddingBottom={`${(1 / aspectRatio) * 100}%`} />
              <SkeletonThumbnail position="absolute" inset={0} width="100%" height="100%" />
            </Box>
          </>
        );
      }}
    />
  );
};
