import React from 'react';
import { Layout } from '@apps/card/components/Layout';
import { CardDieCut, CardPaperType } from '../../../CardCustomizer/CardCustomizer.types';
import { useNavigationContext } from '../../../CardCustomizer/useNavigationState';
import { ButtonV2, Flex, TextV2, useDisclosure } from '@withjoy/joykit';
import { useCustomizationContext } from '../../../CardCustomizer/useCardCustomization';
import { ChoiceGroup } from '../../../CardCustomizer/components/ChoiceGroup';
import { PRICE_CONFIG } from '@apps/card/config';
import { SidePanelPriceBreakdownBlock } from '../../../CardCustomizer/components/SidePanelPriceBreakdownBlock';
import { useCardCustomizerTelemetry } from '../../../CardCustomizer/CardCustomizer.telemetry';
import PaperTypeTooltip from '../../../CardCustomizer/steps/CardDesign/components/PaperTypeTooltip';
import PaperTypesDialog from '../../../CardCustomizer/steps/CardDesign/components/PaperTypesDialog';
import { pxToRem } from '@withjoy/joykit/theme';
import useAllowedTrims from '../../../CardCustomizer/useAllowedTrims';
import { useEditingMode } from '../../../CardCustomizer/useEditingMode';
import { SilhouetteChoiceGroup } from '../../../CardCustomizer/steps/CardDesign/SilhouetteChoiceGroup';
import { CardFrontConfigPanel } from '../../../CardCustomizer/steps/CardDesign/CardFrontConfigPanel';
import { usePopulateEventLinkAndPassword } from '@apps/card/routes/CardCustomizer/steps/CardDesign/usePopulateEventLinkAndPassword';
import { FoilChoiceGroup } from '@apps/card/routes/CardCustomizer/steps/CardDesign/FoilChouseGroup';
import { isFoilTemplate } from '@apps/card/routes/Dashboard/components/DesignsGallery/utils';
import { findFoilLayer, getDimensions } from '@apps/card/Card.utils';
import { QuantityChoiceGroup } from '@apps/card/routes/CardCustomizer/steps/CardDesign/QuantityChoiceGroup';

export const CardFrontCustomizations = (props: { isInsidePDP?: boolean; subtotal?: string }) => {
  const { mode } = useEditingMode();
  const [template] = useCustomizationContext(draft => draft.template);
  const [general, update] = useCustomizationContext(draft => draft.config.customizations, mode === 'order');
  const [cardFront, updateCardFront] = useCustomizationContext(draft => draft.config.card.front, mode === 'order');
  const { paperType, shape, quantity } = general;
  const { customizationButtonInteracted } = useCardCustomizerTelemetry();
  const paperTypesDialog = useDisclosure();
  const allowedTrims = useAllowedTrims(paperType, shape);
  const { subtotal } = props;

  usePopulateEventLinkAndPassword(cb => updateCardFront(cb));

  const getCardPaperTypeName = (type: CardPaperType) => {
    switch (type) {
      case CardPaperType.signature:
        return 'Signature';
      case CardPaperType.smooth:
        return 'Smooth';
      case CardPaperType.pearlescent:
        return 'Pearlescent';
      case CardPaperType.doubleThick:
        return 'Double Thick';
      case CardPaperType.tripleThick:
        return 'Triple Thick';
      default:
        return '';
    }
  };

  const [cardDimensions] = useCustomizationContext(draft => getDimensions(draft.config.card));
  const cardSizeText = `${cardDimensions.width} x ${cardDimensions.height} inches`;

  return (
    <Flex flexDirection="column" rowGap={7}>
      <ChoiceGroup title="Size" value={cardSizeText} textTransform="none" />
      {isFoilTemplate(template) && (
        <FoilChoiceGroup
          selected={findFoilLayer(cardFront.layers)?.foilData?.colorId}
          onSelect={value => {
            updateCardFront(draft => {
              const layer = findFoilLayer(draft.layers);
              if (layer?.foilData) {
                layer.foilData.colorId = value;
              }
            });
            customizationButtonInteracted({ property: 'foilOption', value });
          }}
        />
      )}
      <Flex flexDirection="column" gap={5}>
        <PaperTypeTooltip.Container>
          <ChoiceGroup
            getName={getCardPaperTypeName}
            minHeight={55}
            title="Paper Type"
            value={paperType}
            price={[CardPaperType.signature, CardPaperType.smooth].includes(paperType) ? 'FREE' : `+ ${PRICE_CONFIG.paperType[paperType][quantity].totalPriceString}`}
            choices={[CardPaperType.signature, CardPaperType.smooth, CardPaperType.pearlescent, CardPaperType.doubleThick]}
            setValue={value => {
              update(draft => {
                draft.paperType = value;
              });
              allowedTrims.reflectChange({ newPaperType: value });
              customizationButtonInteracted({ property: 'paperTypeOption', value });
            }}
          >
            <PaperTypeTooltip.Option type={CardPaperType.signature} textDecoration={allowedTrims.disabledPaperTypes.includes(CardPaperType.signature) ? 'line-through' : ''} />
            <PaperTypeTooltip.Option type={CardPaperType.smooth} textDecoration={allowedTrims.disabledPaperTypes.includes(CardPaperType.smooth) ? 'line-through' : ''} />
            <PaperTypeTooltip.Option type={CardPaperType.pearlescent} textDecoration={allowedTrims.disabledPaperTypes.includes(CardPaperType.pearlescent) ? 'line-through' : ''} />
            <PaperTypeTooltip.Option type={CardPaperType.doubleThick} textDecoration={allowedTrims.disabledPaperTypes.includes(CardPaperType.doubleThick) ? 'line-through' : ''} />
          </ChoiceGroup>
          <PaperTypeTooltip.Tooltip />
        </PaperTypeTooltip.Container>
        <ButtonV2
          variant="link"
          fontSize={pxToRem(13)}
          fontWeight={400}
          justifyContent="flex-start"
          padding={0}
          intent="neutral"
          onClick={paperTypesDialog.onOpen}
          borderRadius={0}
          borderBottom="1px solid #D9D9D9"
          textDecoration="none !important"
          alignSelf="flex-start"
          lineHeight={1}
          _hover={{ borderBottomColor: 'transparent' }}
        >
          Compare Paper Types
        </ButtonV2>
        <PaperTypesDialog isOpen={paperTypesDialog.isOpen} onClose={paperTypesDialog.onClose} />
      </Flex>
      <SilhouetteChoiceGroup
        price={shape === CardDieCut.rectangle ? 'FREE' : `+ ${PRICE_CONFIG.shape[shape].getTotalPriceStringByQuantity(quantity)}`}
        getOpacity={shape => (allowedTrims.disabledTrims.includes(shape) ? 0.4 : 1)}
        onValueChange={value => allowedTrims.reflectChange({ newDieCut: value })}
      />
      <QuantityChoiceGroup />
      {subtotal && (
        <Flex columnGap={4}>
          <TextV2 typographyVariant="button1">{'Subtotal'}</TextV2>
          <TextV2 typographyVariant="button1" color="mono12">
            {subtotal}
          </TextV2>
        </Flex>
      )}
    </Flex>
  );
};

export const PaperCardFrontConfigPanel = ({ hideStickyCtaBar }: { hideStickyCtaBar: boolean }) => {
  const { navigateForward } = useNavigationContext();
  const { customizationButtonInteracted } = useCardCustomizerTelemetry();

  return (
    <CardFrontConfigPanel
      hideStickyCtaBar={hideStickyCtaBar}
      onNext={() => {
        navigateForward();
        customizationButtonInteracted({ property: 'stickyCta_nextButton' });
      }}
      renderCustomizations={() => (
        <>
          <Layout.SidePanelBlock>
            <CardFrontCustomizations />
          </Layout.SidePanelBlock>
          <SidePanelPriceBreakdownBlock />
        </>
      )}
    />
  );
};
