import { keyframes, styled } from '@withjoy/joykit';

export const SearchBarInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  float: left;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  height: 40px;

  position: relative;

  width: 100%;
  max-width: 350px;

  padding: 0.5rem 0.85rem 0.5rem 0.7rem;

  outline: 1px solid rgb(217, 217, 217);
  border-radius: 999px;
`;

const shimmerKeyframes = keyframes`
0% {
  mask-position: -200px 0;
  webkit-mask-position: -200px 0;
}
100% {
  mask-position: calc(200px + 100%) 0;
  webkit-mask-position: calc(200px + 100%) 0;
}
`;

export const ShimmerLoadingAnimation = styled.div<{ $loading?: boolean }>`
  background-color: #eee;
  -webkit-mask-image: repeating-linear-gradient(90deg, rgba(250, 250, 250, 0.1), rgba(245, 245, 245, 1), rgba(250, 250, 250, 0.1));
  mask-image: repeating-linear-gradient(90deg, rgba(250, 250, 250, 0.1), rgba(245, 245, 245, 1), rgba(250, 250, 250, 0.1));

  mask-size: 200px 100%;
  mask-repeat: no-repeat;
  animation: ${shimmerKeyframes} 1.3s ease-in-out infinite;

  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 999px;

  width: 100%;
  height: 100%;
  opacity: ${props => (props.$loading ? 0.6 : 0)};
  z-index: 100;

  transition: opacity 750ms cubic-bezier(0.075, 0.82, 0.165, 1);

  pointer-events: none;
`;
