import React from 'react';
import { Redirect, Route, Switch, match } from '@shared/core';
import { isValidShopPageMatch, useCardsRouterContext } from '@apps/card/Card.routes';
import { DesignsGallery } from './DesignsGallery';
import { ExistingDraftsWarningProvider } from '../ExistingDraftsWarningProvider';
import { RestrictedInternationalShippingWarningProvider } from '../RestrictedInternationalShippingWarningProvider';
import { ProductDetailsPage } from '../ProductDetailsPage/ProductDetailsPage';
import { CustomizeNowClickOverrideHandler } from './DesignsGallery.types';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { CategoryNav } from '../CategoryNav';
import { Box } from '@withjoy/joykit';
import { useCardCategoryContext } from '../CardCategoryProvider';

interface ShopProps {
  eventId?: string;
  onCustomizeClickCardOverride?: CustomizeNowClickOverrideHandler;
  match: match;
  productDetailsComponentHealthNameOverride?: string;
  designsGalleryComponentHealthNameOverride?: string;
}

export const ShopRouter = (props: ShopProps) => {
  const { eventId, onCustomizeClickCardOverride, match, designsGalleryComponentHealthNameOverride, productDetailsComponentHealthNameOverride } = props;
  const { routes, getShopPath } = useCardsRouterContext();
  const { currentCategory } = useCardCategoryContext();

  if (!isValidShopPageMatch(match.params)) {
    // TODO: Discuss with team about showing a 404 page or redirecting to a known shop page
    return <Redirect to={getShopPath('wedding', StationeryTemplateCategoryEnum.saveTheDate)} />;
  }

  return (
    <RestrictedInternationalShippingWarningProvider eventId={eventId}>
      <ExistingDraftsWarningProvider eventId={eventId}>
        <Switch>
          <Route
            path={routes.productDetailsPage.paths}
            render={({ match }) => (
              <ProductDetailsPage
                componentHealthNameOverride={productDetailsComponentHealthNameOverride}
                eventId={props.eventId}
                themeId={match.params.themeId}
                onCustomizeCardClickOverride={onCustomizeClickCardOverride}
              />
            )}
          />
          <Route
            exact={true}
            path={routes.designsGallery.paths}
            render={({ match }) => (
              <>
                <Box>
                  <CategoryNav />
                </Box>
                <DesignsGallery
                  componentHealthNameOverride={designsGalleryComponentHealthNameOverride}
                  onCustomizeCardClickOverride={onCustomizeClickCardOverride}
                  eventId={eventId}
                  eventHandle={match.params.eventHandle}
                />
              </>
            )}
          />
          <Redirect to={getShopPath('wedding', currentCategory)} />
        </Switch>
      </ExistingDraftsWarningProvider>
    </RestrictedInternationalShippingWarningProvider>
  );
};
