import { animationTransition } from '@shared/utils/animationTransition';
import { Box } from '@withjoy/joykit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VimeoContainer } from '../../CardTileConcierge';
import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import Player from '@vimeo/player';
import { useGalleryGridStyle } from '../../../../../useGalleryGridStyle';
import { CarouselSlideBaseProps } from './Carousel';
import { usePauseOffScreen } from '../../utils/vimeo/usePauseOffScreen';
import { useAutoplayValues } from '../../../useAutoplayValues';

type CarouselVideoContainerProps = {} & CarouselSlideBaseProps;

export const CarouselVideoContainer = (props: CarouselVideoContainerProps) => {
  const { asset, onClick, currentPosition, index } = props;

  const isShowing = currentPosition === 0;

  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const placeholderImageRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [videoPlayer, setVideoPlayer] = useState<Player | undefined>(undefined);
  const { assetPreviewFrameBackground } = useGalleryGridStyle();
  const { videoPropsAutoplay: useAutoplay } = useAutoplayValues();

  const [videoContainerRef] = usePauseOffScreen(
    () => {
      videoPlayer?.play();
    },
    () => {
      videoPlayer?.pause();
    },
    !useAutoplay
  );

  const videoUrl = useMemo(() => {
    if (asset.type !== 'video') {
      return null;
    }

    const url = new URL(asset.videoUrl);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [asset]);

  const showAndPlay = (player?: Player) => {
    const video = player ?? videoPlayer;
    if (isShowing && video?.getPaused()) {
      video.play().catch(() => {
        // Ignore the error because it's expected when the video is forcibly paused
      });
    }
  };

  const onPlayerLoad = () => {
    if (playerRef && playerRef.current && VimeoPlayer && !videoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      setVideoPlayer(player);
      showAndPlay(player);
    }
    placeholderImageRef.current?.style.setProperty('opacity', '0');
  };

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  if (asset.type !== 'video') {
    return null;
  }

  const { videoThumbnailUrl, backgroundColor } = asset;

  if (!isShowing) {
    placeholderImageRef.current?.style.setProperty('opacity', '1');
  }

  return (
    <Box
      ref={videoContainerRef}
      onClick={() => onClick?.(index)}
      zIndex={3}
      cursor={onClick !== undefined ? 'pointer' : undefined}
      width="100%"
      height="100%"
      position="absolute"
      style={{ transition: animationTransition('transform'), transform: `translateX(${100 * currentPosition}%)` }}
    >
      <Box height="100%">
        <Box
          width="100%"
          height="100%"
          backgroundColor={backgroundColor ?? String(assetPreviewFrameBackground) ?? 'mono1'}
          backgroundPosition="center"
          backgroundSize="98%"
          backgroundRepeat="no-repeat"
          backgroundImage={videoThumbnailUrl ? `url('${videoThumbnailUrl}')` : 'unset'}
        >
          {isShowing && <VimeoContainer onLoad={onPlayerLoad} r={playerRef} src={videoUrl!} />}
        </Box>
      </Box>
    </Box>
  );
};
