import { Flex } from '@withjoy/joykit';
import React, { ReactNode } from 'react';

type ButtonContainerProps = {
  children: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const ButtonContainer = (props: ButtonContainerProps) => {
  const { children, hidden, disabled, onClick } = props;

  const clickable = onClick && !hidden && !disabled;

  return (
    <Flex flexDirection="row" alignItems="center" onClick={clickable ? onClick : undefined} style={{ cursor: clickable ? 'pointer' : undefined, opacity: !hidden ? 1 : 0 }}>
      {children}
    </Flex>
  );
};
