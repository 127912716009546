import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import { Box, BoxProps, Flex, TextV2, styled } from '@withjoy/joykit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProductOffering } from './ConciergeServiceSection.types';
import Player from '@vimeo/player';
import { animationTransition } from '@shared/utils/animationTransition';
import { PRODUCT_OFFERINGS } from './ConciergeServiceSection.config';
import { Carousel as CatalogCarousel } from '@apps/registry/common/components/Catalog';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { Skeleton } from '@shared/components/Skeleton';
import { ConciergeServiceCategory } from '@apps/card/routes/Concierge/config';

const Title = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  overflow: hidden;
`;

const Tile = ({ onClick, data, sortIndex, category }: { onClick: (id: string) => void; data: ProductOffering; sortIndex: number; category: ConciergeServiceCategory }) => {
  const { t2 } = useTranslation('stationery');
  const trans = t2('dashboard', 'conciergeServiceSection', 'carousel');
  const { shortName, videoUrl: videoUrlProp, videoThumbnailUrl, imageUrl } = data;
  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const { paperlustConciergeServiceSectionTileInteracted } = useCardTelemetry();
  const isMobileOrTablet = useIsMobileOrTablet();

  const videoUrl = useMemo(() => {
    const url = new URL(videoUrlProp);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [videoUrlProp]);

  const placeholderImageRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [videoPlayer, setVideoPlayer] = useState<Player | null>(null);

  useEffect(() => {
    if (playerRef && playerRef.current && VimeoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      setVideoPlayer(player);
    }
  }, [VimeoPlayer]);

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  const onMouseOver = () => {
    if (videoPlayer) {
      videoPlayer.play().catch(() => {
        // Ignore the error because it's expected when the video is forcibly paused
      });
      placeholderImageRef.current?.style.setProperty('opacity', '0');
    }
  };

  const onMouseExit = () => {
    placeholderImageRef.current?.style.setProperty('opacity', '1');
    if (videoPlayer) {
      videoPlayer.pause();
      videoPlayer.setCurrentTime(0);
    }
  };

  return (
    <Flex
      flexDirection="column"
      cursor="pointer"
      onClick={() => {
        paperlustConciergeServiceSectionTileInteracted({
          suite: data.longName,
          cost: data.costPerHundredString,
          sortIndex,
          orderOfItems: PRODUCT_OFFERINGS[category].map(offering => offering.longName)
        });
        onClick(data.id);
      }}
      rowGap={5}
    >
      <Box
        onMouseOver={maybeApplyCallback(!isMobileOrTablet, onMouseOver)}
        onMouseOut={maybeApplyCallback(!isMobileOrTablet, onMouseExit)}
        borderRadius={12}
        overflow="hidden"
        position="relative"
        width="100%"
        aspectRatio="5 / 7"
      >
        {!isMobileOrTablet && (
          <Box
            position="relative"
            paddingTop="177.78%"
            backgroundColor="mono1"
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundImage={`url('${videoThumbnailUrl}')`}
          >
            <Box as="iframe" ref={playerRef} border="none" outline="none" width="100%" height="100%" position="absolute" top={0} left={0} title="d" loading="lazy" src={videoUrl} />
          </Box>
        )}

        <Box
          ref={placeholderImageRef}
          position="absolute"
          transition={animationTransition('opacity')}
          top={0}
          left={0}
          backgroundImage={`url('${imageUrl}')`}
          backgroundPosition="center"
          backgroundSize={'cover'}
          size="100%"
        />
      </Box>
      <Flex alignItems="center" flexDirection="column" width="100%" paddingX={1}>
        <Title maxWidth={150} textAlign="center" typographyVariant={['hed1', null, null, 'hed2']}>
          {shortName}
        </Title>
        <TextV2 textAlign="center" typographyVariant={['hed1', null, null, 'hed2']}>
          {trans.tileDescription[category]}
        </TextV2>
      </Flex>
    </Flex>
  );
};

Tile.Skeleton = () => {
  return (
    <Flex flexDirection="column" rowGap={5}>
      <Skeleton
        overrides={{
          PlaceholderRoot: {
            props: {
              borderRadius: 12
            }
          }
        }}
        width={'100%'}
        aspectRatio={'5 / 7'}
        borderRadius={'12px'}
      />
      <Flex alignItems="center" flexDirection="column" width="100%" paddingX={1} rowGap={3}>
        <Skeleton width={120} height={18} />
        <Skeleton width={150} height={18} />
      </Flex>
    </Flex>
  );
};

interface ProductOfferringCarouselProps {
  handleOnTileClick: (id: string) => void;
  category: ConciergeServiceCategory;
}

const Header: React.FC<{ rowGap?: BoxProps['rowGap'] }> = ({ children, rowGap }) => {
  return (
    <Flex rowGap={rowGap || { _: 1, sm2: 3 }} flexDirection="column" alignItems="center" marginBottom={{ _: 6, sm2: 8 }}>
      {children}
    </Flex>
  );
};

const Carousel: React.FC<{ category: ConciergeServiceCategory }> = ({ children, category }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  return (
    <CatalogCarousel itemSpacing={isMobileOrTablet ? 16 : 40} peekMobileExtraSize={48} totalNumberOfItems={PRODUCT_OFFERINGS[category].length} columns={[2, 3, 4, 5, 6]} enablePeek>
      {children}
    </CatalogCarousel>
  );
};

export const ProductOfferringCarousel = ({ handleOnTileClick, category }: ProductOfferringCarouselProps) => {
  const { t2 } = useTranslation('stationery');

  const trans = t2('dashboard', 'conciergeServiceSection', 'carousel');

  return (
    <Box>
      <Header>
        <TextV2 typographyVariant={{ _: 'body2', sm2: 'hed4' }}>{trans.eyebrow}</TextV2>
        <TextV2 typographyVariant={{ _: 'hed4', sm2: 'display1' }} fontWeight={{ _: 600, sm2: 600 }}>
          {trans.title[category]}
        </TextV2>
      </Header>
      <Carousel category={category}>
        {PRODUCT_OFFERINGS[category].map((offering, index) => (
          <CatalogCarousel.Item key={index}>
            <Tile sortIndex={index} data={offering} onClick={handleOnTileClick} category={category} />
          </CatalogCarousel.Item>
        ))}
      </Carousel>
    </Box>
  );
};

ProductOfferringCarousel.Skeleton = ({ category }: { category: ConciergeServiceCategory }) => {
  return (
    <Box>
      <Header rowGap={5}>
        <Skeleton width={242} height={6} />
        <Skeleton width={380} height={7} />
      </Header>
      <Carousel category={category}>
        {PRODUCT_OFFERINGS[category].map((_, index) => (
          <CatalogCarousel.Item key={index}>
            <Tile.Skeleton />
          </CatalogCarousel.Item>
        ))}
      </Carousel>
    </Box>
  );
};
