/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Arrow Right',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.61104 14.3889C8.39625 14.1741 8.39625 13.8258 8.61104 13.611L13.6721 8.54995H0.999951C0.696195 8.54995 0.449951 8.30371 0.449951 7.99995C0.449951 7.69619 0.696195 7.44995 0.999951 7.44995H13.6721L8.61104 2.38886C8.39625 2.17407 8.39625 1.82583 8.61104 1.61104C8.82583 1.39625 9.17407 1.39625 9.38886 1.61104L15.3889 7.61104C15.6036 7.82583 15.6036 8.17407 15.3889 8.38886L9.38886 14.3889C9.17407 14.6036 8.82583 14.6036 8.61104 14.3889Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
