import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { PropAsset } from '../../../../usePropValues';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import ContentCarousel from './components/Carousel';
import { useGalleryGridStyle } from '../../../../useGalleryGridStyle';
import { animationTransition } from '@shared/utils/animationTransition';
import { BOX_SHADOW_60_02, BOX_SHADOW_8 } from '@apps/card/boxShadow';
import { desktopAspectX, desktopAspectY } from '../../../CardsConfig';

type ValuePropCardProps = {
  data: PropAsset & { type: 'prop' };
};

const VALUE_PROP_SMALL_STYLES = {
  borderRadius: 1,
  boxShadow: BOX_SHADOW_8,
  height: '95%',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  margin: '16px',
  transition: animationTransition('box-shadow'),
  _groupHover: {
    boxShadow: `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
  }
} as const;

const VALUE_PROP_LARGE_STYLES = {
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',
  border: '1px solid hsl(0, 0%, 90%)'
} as const;

export const ValuePropCard = (props: ValuePropCardProps) => {
  const isMobile = useIsMobileScreen();
  const { data } = props;
  const { contentList, row, column, columns = 1 } = data;

  const { assetPreviewFrameBackground, valuePropSize } = useGalleryGridStyle();

  const valuePropStylePayload = valuePropSize === 'large' ? VALUE_PROP_LARGE_STYLES : VALUE_PROP_SMALL_STYLES;
  // Calculate grid-area CSS property
  // Locates prop within the grid.
  const gridAreaValue = `${row + 1} / ${column + 1} / ${row + 1} / span ${columns}`;
  // Aspect ratio for mobile / desktop factoring in width in columns.
  const aspectRatio = isMobile ? `${1 * columns} / 2` : `${Number(desktopAspectX) * columns} / ${desktopAspectY}`;

  const pagesLength = contentList.filter(asset => asset.responsiveTarget === (isMobile ? 'mobile' : 'desktop')).length;

  if (pagesLength == 0) {
    return null;
  }

  return (
    <Flex gridArea={gridAreaValue} aspectRatio={valuePropSize === 'large' ? aspectRatio : 'unset'} background={assetPreviewFrameBackground} width="100%" height="100%">
      <Box height="100%" width="100%" overflow="hidden" position="relative" style={valuePropStylePayload}>
        <ContentCarousel autoRotatePeriodMs={7500} content={contentList} currentResponsiveSize={isMobile ? 'mobile' : 'desktop'} {...data} />
      </Box>
    </Flex>
  );
};
