import React from 'react';
import { CardPreviewPane } from './components/PremiumDigitalCustomizationContent/PremiumDigitalPreviewPane';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';

import { PremiumDigitalCustomizationPane } from './components/PremiumDigitalCustomizationContent/PremiumDigitalCustomizationPane';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { TitleBlock } from './components/TitleBlock';
import { PdpLayout } from './components/PdpLayout/PdpLayout';
import { usePriceSummary } from '@apps/card/components/PriceSummary/PriceSummary';
import { BasePdpImplementationProps } from './ProductDetailsPage';
import { StationeryTemplate } from './ProductDetailsPage.types';
import { MinimalRequiredTemplateDataForDraft } from '../DesignsGallery/DesignsGallery.types';

interface PremiumDigitalPdpProps extends BasePdpImplementationProps {
  template: StationeryTemplate;
  onCustomizeClick: (template: MinimalRequiredTemplateDataForDraft, subtotalInMinorUnits?: number) => void;
}
export const PremiumDigitalPdp = (props: PremiumDigitalPdpProps) => {
  const { cardFormatControl, intersectionRef, isCreatingDraft, onCustomizeClick, showCustomizeButtonInHeader, template, designName, templateCategory, eventId, isFoil } = props;

  const themeJson = template.themeJSON;

  const isMobileOrTablet = useIsMobileOrTablet();

  const { totalInMinorUnits } = usePriceSummary({ templateCategory, priceUpTo: 'cardFront', cardConfig: template.themeJSON });

  const handleOnCustomizeNowClick = useEventCallback(() => {
    onCustomizeClick(template, totalInMinorUnits);
  });

  return (
    <PdpLayout
      isFoil={isFoil}
      designName={designName}
      isPremium={template.premium}
      templateCategory={templateCategory}
      onCustomizeNowClick={handleOnCustomizeNowClick}
      isCreatingDraft={isCreatingDraft}
      cardFormatControl={cardFormatControl}
      showCustomizeButtonInHeader={showCustomizeButtonInHeader}
      titleBlock={() => <TitleBlock format={cardFormatControl.cardFormat} title={designName} isFoil={isFoil} />}
      previewPane={() => (
        <CardPreviewPane
          eventId={eventId}
          templateId={template.id}
          isFavorite={template.isFavorite}
          templateCategory={templateCategory}
          cardFront={themeJson.card.front}
          cardShape={themeJson.customizations.shape}
          cardJson={themeJson}
          thumbnailUrl={template.thumbnailUrl!}
          themeId={template.themeId}
        />
      )}
      customizationPane={() => (
        <PremiumDigitalCustomizationPane
          hideCustomizeNowButton={isMobileOrTablet}
          intersectionRef={intersectionRef}
          isCreatingDraft={isCreatingDraft}
          onCustomizeClick={handleOnCustomizeNowClick}
        />
      )}
    />
  );
};
