import { Box } from '@withjoy/joykit';
import { AssetContainer, GalleryAsset } from './GenericPreviewPane';
import React from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

const isPreviewPaneAsset = (value: unknown): value is PreviewPaneAsset => {
  if (!value) {
    return false;
  }

  if (!Object.hasOwn(value, 'type')) {
    return false;
  }

  if (!Object.hasOwn(value, 'src')) {
    return false;
  }

  if (!Object.hasOwn(value, 'key')) {
    return false;
  }

  return true;
};

const isPreviewPaneAssets = (value: unknown): value is PreviewPaneAsset[] => {
  if (!value) {
    return false;
  }

  if (!Array.isArray(value)) {
    return false;
  }

  if (value.some(element => !isPreviewPaneAsset(element))) {
    return false;
  }

  return true;
};

type Vendor = 'taylor' | 'joy_digital';

type PreviewPaneAsset = {
  categoryList: string[];
  type: 'image' | 'video';
  label?: string;
  key: string;
  src: string;
  thumbnailUrl?: string;
};

const validatePreviewPaneAssetFilters = (asset: PreviewPaneAsset, occasion: string, category: StationeryTemplateCategoryEnum, vendor: Vendor): boolean => {
  return asset.categoryList.some(filterString => {
    const filterStringComponents = filterString.split('/');
    const [filterOccasion, filterCategory, filterVendor] = filterStringComponents;

    // Not valid if occasions do not match.
    if (filterOccasion !== '*' && filterOccasion !== occasion) {
      return false;
    }

    // Not valid if categories do not match.
    if (filterCategory !== '*' && filterCategory !== category) {
      return false;
    }

    // Not valid if vendors do not match.
    if (filterVendor !== '*' && filterVendor !== vendor) {
      return false;
    }

    return true;
  });
};

// Filters to assets for current product only.
const filterPreviewPaneAssets = (assets: PreviewPaneAsset[], occasion: string, category: StationeryTemplateCategoryEnum, vendor: Vendor): PreviewPaneAsset[] => {
  return assets.filter(asset => {
    return validatePreviewPaneAssetFilters(asset, occasion, category, vendor);
  });
};

// Convert values from Amplitude to usable GalleryAsset object.
const mapAssetsToGalleryAssetObjects = (assets: PreviewPaneAsset[]): GalleryAsset[] => {
  return assets.map(asset => {
    if (asset.type === 'video' && asset.thumbnailUrl) {
      return {
        render: () => (
          <AssetContainer label={asset.label ?? ''} key={`video-${asset.key}`} enforceAspectRatio>
            <Box
              top={0}
              left={0}
              width="100%"
              height="100%"
              position="absolute"
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundImage={`url('${asset.thumbnailUrl}')`}
            >
              <Box
                as="iframe"
                border="none"
                outline="none"
                width="100%"
                height="100%"
                position="absolute"
                top={0}
                left={0}
                loading="lazy"
                src={`${asset.src}&autoplay=1&controls=0&loop=1&mute=1&background=1`}
              />
            </Box>
          </AssetContainer>
        ),
        thumbnailUrl: asset.thumbnailUrl
      };
    }
    return {
      render: () => <AssetContainer enforceAspectRatio key={asset.key} label={asset.label ?? ''} backgroundImageUrl={asset.src} />,
      thumbnailUrl: asset.src
    };
  });
};

export const useCardPreviewPaneAssets = (occasion: string, category: StationeryTemplateCategoryEnum, vendor: Vendor): GalleryAsset[] => {
  const { value, payload } = useFeatureValue('printPdpPreviewPaneAssetsData');

  if (value !== 'on' || !isPreviewPaneAssets(payload)) {
    return [];
  }

  const assets = filterPreviewPaneAssets(payload, occasion, category, vendor);
  const GalleryAssets = mapAssetsToGalleryAssetObjects(assets);
  return GalleryAssets;
};
