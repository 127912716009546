import { PropAsset, TileTypes } from './usePropValues';
import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { useCallback, useMemo } from 'react';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
const PAGE_SIZE = 180;
const PAGE_QUERY_PARAM = 'page';

const isValidValueProp = (prop: PropAsset, isMobile: boolean) => {
  const hasMobileContent = prop.contentList.some(c => c.responsiveTarget === 'mobile');
  const hasDesktopContent = prop.contentList.some(c => c.responsiveTarget === 'desktop');

  if (isMobile && !hasMobileContent) {
    return false;
  }

  if (!isMobile && !hasDesktopContent) {
    return false;
  }

  return true;
};

const isValuePropFits = (prop: PropAsset, gridRows: number) => {
  return prop.row < gridRows;
};

const calculateSpaceOccupied = (items: TileTypes[]) => {
  return items.reduce((acc, item) => acc + (('columns' in item && item.columns) || 1), 0);
};

export const useStationeryPagination = (templatesWithProps: TileTypes[], columnCount: number) => {
  const isMobile = useIsMobileScreen();
  const [pageQueryParam, setPage] = useManagedSearchParam(PAGE_QUERY_PARAM);
  const currentPage = parseInt(pageQueryParam || '1');

  const { items, pages } = useMemo(() => {
    const galleryGridRows = PAGE_SIZE / columnCount - 1;

    const valueProps = templatesWithProps.filter(template => template.type === 'prop') as (PropAsset & { type: 'prop' })[];
    const validValueProps = valueProps.filter(prop => isValidValueProp(prop, isMobile) && isValuePropFits(prop, galleryGridRows));
    const spaceOccupiedForValueProps = calculateSpaceOccupied(validValueProps);

    const templatesWithoutValueProps = templatesWithProps.filter(template => template.type !== 'prop');
    const totalItems = spaceOccupiedForValueProps + templatesWithoutValueProps.length;

    // If we're on the first page, we show value props
    const valuePropsToDisplay = currentPage > 1 ? [] : validValueProps;

    const templatesOffset = currentPage === 1 ? 0 : PAGE_SIZE * (currentPage - 1) - spaceOccupiedForValueProps;
    const templatesLimit = currentPage === 1 ? PAGE_SIZE - spaceOccupiedForValueProps : PAGE_SIZE;

    const templatesToDisplay = templatesWithoutValueProps.slice(templatesOffset, templatesOffset + templatesLimit);
    const items = [...templatesToDisplay, ...valuePropsToDisplay];

    return {
      items,
      pages: Math.ceil(totalItems / PAGE_SIZE)
    };
  }, [templatesWithProps, currentPage, isMobile, columnCount]);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page.toString());
    },
    [setPage]
  );

  return { items, page: currentPage, pages, setPage: handlePageChange };
};
