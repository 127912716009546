import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useCallback, useMemo } from 'react';
import { StationeryTemplate } from './DesignsGallery.types';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useHistory } from '@react-router';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { ProductOffering } from '../ConciergeServiceSection/ConciergeServiceSection.types';
import useConciergeTiles from './useConciergeTiles';
import { doesProductComponentMatch } from '@apps/card/routes/CardCustomizer/usePromotionConfig';
import { useCachedRandom } from './useCachedRandom';
import { useDesignGalleryFiltersContext } from './DesignGalleryFiltersProvider';
import { cloneDeep } from 'lodash-es';
import { useApplySearchFiltersToTemplates } from './useApplySearchFiltersToTemplates';
import { useDefaultMediaMatchContext } from '@shared/utils/media/DefaultMediaMatchProvider';

export type PropsPayload = {
  props: PropAsset[];
  repeatTiles?: boolean;
  repeatTilesCategoryBlocklist?: StationeryTemplateCategoryEnum[];
  randomizeOrder?: boolean;
};

export type PropContent =
  | {
      type: 'image';
      responsiveTarget: 'desktop' | 'mobile';
      src: string;
    }
  | {
      type: 'video';
      responsiveTarget: 'desktop' | 'mobile';
      videoUrl: string;
      videoThumbnailUrl?: string;
      backgroundColor?: string;
    };

export type PropPositionOverride = {
  row: number;
  column: number;
};

export type PropAsset = {
  link?: string;
  label?: string;
  telemetryId?: string;
  categoryList: string[];
  contentList: PropContent[];
  onClick?: () => void;
  columns?: number;
  preferredPosition?: PropPositionOverride;
  row: number;
  column: number;
};

export type CardLinkAsset = {
  desktop: string;
  mobile: string;
  category: StationeryTemplateCategoryEnum;
  onClick?: () => void;
  titleText: string;
};

export type ConciergeTileAsset = ProductOffering & { index: number };

export type TileTypes =
  | (PropAsset & { type: 'prop' })
  | (StationeryTemplate & { type: 'template' })
  | (CardLinkAsset & { type: 'cardlink' })
  | (ConciergeTileAsset & { type: 'concierge' });

interface Arguments {
  category: StationeryTemplateCategoryEnum;
  templates: StationeryTemplate[];
  conciergeEnabled: boolean;
}

const isValidCategory = (value: unknown): value is StationeryTemplateCategoryEnum => {
  if (typeof value !== 'string') {
    return false;
  }

  const templateValues = Object.values(StationeryTemplateCategoryEnum);
  return templateValues.includes(value as StationeryTemplateCategoryEnum);
};

const isCategoryRepeatBlocklisted = (category: StationeryTemplateCategoryEnum, payload: PropsPayload) => {
  if (!payload.repeatTilesCategoryBlocklist) {
    return false;
  }

  return payload.repeatTilesCategoryBlocklist.includes(category);
};

const isPropContent = (value: unknown): value is PropContent => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  const content = value as PropContent;

  if (!Object.hasOwn(value, 'type')) {
    return false;
  }

  if (content.type !== 'image' && content.type !== 'video') {
    return false;
  }

  if (!Object.hasOwn(value, 'responsiveTarget')) {
    return false;
  }

  if (content.responsiveTarget !== 'desktop' && content.responsiveTarget !== 'mobile') {
    return false;
  }

  if (content.type === 'image') {
    if (!Object.hasOwn(value, 'src') && typeof content.src !== 'string') {
      return false;
    }
  } else if (content.type === 'video') {
    if (!Object.hasOwn(value, 'videoUrl') && typeof content.videoUrl !== 'string') {
      return false;
    }

    const noThumbnail = !Object.hasOwn(value, 'videoThumbnailUrl') && typeof content.videoThumbnailUrl !== 'string';
    const noBackgroundColor = !Object.hasOwn(value, 'backgroundColor') && typeof content.backgroundColor !== 'string';

    if (noThumbnail && noBackgroundColor) {
      return false;
    }
  }

  return true;
};

const isValidPositionOverride = (value: unknown): value is PropPositionOverride => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  const asPosition = value as PropPositionOverride;
  if (!Object.hasOwn(value, 'row')) {
    return false;
  }

  if (typeof asPosition.row !== 'number') {
    return false;
  }

  if (!Object.hasOwn(value, 'column')) {
    return false;
  }

  if (typeof asPosition.column !== 'number') {
    return false;
  }

  return true;
};

const isValidProp = (value: unknown): value is PropAsset => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  const asset = value as PropAsset;

  if (Object.hasOwn(value, 'label')) {
    if (typeof asset.label !== 'string') {
      return false;
    }
    if (!Object.hasOwn(value, 'link') && typeof asset.link !== 'string') {
      return false;
    }
  }

  if (!Object.hasOwn(value, 'categoryList') && typeof asset.categoryList !== 'string') {
    return false;
  }

  if (!Object.hasOwn(value, 'contentList')) {
    return false;
  }

  if (!Array.isArray(asset.contentList)) {
    return false;
  }

  if (asset.contentList.some(content => !isPropContent(content))) {
    return false;
  }

  if (Object.hasOwn(value, 'preferredPosition')) {
    if (!isValidPositionOverride(asset.preferredPosition)) {
      return false;
    }
  }

  return true;
};

const isValidPropsPayload = (payload: unknown): payload is PropsPayload => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  const asPayload = payload as PropsPayload;

  if (!asPayload.props) {
    return false;
  }

  if (asPayload.props.some(el => !isValidProp(el))) {
    return false;
  }

  if (Object.hasOwn(payload, 'repeatTilesCategoryBlacklist')) {
    if (!Array.isArray(asPayload.repeatTilesCategoryBlocklist)) {
      return false;
    }

    if (asPayload.repeatTilesCategoryBlocklist?.some(category => !isValidCategory(category))) {
      return false;
    }
  }

  return true;
};

const cardCategoryMatches = (categoriesList: string[], context: { occasion: string; category: StationeryTemplateCategoryEnum }) => {
  return categoriesList.some(category => {
    const categoryComponents = category.split('/');
    const [targetOccasion, targetCategory] = categoryComponents;
    const { category: currentCategory, occasion: currentOccasion } = context;

    if (!doesProductComponentMatch(targetOccasion, currentOccasion)) {
      return false;
    }

    if (!doesProductComponentMatch(targetCategory, currentCategory)) {
      return false;
    }

    return true;
  });
};

const injectImagineCard = (templatesWithProps: TileTypes[], category: StationeryTemplateCategoryEnum, onClickCards: () => void) => {
  const imagineProps = [
    {
      desktop: 'https://withjoy.com/media/raw/print/savethedate-simple.gif',
      mobile: 'https://withjoy.com/media/raw/print/savethedate-simple.gif',
      category: StationeryTemplateCategoryEnum.saveTheDate,
      onClick: onClickCards,
      titleText: 'Imagine Your Card'
    },
    {
      desktop: 'https://withjoy.com/media/raw/print/invitation-fade-720x1008.gif',
      mobile: 'https://withjoy.com/media/raw/print/invitation-simple.gif',
      category: StationeryTemplateCategoryEnum.invitation,
      onClick: onClickCards,
      titleText: 'Imagine Your Card'
    }
  ];

  imagineProps.forEach(prop => {
    if (prop.category === category) {
      templatesWithProps.splice(2, 0, {
        ...prop!,
        type: 'cardlink'
      });
    }
  });
};

// Checks to see if the specified row + column is already
// taken by a manually positioned card. Returns the closest avaliable
// position, and offset for tracking rolling offset of cards downstream.
const checkForOverlap = (row: number, column: number, rowGap: number, furthestColumn: number, cards: PropAsset[]) => {
  let offset = 0;
  cards.forEach(card => {
    if (card.preferredPosition) {
      // Calculate comparison card start & end position
      const preferredColumn = card.preferredPosition.column;
      const columnSpilloverRows = Math.floor(preferredColumn / furthestColumn);
      const actualColumnStart = preferredColumn % furthestColumn;
      const actualColumnEnd = actualColumnStart + (card.columns ?? 1) - 1;
      const actualRow = card.preferredPosition.row + columnSpilloverRows;

      // Check if we overlap target card.
      if (row == actualRow && column >= actualColumnStart && column <= actualColumnEnd) {
        offset += 1 + rowGap;
        return;
      }
    }
  });

  return { row: row + offset, column, offset: -offset };
};

const RESPONSIVE_CONFIG = {
  _: { row: 2, rowGap: 2 },
  sm2: { row: 3, rowGap: 2 },
  md3: { row: 4, rowGap: 1 },
  lg: { row: 5, rowGap: 1 },
  xl: { row: 6, rowGap: 1 }
};

const usePropValues = (args: Arguments) => {
  const { category, templates, conciergeEnabled } = args;
  const { value: showImagineTab } = useFeatureValue('printImagineGalleryEnabled');
  const { value: showImagineProps } = useFeatureValue('printImagineShowValueProps');
  const { value: useNewProps, payload: newProps } = useFeatureValue('printValuePropsData');
  const { getTotalActiveFilterCount } = useDesignGalleryFiltersContext();
  const history = useHistory();
  const { getCardImaginePath } = useCardsRouterContext();
  const injectConcierge = useConciergeTiles(category);
  const cachedRandom = useCachedRandom({ deps: [category] });
  const shuffleRandom = useCachedRandom({ deps: [category] });

  const { useSearchResults } = useApplySearchFiltersToTemplates();
  const searchResults = useSearchResults();
  const { searchCompleted } = searchResults;

  const goToImagine = useCallback(() => {
    history.push(getCardImaginePath('wedding', category));
  }, [category, getCardImaginePath, history]);

  const { defaultScreen } = useDefaultMediaMatchContext();

  // row - number of cards per row, rowGap - number of empty rows between cards
  const [config] = useResponsive(
    {
      values: RESPONSIVE_CONFIG
    },
    defaultScreen === 'desktop' ? RESPONSIVE_CONFIG.lg : defaultScreen === 'tablet' ? RESPONSIVE_CONFIG.md3 : RESPONSIVE_CONFIG._
  );

  const shouldInjectProps = getTotalActiveFilterCount() === 0 && !searchCompleted;

  const tiles = useMemo(() => {
    const templatesWithProps: TileTypes[] = [...templates].map(t => ({
      ...t,
      type: 'template'
    }));

    if (!shouldInjectProps) {
      return templatesWithProps;
    }

    let cards: PropAsset[] = [];

    const totalRows = templates.length / (config?.row ?? 1);
    const requiredPropsToFillGallery = totalRows / ((config?.rowGap ?? 1) + 1);

    if (useNewProps && isValidPropsPayload(newProps)) {
      const originalValuePropsList = newProps.props.filter(card => {
        return cardCategoryMatches(card.categoryList, {
          occasion: 'wedding',
          category
        });
      });

      cards = cloneDeep(originalValuePropsList);

      if ((newProps.repeatTiles ?? false) && !isCategoryRepeatBlocklisted(category, newProps)) {
        const shuffledPool = originalValuePropsList.sort(a => {
          return shuffleRandom(originalValuePropsList.indexOf(a)) - 0.5;
        });

        if (cards.length < requiredPropsToFillGallery) {
          for (let i = cards.length; i < requiredPropsToFillGallery; i++) {
            const card = shuffledPool[i % originalValuePropsList.length];
            // Do not repeat cards with specified positions.
            if (!card.preferredPosition) {
              cards.push(card);
            }
          }
        }
      }
    }

    const shouldInjectFeatured = showImagineTab === 'on' && showImagineProps === 'on';
    shouldInjectFeatured && injectImagineCard(templatesWithProps, category, goToImagine);

    if (config) {
      // Track how many cards are not following normal positioning
      // So we can account for that in the following cards.
      let rowRollingOffset = 0;
      // Make sure we do not place more props than can fit.
      const maxRows = (templatesWithProps.length + cards.length) / (config.row * (config.rowGap + 1)) - 1;
      const manuallyPositionedCards = cards.filter(prop => !!prop.preferredPosition);

      cards.forEach((card, row) => {
        if (row >= maxRows) {
          return;
        }

        const furthestColumn = Math.max(config.row - (card.columns ?? 1 - 1), 1);
        if (!card.preferredPosition) {
          // Randomly positioned cards
          const positionOnRow = Math.floor(cachedRandom(row) * furthestColumn);
          const cardRow = (config.rowGap + 1) * (row - rowRollingOffset + 1);
          const { row: safeRow, column: safeCol, offset } = checkForOverlap(cardRow, positionOnRow, config.rowGap, furthestColumn, manuallyPositionedCards);
          card.row = safeRow;
          card.column = safeCol;
          rowRollingOffset += offset;
        } else {
          // Manually positioned cards
          const preferredColumn = card.preferredPosition.column;
          const columnSpilloverRows = Math.floor(preferredColumn / furthestColumn);
          card.column = preferredColumn % furthestColumn;
          card.row = card.preferredPosition.row + columnSpilloverRows;
          rowRollingOffset++;
        }
        templatesWithProps.push({ ...card, type: 'prop' });
      });
    }

    if (conciergeEnabled) {
      return injectConcierge(templatesWithProps, category);
    }

    return templatesWithProps;
  }, [
    templates,
    shouldInjectProps,
    config,
    useNewProps,
    newProps,
    showImagineTab,
    showImagineProps,
    category,
    goToImagine,
    conciergeEnabled,
    shuffleRandom,
    cachedRandom,
    injectConcierge
  ]);

  return {
    templatesWithProps: tiles,
    galleryColumnCount: config.row
  };
};

export default usePropValues;
