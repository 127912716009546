import { getSessionStorage } from '@shared/core';
import { useCallback } from 'react';

interface CachedRandom {
  deps: unknown[];
}

const ss = getSessionStorage();

export const useCachedRandom = (args: CachedRandom) => {
  const { deps } = args;

  const get = useCallback(
    (key: string | number) => {
      const currentValue = ss.getItem(String(key));

      if (currentValue) {
        return parseFloat(currentValue);
      }

      const random = Math.random();

      ss.setItem(String(key), String(random));

      return random;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deps]
  );

  return get;
};
