import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';

interface ValueProp {
  icon: JSX.Element;
  description: string;
  title?: string;
}

const InlineValuePropItem = (props: ValueProp) => {
  const { icon, description } = props;
  return (
    <Flex justifyContent="center" flexDirection="column" rowGap={5} alignItems="center" textAlign="center" height={104} maxWidth={122}>
      {icon}
      <TextV2 typographyVariant="hed1">{description}</TextV2>
    </Flex>
  );
};

interface Props {
  items: Array<ValueProp>;
}

export const InlineValueProps = ({ items }: Props) => {
  return (
    <Flex marginTop={5} marginBottom={10} columnGap={5} justifyContent="center">
      {items.map((item, index) => (
        <InlineValuePropItem key={index} icon={item.icon} description={item.description} />
      ))}
    </Flex>
  );
};

const ListValuePropItem = (props: ValueProp) => {
  const { icon, description, title } = props;
  return (
    <Flex columnGap={5} alignItems="center">
      {icon}
      <Box>
        {title && (
          <TextV2 typographyVariant="body1" fontWeight={600}>
            {title}
          </TextV2>
        )}
        <TextV2 typographyVariant="body1">{description}</TextV2>
      </Box>
    </Flex>
  );
};

export const ListValueProps = ({ items, sectionTitle, ...props }: Props & { sectionTitle: string } & BoxProps) => {
  return (
    <Flex flexDirection="column" rowGap={5} {...props}>
      <TextV2 typographyVariant="body2" fontWeight={600}>
        {sectionTitle}
      </TextV2>
      {items.map((item, index) => (
        <ListValuePropItem key={index} icon={item.icon} description={item.description} title={item.title} />
      ))}
    </Flex>
  );
};
