import { isLightColor } from '@shared/utils/color';

/**
 * Determines if a string is in rgb or rgba format.  the alpha value is ignored if rgba.
 * @param r red channel value 0-255
 * @param g green channel value 0-255
 * @param g blue channel value 0-255
 * @returns object {r: number, g:number, b:number } or undefined if the string is not in rgb format.
 */
export const toRGBObject = (
  rgbStr: string
):
  | {
      r: number;
      g: number;
      b: number;
    }
  | undefined => {
  // exit if input is not a non-zero length string
  if (!rgbStr || typeof rgbStr !== 'string') {
    return;
  }
  // match on 'rgb(' and 'rgba(' CSS color ))
  const match = /rgba?\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}).*/.exec(rgbStr);
  if (match) {
    const values = rgbStr.match(/\d+/g);
    // if we have an rgba string length may be 4.  We only care that length is at least 3
    if (values && values.length > 2) {
      const [red, green, blue] = values.map(Number);
      return {
        r: red,
        g: green,
        b: blue
      };
    }
  }
  return;
};

/**
 * Determines if a string is in css hex format ( '#ffcc44' ).
 * @param color string to test
 * @returns boolean true if the color is in css hex format ( '#ffcc44' ).
 */
export const isHexColor = (color: string): boolean => {
  return /^#[0-9A-F]{6}$/i.test(color);
};

export const hexToRgb = (hexColor: string) => {
  const base = hexColor.startsWith('#') ? 1 : 0;

  const r = parseInt(hexColor.substring(base, 3), 16);
  const g = parseInt(hexColor.substring(base + 2, 5), 16);
  const b = parseInt(hexColor.substring(base + 4, 7), 16);

  return { r, g, b };
};

/**
 * @param color Hex value format: #ffffff or ffffff
 * @param lightMultiplier darken multiplier value, example  1.5 to darken by 50%.
 * @param darkMultiplier lighten multiplier value, example  1.5 to lighten by 50%.
 * @returns string CSS rgb string value of the adjusted color
 */
export const shadeColor = (color: string, lightMultiplier: number, darkMultiplier: number): string => {
  let r = 220,
    g = 220,
    b = 220;
  const defaultColorString = `rgb(${r}, ${g}, ${b})`;

  if (!color && !isNaN(lightMultiplier) && !isNaN(darkMultiplier)) {
    return defaultColorString;
  }

  const rgbValues = toRGBObject(color);
  if (rgbValues) {
    r = rgbValues.r;
    g = rgbValues.g;
    b = rgbValues.b;
  } else if (isHexColor(color)) {
    const rgb = hexToRgb(color);
    r = rgb.r;
    g = rgb.g;
    b = rgb.b;
  } else {
    return defaultColorString;
  }

  const isLight = isLightColor(r, g, b);

  if (isLight) {
    r = Math.round(r / lightMultiplier);
    g = Math.round(g / lightMultiplier);
    b = Math.round(b / lightMultiplier);

    r = r < 255 ? r : r;
    g = g < 255 ? g : r;
    b = b < 255 ? b : r;
  } else {
    // If the color is dark we will lighten instead of shade so we don't get black blobs of color
    r = Math.round(r * darkMultiplier);
    if (r > 255) {
      r = 255;
    }
    g = Math.round(g * darkMultiplier);
    if (g > 255) {
      g = 255;
    }
    b = Math.round(b * darkMultiplier);
    if (b > 255) {
      b = 255;
    }

    r = r < 255 ? r : r;
    g = g < 255 ? g : r;
    b = b < 255 ? b : r;
  }

  return `rgb(${r}, ${g}, ${b})`;
};
