import React, { useCallback, useMemo } from 'react';
import { Box, BoxProps, TextV2, Flex, ButtonV2, SpacingStack, LinkV2 } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { FormattedEventScheduleFragment, EventScheduleFragment, Category } from '@graphql/generated';
import { ContentColumns } from '../../primitives';
import { AddToCalendar as AddToCalendarIcon, Direction } from '@withjoy/joykit/icons';
import { InfoBlock } from '../../primitives/InfoBlock';
import { DescriptionBlock, DressCodeBlock, MapBlock } from '../../components/ItineraryBlocks';
import { VirtualEventBlock } from '../../components/VirtualEventBlock';
import { variantStyles, VariantStyles } from './';
import { getScheduleGroupHeader, getTimeZoneAbbr, ScheduleListTestIds, useTestIds } from './ScheduleList.utils';
import { useTranslations, ScheduleListTranslations } from './ScheduleList.i18n';
import { AddToCalendar } from '@shared/components/AddToCalendar';
import { config } from '@static/js/env.config';
import { useLayout } from '../../layouts/LayoutProvider';
import { StyledVirtualEventContainer, styles } from '@apps/guest/packages/layout-engine/widgets/ScheduleList/ScheduleList.styles';
import { TelemetryProvider, useScheduleListTelemetry } from './ScheduleList.telemetry';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useScheduleListController } from '@apps/guest/packages/layout-engine/widgets/ScheduleList/ScheduleList.controller';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body3ToButtonsOverride,
  button2ToButtonsOverride,
  label6ToCaptionOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { OverrideTypography } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.types';
import { useColorPaletteProvider } from '../../layouts/ColorPaletteProvider/ColorPaletteProvider';

export type Variant = 'stack' | 'inline';

export interface ScheduleListProps
  extends Readonly<{
      // Add props here.
      eventId: string;
      eventHandle: string;
      variant?: Variant;
      layout?: string;
    }>,
    BoxProps {}

export interface ScheduleListContentProps
  extends Readonly<{
      eventId: string;
      formattedSchedule: ReadonlyArray<FormattedEventScheduleFragment>;
      tbaSchedule: ReadonlyArray<EventScheduleFragment>;
      eventHandle: string;
      variant?: Variant;
      layout?: string;
      shouldDisplayUnlockButton: Maybe<boolean>;
      handleUnlockDialogClick: () => void;
      unlockButtonText: string;
    }>,
    BoxProps {}

const PrimaryBlock: React.FC<{
  title: Maybe<string>;
  description: Maybe<string>;
  dressCode: Maybe<string>;
  eventNameMarkup?: JSX.Element;
  location: Maybe<string>;
  placeId: Maybe<string>;
  showMap: Maybe<boolean>;
  isToBeAnnounced: Maybe<boolean>;
  translations: ScheduleListTranslations;
  styleProps: VariantStyles['primaryBlock'];
  virtualEventMarkup?: JSX.Element;
  testIds: ScheduleListTestIds['listItemTestIds'];
  onAddressClicked?: (scheduleTitle: string) => void;
}> = props => {
  const {
    title,
    description,
    dressCode,
    isToBeAnnounced,
    translations,
    eventNameMarkup,
    location,
    placeId,
    showMap,
    styleProps,
    testIds,
    virtualEventMarkup,
    onAddressClicked
  } = props;

  if (!eventNameMarkup && !virtualEventMarkup && !description && !isToBeAnnounced && !dressCode && !location && !placeId) {
    return null;
  }

  return (
    <Box {...testIds.primaryBlock.prop} {...styleProps.container}>
      <InfoBlock.Group spread="md">
        {eventNameMarkup}
        {virtualEventMarkup}
        {description && <DescriptionBlock {...testIds.descriptionBlock.prop}>{description}</DescriptionBlock>}
        {isToBeAnnounced && !description && <DescriptionBlock {...testIds.descriptionBlock.prop}>{translations.toBeAnnouncedDescription}</DescriptionBlock>}
        {dressCode && <DressCodeBlock {...testIds.dressCodeblock.prop}>{dressCode}</DressCodeBlock>}
        {(location || placeId) && (
          <MapBlock
            handleClick={() => {
              onAddressClicked && onAddressClicked(title || '');
            }}
            {...testIds.mapBlock.prop}
            location={location}
            placeId={placeId}
            showMap={!!showMap}
          />
        )}
      </InfoBlock.Group>
    </Box>
  );
};

const SecondaryBlock: React.FC<{
  eventHandle: string;
  eventNameMarkup?: JSX.Element;
  timeframe?: string;
  virtualEventMarkup?: JSX.Element;
  styleProps: VariantStyles['secondaryBlock'];
  testIds: ScheduleListTestIds['listItemTestIds'];
  translations: ScheduleListTranslations;
  item?: EventScheduleFragment;
  onCalendarClicked?: (title: string, type: string) => void;
}> = ({ eventHandle, eventNameMarkup, styleProps, testIds, timeframe, translations, virtualEventMarkup, item, onCalendarClicked }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);
  const showDurationBlock = !!(eventNameMarkup || timeframe);
  const description = `${item?.description ? item.description + '\n\n' : ''}${item?.dressCode ? translations.dressCode + ': ' + item.dressCode + '\n\n' : ''}${
    translations.moreDetails
  }: ${config.clientUri + '/' + eventHandle + '/schedule'}`;
  return (
    <SpacingStack {...testIds.secondaryBlock.prop} {...styleProps.container}>
      {showDurationBlock && (
        <>
          <Box {...styleProps.durationBlockContainer}>
            {eventNameMarkup}
            {timeframe && (
              <GuestSiteTypographyOverride override={label6ToCaptionOverride}>
                <TextV2 {...testIds.timeframe.prop} typographyVariant="label6" {...styleProps.durationBlock}>
                  {timeframe}
                </TextV2>
              </GuestSiteTypographyOverride>
            )}
          </Box>
          {/* without a start time there is no reason to add to calendar */}
          <Flex columnGap={2}>
            {timeframe && item?.startTime?.timestamp && (
              <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
                <AddToCalendar
                  start={item?.startTime?.timestamp}
                  end={item?.endTime?.timestamp}
                  title={item?.name}
                  location={item?.location ?? ''}
                  description={description}
                  handleClickAddToCalendar={onCalendarClicked}
                >
                  <ButtonV2
                    {...testIds.addToCalendar.prop}
                    startIcon={() => <AddToCalendarIcon />}
                    variant="outline"
                    shape="rounded"
                    size={'lg'}
                    borderColor="mono3"
                    color="mono14"
                    _hover={{ backgroundColor: 'mono2', borderColor: 'mono3', color: 'mono14' }}
                    _active={{ backgroundColor: 'mono3', borderColor: 'mono3', color: 'mono14' }}
                    _disabled={{
                      backgroundColor: 'mono3',
                      borderColor: 'mono3',
                      color: 'mono8',
                      cursor: 'not-allowed'
                    }}
                    {...styleProps.calendarBlock}
                  >
                    {isMobile && item?.location ? translations.addToCal : translations.addToCalendar}
                  </ButtonV2>
                </AddToCalendar>
              </GuestSiteTypographyOverride>
            )}

            {isMobile && item?.location && (
              <GuestSiteTypographyOverride override={body3ToButtonsOverride}>
                <ButtonV2
                  {...testIds.getDirections.prop}
                  startIcon={() => <Direction />}
                  variant="outline"
                  shape="rounded"
                  size={'lg'}
                  borderColor="mono3"
                  color="mono14"
                  _hover={{ backgroundColor: 'mono2', borderColor: 'mono3', color: 'mono14' }}
                  _active={{ backgroundColor: 'mono3', borderColor: 'mono3', color: 'mono14' }}
                  _disabled={{
                    backgroundColor: 'mono3',
                    borderColor: 'mono3',
                    color: 'mono8',
                    cursor: 'not-allowed'
                  }}
                  {...styleProps.calendarBlock}
                >
                  <LinkV2
                    href={`https://maps.google.com/?q=${item.location}`}
                    isExternal={true}
                    color={'mono14'}
                    textDecoration={'none'}
                    _hover={{ color: 'mono14' }}
                    _visited={{ color: 'mono14' }}
                  >
                    {translations.getDirections}
                  </LinkV2>
                </ButtonV2>
              </GuestSiteTypographyOverride>
            )}
          </Flex>
        </>
      )}
      {virtualEventMarkup}
    </SpacingStack>
  );
};

const ScheduleListItem: React.FC<{
  eventHandle: string;
  item: EventScheduleFragment;
  styleProps: VariantStyles;
  isHeaderInSecondaryBlock: boolean;
  translations: ScheduleListTranslations;
  showTimeZone: boolean;
  isToBeAnnounced: boolean;
  onAddressClicked?: (title: string) => void;
  onCalendarClicked?: (title: string, type: string) => void;
  onVirtualEventBlockClicked?: () => void;
}> = React.memo(
  ({
    eventHandle,
    item,
    isHeaderInSecondaryBlock,
    isToBeAnnounced = false,
    translations,
    styleProps,
    showTimeZone,
    onAddressClicked,
    onCalendarClicked,
    onVirtualEventBlockClicked
  }) => {
    const { id, startTime, endTime, name, virtualEventLink, description, dressCode, location, placeId, showMap } = item;
    const timezone = getTimeZoneAbbr(startTime?.milliseconds, startTime?.timezone);
    const timeframe = showTimeZone
      ? translations.timeframeTz(startTime?.hostTimeString, endTime?.hostTimeString, timezone)
      : translations.timeframe(startTime?.hostTimeString, endTime?.hostTimeString);
    const { listItemTestIds: testIds } = useTestIds(id);
    const { layout } = useLayout();

    const eventNameOverride: OverrideTypography = useMemo(() => [{ variant: layout === 'aloha' ? 'hed4' : 'hed3', category: Category.SUB_HEADING }], [layout]);

    const eventNameMarkup = (
      <GuestSiteTypographyOverride override={eventNameOverride}>
        <TextV2
          {...testIds.eventName.prop}
          marginTop={{ sm4: '-1.5px' }}
          marginBottom={'0.875rem'}
          typographyVariant={layout === 'aloha' ? 'hed4' : 'hed3'}
          lineHeight={1.35}
          fontWeight={500}
          wordBreak="break-word"
          textAlign={['center', 'center', 'inherit']}
        >
          {name}
        </TextV2>
      </GuestSiteTypographyOverride>
    );
    const virtualEventMarkup = virtualEventLink ? (
      <StyledVirtualEventContainer __css={styles.virtualEventContainer} {...testIds.livestream.prop}>
        <VirtualEventBlock onVirtualEventBlockClicked={onVirtualEventBlockClicked} startTime={startTime} endTime={endTime} virtualEvent={virtualEventLink} />
      </StyledVirtualEventContainer>
    ) : undefined;

    const isSecondaryBlockHasContent = !!(virtualEventLink || location || dressCode || description);

    return (
      <ContentColumns
        centered={true}
        role="listitem"
        key={id}
        {...{ ...styleProps.scheduleDayItemContainer, ...(!isSecondaryBlockHasContent ? styles.columnReverseContainer : {}) }}
      >
        {!isToBeAnnounced && (
          <ContentColumns.Column {...styleProps.secondaryBlockColumns}>
            <SecondaryBlock
              eventHandle={eventHandle}
              eventNameMarkup={isHeaderInSecondaryBlock ? eventNameMarkup : undefined}
              styleProps={isSecondaryBlockHasContent ? styleProps.secondaryBlock : variantStyles['stack'].secondaryBlock}
              testIds={testIds}
              item={item}
              timeframe={isToBeAnnounced ? undefined : timeframe}
              translations={translations}
              virtualEventMarkup={isHeaderInSecondaryBlock ? virtualEventMarkup : undefined}
              onCalendarClicked={onCalendarClicked}
            />
          </ContentColumns.Column>
        )}

        <ContentColumns.Column {...styleProps.primaryBlockColumns}>
          <PrimaryBlock
            title={name}
            description={description}
            dressCode={dressCode}
            eventNameMarkup={isHeaderInSecondaryBlock && !isToBeAnnounced ? undefined : eventNameMarkup}
            isToBeAnnounced={isToBeAnnounced}
            translations={translations}
            location={location}
            placeId={placeId}
            showMap={showMap}
            styleProps={styleProps.primaryBlock}
            testIds={testIds}
            virtualEventMarkup={isHeaderInSecondaryBlock ? undefined : virtualEventMarkup}
            onAddressClicked={onAddressClicked}
          />
        </ContentColumns.Column>
      </ContentColumns>
    );
  }
);

const ScheduleListContent = React.forwardRef<HTMLDivElement, ScheduleListContentProps>((props, ref) => {
  const {
    children,
    eventHandle,
    shouldDisplayUnlockButton,
    handleUnlockDialogClick,
    unlockButtonText,
    formattedSchedule,
    tbaSchedule,
    layout,
    variant = 'inline',
    eventId,
    ...restProps
  } = props;
  const isMdOrSmScreen = useMediaQuery(theme => theme.mediaQueries.down({ breakpointAlias: 'sm3' }));
  const isStackVariant = variant === 'stack';
  const isHeaderInSecondaryBlock = isMdOrSmScreen || isStackVariant;
  const { textColor } = useColorPaletteProvider();
  const borderBottomOverride = textColor ? { borderBottomColor: textColor } : {};

  const styles = variantStyles[variant];
  const translations = useTranslations();
  const telemetry = useScheduleListTelemetry();

  const headerOverride: OverrideTypography = useMemo(() => [{ variant: variant === 'stack' ? 'hed3' : 'hed4', category: Category.SUB_HEADING }], [variant]);

  const handleAddressClicked = useCallback(
    scheduleTitle => {
      telemetry.scheduleAddressClicked(scheduleTitle);
    },
    [telemetry]
  );

  const handleAddToCalendar = useCallback(
    (scheduleTitle: string, provider: string) => {
      telemetry.scheduleAddToCalendarClicked(scheduleTitle, provider);
    },
    [telemetry]
  );

  const handleVirtualEventClicked = useCallback(
    (scheduleTitle: string) => {
      telemetry.scheduleVirtualEventClicked(scheduleTitle);
    },
    [telemetry]
  );

  const _renderScheduleGroup = (key: string | number, header: string, items: ReadonlyArray<EventScheduleFragment>, index: number) => {
    if (!items.length) {
      return null;
    }
    const isToBeAnnounced = key === 'tba';
    const showTimeZone = items.reduce((resultSet, item) => resultSet.add(item.timezone), new Set()).size > 1;
    const headerMarginTop = shouldDisplayUnlockButton ? (index === 0 ? 9 : 7) : 0;

    return (
      <div key={key} data-testid={`schedule-group-${key}`}>
        <Box as="section" {...styles.container}>
          <Box as="header" {...styles.header} marginTop={headerMarginTop} {...borderBottomOverride}>
            <GuestSiteTypographyOverride override={headerOverride}>
              <TextV2 data-testid={`groupheader-${key}`} {...styles.headerText}>
                {header}
              </TextV2>
            </GuestSiteTypographyOverride>
          </Box>
          <Flex flexDirection="column" role="list">
            {items.map(item => {
              return (
                <ScheduleListItem
                  key={item.id}
                  eventHandle={eventHandle}
                  item={item}
                  isHeaderInSecondaryBlock={isHeaderInSecondaryBlock}
                  isToBeAnnounced={isToBeAnnounced}
                  styleProps={styles}
                  translations={translations}
                  showTimeZone={showTimeZone}
                  onAddressClicked={handleAddressClicked}
                  onCalendarClicked={handleAddToCalendar}
                  onVirtualEventBlockClicked={() => handleVirtualEventClicked(item.name)}
                />
              );
            })}
          </Flex>
        </Box>
      </div>
    );
  };

  return (
    <Box ref={ref} {...restProps} {...styles.events}>
      <>
        <Flex justifyContent={'center'}>
          {shouldDisplayUnlockButton && (
            <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
              <ButtonV2 onClick={handleUnlockDialogClick} variant="outline" shape="rounded">
                {unlockButtonText}
              </ButtonV2>
            </GuestSiteTypographyOverride>
          )}
        </Flex>
        <SpacingStack {...styles.eventsStack}>
          {formattedSchedule.map(({ date: groupDate, items }, i) => {
            return _renderScheduleGroup(`${groupDate.milliseconds}-${i}`, getScheduleGroupHeader(groupDate.hostDateString, groupDate.hostTimeString), items, i);
          })}
          {_renderScheduleGroup('tba', translations.toBeAnnounced, tbaSchedule, formattedSchedule.length)}
        </SpacingStack>
      </>
    </Box>
  );
});

const ScheduleList = React.forwardRef<HTMLDivElement, ScheduleListProps>((props, ref) => {
  const { eventId, eventHandle } = props;
  const { layout } = useLayout();
  const { formattedSchedule, shouldDisplayUnlockButton, loading, tbaSchedule, handleUnlockDialogClick, unlockButtonText } = useScheduleListController(eventHandle);

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  return (
    <TelemetryProvider context={{ layout, eventId }}>
      <ScheduleListContent
        layout={layout}
        {...props}
        ref={ref}
        tbaSchedule={tbaSchedule}
        formattedSchedule={formattedSchedule}
        handleUnlockDialogClick={handleUnlockDialogClick}
        shouldDisplayUnlockButton={shouldDisplayUnlockButton}
        unlockButtonText={unlockButtonText}
      />
    </TelemetryProvider>
  );
});
ScheduleList.displayName = 'ScheduleList';

export { ScheduleList };
