import { createTelemetry, createTelemetryObject } from '@shared/core';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { getTimezone } from '@apps/createWedding/utils/getTimezone';

type PagesForTelemetry =
  | 'createAccount'
  | 'signIn'
  | 'forgotPassword'
  | 'questionnaire'
  | 'tyingTheKnot'
  | 'createWeddingForm'
  | 'weddingAlreadyExists'
  | 'socialAccount'
  | 'survey';

const pageTracking: Record<PagesForTelemetry, PageFunctionType> = {
  createAccount: () => ({ category: 'CreateWeddingV2', page: 'signup', pagePrefix: 'marketing' }),
  signIn: () => ({ category: 'CreateWeddingV2', page: 'login', pagePrefix: 'marketing' }),
  forgotPassword: () => ({ category: 'CreateWeddingV2', page: 'forgotPassword', pagePrefix: 'marketing' }),
  questionnaire: () => ({ category: 'CreateWeddingV2', page: 'questionnaire', pagePrefix: 'marketing' }),
  tyingTheKnot: () => ({ category: 'CreateWeddingV2', page: 'tyingTheKnot', pagePrefix: 'marketing' }),
  createWeddingForm: () => ({ category: 'CreateWeddingV2', page: 'weddingDetailsForm', pagePrefix: 'marketing' }),
  weddingAlreadyExists: () => ({ category: 'CreateWeddingV2', page: 'existingEvent', pagePrefix: 'marketing' }),
  socialAccount: () => ({ category: 'CreateWeddingV2', page: 'social_intercept', pagePrefix: 'marketing' }),
  survey: () => ({ category: 'CreateWeddingV2', page: 'survey', pagePrefix: 'marketing' })
};

type AuthMode = 'createaccount' | 'login' | 'forgotpassword';
const CREATE_WEDDING_CTA = 'CreateWeddingCTA';

export const createWeddingTelemetry = createTelemetryObject({
  actions: {
    inputHandler: (fieldIdentifier: string, inputAction: 'onFocus' | 'onChange') => ({
      action: 'FormFieldInteracted',
      category: 'CreateWeddingV2',
      label: fieldIdentifier,
      extraInfo: {
        inputAction
      }
    }),
    passwordClicked: (authMode: AuthMode) => ({ action: 'Password', actionType: 'click', category: 'CreateWeddingV2', label: authMode }),
    emailClicked: (authMode: AuthMode) => ({ action: 'Email', actionType: 'click', category: 'CreateWeddingV2', label: authMode }),
    createAccountClicked: () => ({
      action: CREATE_WEDDING_CTA,
      actionType: 'click',
      category: 'CreateWeddingV2',
      extraInfo: {
        CtaName: 'EmailPassword'
      }
    }),
    logoutClicked: () => ({ action: 'Logout', actionType: 'click', category: 'CreateWeddingV2' }),
    trackError: (action: string, error: unknown) => ({ action, actionType: 'error', category: 'CreateWeddingV2', extraInfo: { error } }),
    weddingCreated: (website: string, eventId: string, email?: string, adminOrOwnerEventCount?: number) => ({
      action: 'Publish',
      label: website,
      category: 'CreateWeddingV2',
      extraInfo: { eventId, email, orderId: Date.now(), adminOrOwnerEventCount }
    }),
    questionnaireCompleted: (questionResponses: string[]) => ({
      action: 'HeardAboutJoySubmit',
      category: 'CreateWeddingV2',
      actionType: 'click',
      extraInfo: { questionResponses }
    }),
    dateUndecidedClicked: () => ({ action: 'DateUndecided', actionType: 'click', category: 'CreateWeddingV2' }),
    partnerFirstNameClicked: () => ({ action: 'PartnerFirstName', actionType: 'click', category: 'CreateWeddingV2' }),
    ownerFirstNameClicked: () => ({ action: 'OwnerFirstName', actionType: 'click', category: 'CreateWeddingV2' }),
    dateSelected: () => ({ action: 'Date', actionType: 'select', category: 'CreateWeddingV2' }),
    locationSelected: () => ({ action: 'Location', actionType: 'select', category: 'CreateWeddingV2' }),
    questionnaireSkipped: () => ({ action: 'HeardAboutJoySkip', actionType: 'click', category: 'CreateWeddingV2' }),
    questionClicked: () => ({ action: 'HeardAboutJoyOption', actionType: 'click', category: 'CreateWeddingV2' }),
    timezoneRegistered: (info: ReturnType<typeof getTimezone>) => ({ action: 'TimezoneRegistered', category: 'CreateWeddingV2', extraInfo: info }),
    dateSet: (eventDate: string, dateLabel: string) => ({ action: 'DateSet', category: 'CreateWeddingV2', label: dateLabel, extraInfo: { eventDate } }),
    submitDetailsClicked: (userEmail: string) => ({
      action: CREATE_WEDDING_CTA,
      actionType: 'click',
      label: 'continue',
      category: 'CreateWeddingV2',
      extraInfo: { email: userEmail, CtaName: 'SubmitDetails' }
    }),
    firstNamesSubmitted: (userEmail: string) => ({
      action: CREATE_WEDDING_CTA,
      actionType: 'click',
      label: 'continue',
      category: 'CreateWeddingV2',
      extraInfo: { email: userEmail, CtaName: 'FirstNames' }
    }),
    weddingDetailsSubmitted: (userEmail: string) => ({
      action: CREATE_WEDDING_CTA,
      actionType: 'click',
      label: 'continue',
      category: 'CreateWeddingV2',
      extraInfo: { email: userEmail, CtaName: 'WeddingDetails' }
    }),
    maliciousRedirect: maliciousHref => ({
      action: 'maliciousRedirect',
      category: 'CreateWeddingV2',
      extraInfo: {
        maliciousHref
      }
    }),
    findACoupleClicked: () => ({
      action: 'FindACouple',
      category: 'CreateWeddingV2',
      actionType: 'click',
      label: 'find a couple',
      page: 'CreateWeddingV2',
      pagePrefix: 'marketing'
    }),
    socialIntercept: socialProvider => ({
      action: 'CreateEventWithEmailPassword',
      actionType: 'click',
      label: 'Create event with email and password',
      category: 'CreateWeddingV2',
      pagePrefix: 'marketing',
      page: 'social_intercept',
      extraInfo: { accountType: socialProvider }
    }),
    surveySubmitClicked: ({
      action,
      label,
      selectedOptions,
      eventId,
      email
    }: {
      action: 'SurveyProceed' | 'SurveySkip';
      label: string;
      selectedOptions: string[];
      eventId?: string;
      email?: Nullable<string>;
    }) => ({
      action: CREATE_WEDDING_CTA,
      actionType: 'click',
      label,
      category: 'CreateWeddingV2',
      pagePrefix: 'marketing',
      page: 'survey',
      extraInfo: { selectedOptions, selectedOptionsLength: selectedOptions.length, CtaName: action, email, eventId }
    }),
    honorSurveyResults: (selectedOptions: string[]) => ({
      action: 'HonorSurveyResults',
      category: 'CreateWeddingV2',
      pagePrefix: 'marketing',
      page: 'survey',
      extraInfo: { selectedOptions, selectedOptionsLength: selectedOptions.length }
    })
  },
  pages: pageTracking
});

const { TelemetryProvider, useTelemetry: useCreateWeddingTelemetry } = createTelemetry(createWeddingTelemetry);

export { TelemetryProvider, useCreateWeddingTelemetry };
