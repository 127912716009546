import { ApolloCache, useApolloClient } from '@apollo/client';
import { EventPlacesDocument, EventPlacesQuery } from '@graphql/generated';
import { EventPlace } from './EventPlace.types';

/**
 * Interface for the update cache parameters used in adding, updating, or removing event places.
 */
export interface UpdateEventPlaceCacheArgs {
  cache?: ApolloCache<{}>;
  eventPlace?: EventPlace;
  pointOnMapId?: string | null;
  eventHandle?: string;
  action: 'add' | 'update' | 'remove';
}

/**
 * Function that updates the Apollo cache after adding, updating, or removing event places or points on the map.
 * @param {UpdateEventPlaceCacheArgs} props - The parameters used to update the cache.
 */
export const useEventPlaceCacheUpdate = () => {
  const apolloClient = useApolloClient();
  const updateEventPlaceCache = ({ cache, eventPlace, pointOnMapId, eventHandle, action }: UpdateEventPlaceCacheArgs) => {
    const cacheClient = cache ?? apolloClient;
    const queryParam = {
      query: EventPlacesDocument,
      variables: {
        name: eventHandle!,
        payload: { eventName: eventHandle!, pageSlug: 'accommodations' },
        isAdminDashboard: true
      },
      batchMode: 'fast'
    };

    const cachedData = cacheClient.readQuery<EventPlacesQuery>(queryParam);
    const cachedPlaces = cachedData?.eventByName?.places ?? [];
    const cachedComponents = cachedData?.pageContainerBySlug?.components;

    // Update places based on the action
    let places;
    if (eventPlace) {
      if (action === 'add') {
        places = pointOnMapId ? [eventPlace] : [...cachedPlaces, eventPlace];
      } else if (action === 'update') {
        places = [...cachedPlaces.filter(place => place.id !== eventPlace?.id), eventPlace];
      } else {
        places = cachedPlaces.filter(place => place.id !== eventPlace?.id);
      }
    } else {
      places = cachedPlaces;
    }

    let components = cachedComponents;

    // Update travel map components if removing a point on the map
    if (pointOnMapId) {
      const findTravelMapComponent = cachedComponents?.find(component => component.__typename === 'TravelMapComponent');
      const travelMapComponent = findTravelMapComponent?.__typename === 'TravelMapComponent' ? findTravelMapComponent : undefined;
      if (travelMapComponent) {
        const updatedPointsOnMap = travelMapComponent.pointsOnMap?.filter(point => point?.id !== pointOnMapId);
        const updatedTravelMapComponent = { ...travelMapComponent, pointsOnMap: updatedPointsOnMap };
        components = cachedComponents ? [...(cachedComponents.filter(component => component.__typename !== 'TravelMapComponent') ?? []), updatedTravelMapComponent] : [];
      }
    }

    // Write the updated data back to the Apollo cache
    if (cachedData?.eventByName) {
      cacheClient.writeQuery<EventPlacesQuery>({
        ...queryParam,
        data: {
          ...cachedData,
          eventByName: { ...cachedData.eventByName, places },
          pageContainerBySlug: { ...cachedData.pageContainerBySlug, id: cachedData.pageContainerBySlug?.id ?? '', components }
        }
      });
    }
  };
  return { updateEventPlaceCache };
};
