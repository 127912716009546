import { useCallback, useEffect, useRef } from 'react';
import { StationeryTemplate } from './DesignsGallery.types';
import { useSearchTermToTemplatesLazyQuery } from '@graphql/generated';
import { makeVar, useReactiveVar } from '@apollo/client';
import { useSearchTelemetry } from './components/Search/Search.telemetry';
import { useSearchToQueryParam } from './utils/useSearchToQueryParam';
import { useHistory } from '@react-router';

export enum SearchRequestState {
  INIT,
  LOADING,
  DONE,
  ERROR
}

type PrintGallerySearchQuery = {
  enabled: boolean;
  query?: string;
};

type PrintGallerySearchResults = {
  searchCompleted: boolean;
  searchError: boolean;
  resultTemplates?: {
    templateID: string;
    matchingTags: number;
    allTags: string[];
    matchingDescriptionWords: number;
  }[];
  resultTags?: string[] | null;
};

export const printGallerySearchTermVar = makeVar<PrintGallerySearchQuery>({ enabled: false });
export const printGallerySearchResultsVar = makeVar<PrintGallerySearchResults>({ searchCompleted: false, searchError: false });

export const usePrintGallerySearchTermVar = () => {
  return useReactiveVar(printGallerySearchTermVar);
};

const usePrintGallerySearchResultsVar = () => {
  return useReactiveVar(printGallerySearchResultsVar);
};

export const useApplySearchFiltersToTemplates = () => {
  const history = useHistory();
  const requestState = useRef<SearchRequestState>(SearchRequestState.INIT);
  const [getSearchResults, { called, error, loading, data }] = useSearchTermToTemplatesLazyQuery({
    fetchPolicy: 'no-cache',
    batchMode: 'fast'
  });
  const { searchBarInteracted, searchResultsReturned, searchResultsError } = useSearchTelemetry();
  const { getSearchURL } = useSearchToQueryParam();
  const { query } = printGallerySearchTermVar();

  // Tracks lazyQuery progress
  useEffect(() => {
    if (requestState.current === SearchRequestState.LOADING) {
      if (!loading && called) {
        if (!!data && !error) {
          requestState.current = SearchRequestState.DONE;
        } else {
          requestState.current = SearchRequestState.ERROR;
        }
      }
    }

    if (requestState.current === SearchRequestState.ERROR) {
      printGallerySearchResultsVar({
        searchCompleted: false,
        searchError: true
      });

      printGallerySearchTermVar({
        enabled: false
      });

      searchResultsError({
        query: query ?? ''
      });
    }

    if (requestState.current === SearchRequestState.DONE) {
      const results = data?.searchTermToTemplates;
      if ((results?.templates.length ?? 0) > 0) {
        printGallerySearchResultsVar({
          searchCompleted: true,
          resultTemplates: results?.templates,
          resultTags: results?.tags,
          searchError: false
        });

        searchResultsReturned({
          query: results!.query,
          resultsCount: results!.templates.length,
          tags: results!.tags ?? []
        });

        requestState.current = SearchRequestState.INIT;
      } else {
        printGallerySearchResultsVar({
          searchCompleted: true,
          searchError: true
        });
        requestState.current = SearchRequestState.ERROR;
      }

      printGallerySearchTermVar({
        enabled: true,
        query: results?.query
      });
    }
  }, [called, data, error, loading, query, searchResultsError, searchResultsReturned]);

  // Sends search query request
  const makeSearchRequest = useCallback(
    (searchQuery: string) => {
      const canMakeRequest = requestState.current !== SearchRequestState.LOADING;
      if (canMakeRequest) {
        getSearchResults({
          variables: {
            searchTerm: searchQuery
          }
        });

        searchBarInteracted({
          query: searchQuery
        });

        history.push(getSearchURL(searchQuery));

        requestState.current = SearchRequestState.LOADING;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSearchResults, searchBarInteracted]
  );

  const clearSearch = () => {
    printGallerySearchResultsVar({
      searchCompleted: false,
      searchError: false
    });
    history.push(getSearchURL());

    printGallerySearchTermVar({
      enabled: false
    });
    requestState.current = SearchRequestState.INIT;
  };

  const searchError = () => {
    printGallerySearchResultsVar({
      searchCompleted: false,
      searchError: true
    });

    printGallerySearchTermVar({
      enabled: false
    });
    requestState.current = SearchRequestState.ERROR;
  };

  const searchResults = printGallerySearchResultsVar();
  // Takes list of templates and filters to search results if they exist.
  const applySearchFiltersToTemplates = useCallback(
    (templates: StationeryTemplate[]) => {
      if (searchResults.searchCompleted && searchResults.resultTemplates) {
        const { resultTemplates } = searchResults;
        const t = templates
          .filter(template => {
            return resultTemplates.some(t => t.templateID === template.id);
          })
          .map(template => {
            const searchObject = resultTemplates.find(t => t.templateID === template.id);
            return {
              ...template,
              matchingTagCount: (searchObject?.matchingTags ?? 0) + (searchObject?.matchingDescriptionWords ?? 0),
              searchTags: searchObject?.allTags
            };
          })
          .sort((a, b) => {
            return a.matchingTagCount - b.matchingTagCount;
          })
          .reverse();
        return t;
      } else {
        return templates;
      }
    },
    [searchResults]
  );

  return { applySearchFiltersToTemplates, searchError, makeSearchRequest, loadingState: requestState, clearSearch, useSearchResults: usePrintGallerySearchResultsVar };
};
