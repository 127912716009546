import React from 'react';
import { lazy } from '@loadable/component';
import { useFeatureValue } from '@shared/core/featureFlags';
import { Redirect, Route, Switch } from '@react-router';
import { CardsRoutesProvider, useCardsRouterContext } from './Card.routes';
import { Helmet } from 'react-helmet-async';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { ImagineYourCard } from './routes/Dashboard/components/Imagine/ImagineYourCard';

const Concierge = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/concierge" */
      './routes/Concierge'
    )
);

const PaperCardCustomizer = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/cardcustomizer" */
      './routes/PaperCardCustomizer'
    )
);

const DigitalCardCustomizer = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/digitalcardcustomizer" */
      './routes/DigitalCardCustomizer'
    )
);

const OrderDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/orderdetails" */
      './routes/OrderDetails'
    )
);
const OrderTracking = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/ordertracking" */
      './routes/OrderTracking'
    )
);

const Dashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "content/card/dashboard" */
      './routes/Dashboard'
    )
);

interface CardProps {
  eventId: string;
  eventHandle: string;
}

const Router = (props: CardProps) => {
  const { routes } = useCardsRouterContext();

  const { value: printEnableDigitalEditor } = useFeatureValue('printEnableDigitalEditor');
  const isOrderTrackingEnabled = useFeatureValue('printPremiumOrderTrackingEnabled').value === 'on';

  return (
    <Switch>
      <Route path={routes.designConcierge.paths} render={() => <Concierge />} />
      <Route path={routes.imagine.paths} render={() => <ImagineYourCard />} />
      <Route path={routes.paperCardCustomizer.paths} render={({ match }) => <PaperCardCustomizer cardDraftId={match.params.cardDraftId} />} />
      {printEnableDigitalEditor === 'on' && (
        <Route
          path={routes.digitalCardCustomizer.paths}
          render={({ match }) => <DigitalCardCustomizer eventHandle={match.params.eventHandle} cardDraftId={match.params.cardDraftId} />}
        />
      )}
      <Route path={routes.editOrder.paths} render={({ match }) => <PaperCardCustomizer printOrderId={match.params.printOrderNumber} />} />
      <Route
        path={routes.editDigitalOrder.paths}
        render={({ match }) => <DigitalCardCustomizer eventHandle={match.params.eventHandle} digitalOrderId={match.params.printOrderNumber} />}
      />
      {isOrderTrackingEnabled && (
        <Route
          path={routes.orderTracking.paths}
          render={({ match }) => {
            return <OrderTracking eventId={props.eventId} orderNumber={match.params.printOrderNumber} />;
          }}
        />
      )}
      <Route
        path={routes.orderDetails.paths}
        render={({ match }) => {
          return <OrderDetails printOrderNumber={match.params.printOrderNumber} />;
        }}
      />
      <Route
        path={routes.dashboard.paths}
        render={() => {
          return <Dashboard eventId={props.eventId} />;
        }}
      />
    </Switch>
  );
};

const CLARITY_SCRIPT = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "lqepsakddd");`;

export const Card = (props: CardProps) => {
  const { loading, value } = useFeatureValue('enableNewPrintExperience');

  if (loading) {
    return <JoyLogoLoader loaderKey="admin-customize-card" />;
  }

  if (value !== 'on') {
    return <Redirect to={`/${props.eventHandle}/edit`} />;
  }

  return (
    <CardsRoutesProvider eventHandle={props.eventHandle}>
      <Helmet>
        <script type="text/javascript">{CLARITY_SCRIPT}</script>
      </Helmet>
      <Router {...props} />
    </CardsRoutesProvider>
  );
};
