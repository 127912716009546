/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import createSvgIcon from '@shared/joykit/packages/core/icons/createSvgIcon';
import { SvgIconConfig } from '@shared/joykit/packages/core/icons/types';
import * as React from 'react';

const config: SvgIconConfig = {
  title: 'Search',
  definitions: {
    '16': {
      viewBox: '0 0 15 14',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2607 10.5426C9.22948 11.3981 7.90503 11.9124 6.46046 11.9124C3.17108 11.9124 0.504517 9.24579 0.504517 5.95642C0.504517 2.66704 3.17108 0.000473022 6.46046 0.000473022C9.74984 0.000473022 12.4164 2.66704 12.4164 5.95642C12.4164 7.40514 11.8992 8.73305 11.0393 9.76555L14.3343 13.0606C14.5491 13.2754 14.5491 13.6236 14.3343 13.8384C14.1196 14.0532 13.7713 14.0532 13.5565 13.8384L10.2607 10.5426ZM1.60452 5.95642C1.60452 3.27455 3.7786 1.10047 6.46046 1.10047C9.14232 1.10047 11.3164 3.27455 11.3164 5.95642C11.3164 7.2719 10.7933 8.46521 9.9438 9.33969C9.92366 9.35491 9.90432 9.3717 9.88597 9.39006C9.86789 9.40814 9.85133 9.42716 9.8363 9.44697C8.96249 10.2922 7.77222 10.8124 6.46046 10.8124C3.7786 10.8124 1.60452 8.63828 1.60452 5.95642Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10.702C4 7.008 7.008 4 10.702 4s6.703 3.008 6.703 6.702a6.676 6.676 0 0 1-1.534 4.257l3.939 3.932a.65.65 0 0 1-.919.919l-3.932-3.939a6.676 6.676 0 0 1-4.257 1.534C7.008 17.405 4 14.396 4 10.702zm12.107 0a5.395 5.395 0 0 0-5.405-5.405 5.395 5.395 0 0 0-5.405 5.405 5.395 5.395 0 0 0 5.405 5.406 5.395 5.395 0 0 0 5.405-5.406z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.4375 20.6289C36.4375 29.3274 29.386 36.3789 20.6875 36.3789C11.989 36.3789 4.9375 29.3274 4.9375 20.6289C4.9375 11.9304 11.989 4.87891 20.6875 4.87891C29.386 4.87891 36.4375 11.9304 36.4375 20.6289ZM32.198 33.1394C29.17 35.9267 25.1275 37.6289 20.6875 37.6289C11.2987 37.6289 3.6875 30.0177 3.6875 20.6289C3.6875 11.2401 11.2987 3.62891 20.6875 3.62891C30.0763 3.62891 37.6875 11.2401 37.6875 20.6289C37.6875 25.128 35.9397 29.219 33.0861 32.2597L44.1294 43.3031C44.3735 43.5472 44.3735 43.9429 44.1294 44.187C43.8854 44.431 43.4896 44.431 43.2456 44.187L32.198 33.1394Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
