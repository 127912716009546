import React, { useMemo } from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { SkeletonText } from '@shared/components/Skeleton';
import { CardTileDiscountedPriceLabel } from '../../DesignsGallery/components/CardTileDiscountedPriceLabel';
import { pxToRem } from '@withjoy/joykit/theme';
import { FoilBadge } from '../../FoilBadge';
import { useFoilConfig } from '@apps/card/hooks/useFoilConfig';
import { useDisplayPrice } from '../useDisplayPrice';
import { CardFormat } from '../constants';

interface TitleBlockProps {
  title: string;
  subtitle?: string;
  isFoil?: boolean;
  format?: CardFormat;
}

const Root: React.FC = ({ children }) => {
  return (
    <Flex flexDirection="column" rowGap={3} marginBottom={{ _: 5, sm2: 8 }}>
      {children}
    </Flex>
  );
};
export const TitleBlock = (props: TitleBlockProps) => {
  const { title, subtitle, format, isFoil = false } = props;

  const { pricePerCardInMinorUnits: foilPrice } = useFoilConfig();
  const displayPrice = useDisplayPrice({ taylor: isFoil ? foilPrice : 0 });

  const price = useMemo(() => {
    if (format === CardFormat.premiumDigital) {
      return displayPrice.premiumDigital;
    }

    if (format === CardFormat.paper) {
      return displayPrice.paper;
    }

    return displayPrice.digital;
  }, [format, displayPrice]);

  return (
    <Root>
      {isFoil && <FoilBadge />}
      <TextV2 fontWeight={{ sm2: 600 }} typographyVariant={{ _: 'hed4', sm2: 'display1' }}>
        {title}
      </TextV2>
      {price.isEnabled ? (
        <CardTileDiscountedPriceLabel preDiscountPrice={price.originalPrice} sale={price.currentSale!} discountedPrice={price.discountedPrice} alignment="flex-start" />
      ) : (
        // Height to match the height of CardTileDiscountedPriceLabel and avoid layout shift
        <Flex height={pxToRem(24)} alignItems="center">
          <TextV2 typographyVariant="label2">{format === CardFormat.digital ? price.originalPrice : `From ${price.originalPrice}`}</TextV2>
        </Flex>
      )}
      {subtitle && (
        <TextV2 color="mono12" fontWeight={{ _: 500, sm2: 500 }} typographyVariant={{ _: 'hed1', sm2: 'hed4' }}>
          {subtitle}
        </TextV2>
      )}
    </Root>
  );
};

TitleBlock.Skeleton = () => {
  return (
    <Root>
      <SkeletonText rows={1} width={{ _: 160, sm2: 200 }} skeletonHeight={{ _: 24, sm2: 30 }} />
      <SkeletonText rows={1} width={80} skeletonHeight={{ _: 20, sm2: 24 }} />
    </Root>
  );
};
