import React, { useEffect } from 'react';
import { Envelope } from './Envelope';
import { Box, BoxProps } from '@withjoy/joykit';
import { useWindowSize } from './hooks';
import { PremiumEnvelope, PremiumEnvelopeRef } from './PremiumEnvelope';

export enum IRevealType {
  // Presents the card in an animated envelope opening
  Envelope = 'envelope',
  // Presents the card in an animated envelope opening
  PremiumEnvelope = 'premium-envelope',
  // Presents the card with no reveal animation
  Default = 'default'
}

export interface RevealRendererProps {
  // The color of the reveal base theme color in hex ( '#3366ff' ) or rgb ( 'rgb(123,45,67)' ) format
  themeColor: string;
  reopenOnThemeColorChange?: boolean;
  animation?: string;
  loading?: boolean;
  width?: number;
  verticalOffset?: number;
  autoPlayDelay?: number;
  backgroundColor?: string;
  onThemeTransitionComplete?: () => void;
  blockInteraction?: boolean;
  preventToggleOnContentClick?: boolean;
  bottomOffset?: BoxProps['paddingBottom'];
  envelopeSiblingElement?: () => React.ReactNode;
  onRevealed?: (revealed: boolean) => void;
}

interface RevealProps extends RevealRendererProps {
  // The reveal animation type
  type: IRevealType;
  children: React.ReactNode;
}

const DefaultReveal: React.FC<RevealRendererProps> = ({ children, onThemeTransitionComplete, themeColor, backgroundColor }) => {
  const [curThemeColor, setCurThemeColor] = React.useState(themeColor);
  useEffect(() => {
    if (curThemeColor !== themeColor) {
      setCurThemeColor(themeColor);
      onThemeTransitionComplete?.();
    }
  }, [themeColor, onThemeTransitionComplete, curThemeColor]);
  return (
    <Box padding={6} backgroundColor={backgroundColor}>
      {children}
    </Box>
  );
};

const typeComponentMap = {
  [IRevealType.Envelope]: Envelope,
  [IRevealType.PremiumEnvelope]: PremiumEnvelope,
  [IRevealType.Default]: DefaultReveal
};

const DEFAULT_CONTENT_WIDTH = 460;
const DEFAULT_PADDING = 40;

export const Reveal = React.forwardRef<PremiumEnvelopeRef, RevealProps>(
  (
    {
      preventToggleOnContentClick,
      themeColor,
      reopenOnThemeColorChange,
      animation,
      type,
      width,
      verticalOffset,
      autoPlayDelay,
      backgroundColor,
      onThemeTransitionComplete,
      loading,
      blockInteraction,
      bottomOffset,
      children,
      envelopeSiblingElement,
      onRevealed
    },
    ref
  ) => {
    const RevealRenderer = typeComponentMap[type] || typeComponentMap[IRevealType.Default];
    const { width: winWidth, height: winHeight } = useWindowSize();
    let renderWidth = width;
    if (!width) {
      renderWidth = winWidth > DEFAULT_CONTENT_WIDTH + DEFAULT_PADDING ? DEFAULT_CONTENT_WIDTH : winWidth - DEFAULT_PADDING;
    }
    const renderHeight = typeof verticalOffset === 'number' ? verticalOffset : winHeight;

    return (
      <RevealRenderer
        ref={ref}
        themeColor={themeColor}
        reopenOnThemeColorChange={reopenOnThemeColorChange}
        animation={animation}
        backgroundColor={backgroundColor}
        width={renderWidth}
        verticalOffset={renderHeight}
        autoPlayDelay={autoPlayDelay}
        onThemeTransitionComplete={onThemeTransitionComplete}
        loading={loading}
        blockInteraction={blockInteraction}
        preventToggleOnContentClick={preventToggleOnContentClick}
        bottomOffset={bottomOffset}
        envelopeSiblingElement={envelopeSiblingElement}
        onRevealed={onRevealed}
      >
        {children}
      </RevealRenderer>
    );
  }
);
