import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import {
  EnvelopeContainer,
  CardFlap,
  EnvelopeBack,
  CardBottom,
  CardLeft,
  CardWrapper,
  WrapperContainer,
  EnvelopePositioner,
  CardRight,
  CardBottomTexture,
  CardFlapTexture
} from './premiumEnvelope.styles';
import { Player } from '@lottiefiles/react-lottie-player';
import { shadeColor } from './utils';
import { RevealRendererProps } from './Reveal';
import { Box } from '@withjoy/joykit';

export enum EnvelopeAnimation {
  wiggle = 'wiggle',
  tremble = 'tremble'
}
const ASPECT_RATIO = 0.714;

export interface PremiumEnvelopeRef {
  openEnvelope: () => void;
  closeEnvelope: () => void;
  isOpen: boolean;
}

export const PremiumEnvelope = React.forwardRef<PremiumEnvelopeRef, RevealRendererProps>(
  (
    {
      animation = 'confetti',
      themeColor,
      reopenOnThemeColorChange = true,
      width = 320,
      children,
      autoPlayDelay,
      onThemeTransitionComplete,
      backgroundColor,
      verticalOffset,
      loading = false,
      blockInteraction,
      bottomOffset = 10,
      preventToggleOnContentClick,
      envelopeSiblingElement,
      onRevealed
    },
    ref
  ) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const lottieRef = useRef(null);
    const step1Timeout = useRef<number | null>(null);
    const step2Timeout = useRef<number | null>(null);
    const step3Timeout = useRef<number | null>(null);
    const step4Timeout = useRef<number | null>(null);
    const autoOpenTimeout = useRef<number | null>(null);
    const autoOpenComplete = useRef(false);
    const isAnimating = useRef(false);

    const [height, setHeight] = useState(2000);
    const [flapOpen, setFlapOpen] = React.useState(false);
    const [cardOpen, setCardOpen] = React.useState(false);
    const [hasHover, setHasHover] = React.useState(false);

    const envelopeHeight = Math.round(width * ASPECT_RATIO);
    let topOffset = 0;
    if (verticalOffset) {
      topOffset = verticalOffset * 0.5 - envelopeHeight * 0.5;
    }
    const [curThemeColor, setCurThemeColor] = useState(themeColor);

    const handleOpen = useCallback(
      (open: boolean) => {
        if (!loading) {
          isAnimating.current = true;
          if (open) {
            autoOpenTimeout.current && window?.clearTimeout(autoOpenTimeout.current);
            autoOpenComplete.current = true;
            setFlapOpen(true);
            setTimeout(() => {
              setCardOpen(true);
              onRevealed?.(true);
              isAnimating.current = false;
            }, 1000);
          } else {
            wrapperRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              setCardOpen(false);
              onRevealed?.(false);
              setTimeout(() => {
                wrapperRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
                setTimeout(() => {
                  setHasHover(false);
                  setFlapOpen(false);
                  isAnimating.current = false;
                }, 100);
              }, 1000);
            }, 500);
          }
        }
      },
      [loading, onRevealed]
    );

    useImperativeHandle(ref, () => ({
      isOpen: cardOpen,
      openEnvelope: () => {
        handleOpen(true);
      },
      closeEnvelope: () => {
        handleOpen(false);
      }
    }));

    const clickOpen = useCallback(
      (open: boolean) => {
        if (!blockInteraction) {
          handleOpen(open);
        } else {
          setHeight(height - 1);
        }
      },
      [blockInteraction, handleOpen, height]
    );

    useLayoutEffect(() => {
      if (elementRef.current && height !== elementRef.current.offsetHeight && elementRef.current.offsetHeight > 0) {
        setHeight(elementRef.current.offsetHeight);
        if (!loading && autoPlayDelay && !autoOpenTimeout.current && autoOpenComplete.current === false) {
          autoOpenTimeout.current = window?.setTimeout(() => {
            handleOpen(true);
            autoOpenComplete.current = true;
          }, autoPlayDelay);
        }
      }
    }, [setHeight, height, elementRef?.current?.offsetHeight, autoPlayDelay, loading, handleOpen]);

    useEffect(() => {
      if (elementRef.current && height !== elementRef.current.offsetHeight && elementRef.current.offsetHeight > 0) {
        setHeight(elementRef.current.offsetHeight);
      }
    }, [blockInteraction, height, elementRef?.current?.offsetHeight]);

    useEffect(() => {
      if (loading === false && !autoOpenTimeout.current && autoOpenComplete.current === false && autoPlayDelay) {
        setTimeout(() => {
          handleOpen(true);
          autoOpenComplete.current = true;
        }, autoPlayDelay);
      }
    }, [loading, autoPlayDelay, handleOpen]);

    const clearTimeouts = () => {
      if (step1Timeout.current) {
        window?.clearTimeout(step1Timeout.current);
      }
      if (step2Timeout.current) {
        window?.clearTimeout(step2Timeout.current);
      }
      if (step3Timeout.current) {
        window?.clearTimeout(step3Timeout.current);
      }
      if (step4Timeout.current) {
        window?.clearTimeout(step4Timeout.current);
      }
    };
    useEffect(() => {
      // This triggers the animation to run in stages when the theme changes
      if (themeColor !== curThemeColor) {
        clearTimeouts();
        setCardOpen(false);
        setCurThemeColor(themeColor);
        step1Timeout.current = window?.setTimeout(() => {
          setHasHover(false);
          setFlapOpen(false);
          step2Timeout.current = window?.setTimeout(() => {
            onThemeTransitionComplete?.();
            if (reopenOnThemeColorChange) {
              step3Timeout.current = window?.setTimeout(() => {
                setHasHover(true);
                setFlapOpen(true);
                step4Timeout.current = window?.setTimeout(() => {
                  setCardOpen(true);
                }, 500);
              }, 750);
            }
          }, 500);
        }, 1100);
      }
    }, [themeColor, onThemeTransitionComplete, curThemeColor, reopenOnThemeColorChange]);

    const screenBackgroundColor = backgroundColor ? backgroundColor : 'none';
    const topFlapHoverColor = shadeColor(curThemeColor, 1.42, 1);
    const topFlapColor = shadeColor(curThemeColor, 1.02, 1.58);
    const leftFlapColor = shadeColor(curThemeColor, 1.18, 1.42);
    const rightFlapColor = shadeColor(curThemeColor, 1.18, 1.42);
    const bottomFlapColor = shadeColor(curThemeColor, 1.09, 1.48);
    const intColor = shadeColor(curThemeColor, 1.25, 1.15);
    const intFlapColor = shadeColor(curThemeColor, 1.3, 1.1);
    const innerEnvelopePadding = 4;
    const paddedWidth = width + innerEnvelopePadding * 2;

    return (
      <WrapperContainer ref={wrapperRef} backgroundColor={screenBackgroundColor}>
        <EnvelopePositioner backgroundColor={screenBackgroundColor} paddingBottom={bottomOffset}>
          <EnvelopeContainer
            isOpen={cardOpen}
            height={height}
            verticalOffset={topOffset}
            onMouseOver={() => {
              if (!isAnimating.current) {
                setHasHover(true);
              }
            }}
            onMouseLeave={() => {
              if (!isAnimating.current) {
                setHasHover(false);
              }
            }}
            hasHover={hasHover}
            flapOpen={flapOpen}
          >
            {flapOpen && animation === 'confetti' && (
              <Player
                onEvent={event => {
                  if (event === 'complete') {
                    const player = (lottieRef?.current as unknown) as Player;
                    player?.container?.remove();
                  }
                }}
                ref={lottieRef}
                autoplay
                keepLastFrame
                src="https://d2uft7zh7kxc3y.cloudfront.net/rsvp_confetti.json"
                style={{ position: 'absolute', zIndex: 500, width: '100%', maxWidth: '1000px', top: '-100px', transform: 'rotateX(180deg)' }}
              />
            )}
            <CardFlap
              openColor={intFlapColor}
              closedColor={topFlapColor}
              isOpen={flapOpen}
              hasHover={hasHover}
              hoverColor={topFlapHoverColor}
              onClick={() => clickOpen(!flapOpen)}
            />
            <CardFlapTexture isOpen={flapOpen} hasHover={hasHover} onClick={() => clickOpen(!flapOpen)} />
            <CardLeft sideColor={leftFlapColor} onClick={() => clickOpen(!flapOpen)} />
            <CardRight sideColor={rightFlapColor} onClick={() => clickOpen(!flapOpen)} />
            <CardBottom bottomColor={bottomFlapColor} onClick={() => clickOpen(!flapOpen)} />
            <CardBottomTexture onClick={() => clickOpen(!flapOpen)} />
            <EnvelopeBack width={paddedWidth} height={envelopeHeight} backgroundColor={intColor} hasHover={hasHover} isOpen={cardOpen} onClick={() => clickOpen(!flapOpen)} />
            <CardWrapper
              envelopeHeight={envelopeHeight}
              ref={elementRef}
              height={height}
              isOpen={cardOpen}
              hasHover={hasHover}
              onClick={!preventToggleOnContentClick ? () => clickOpen(!flapOpen) : undefined}
            >
              {children}
            </CardWrapper>
          </EnvelopeContainer>
          {envelopeSiblingElement ? (
            <Box position="absolute" width={paddedWidth} bottom={'12px'}>
              {envelopeSiblingElement()}
            </Box>
          ) : null}
        </EnvelopePositioner>
      </WrapperContainer>
    );
  }
);
