import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';

export type CardCarouselSlide =
  | ({
      type: 'image';
    } & CardImageSlide)
  | ({
      type: 'video';
    } & CardVideoSlide);

// Basic carousel slide properties
type CardCarouselSlideBase = {
  src: string;
  categoryList: string[];
};

// Image specific properties
type CardImageSlide = {} & CardCarouselSlideBase;

// Video specific properties
type CardVideoSlide = {} & CardCarouselSlideBase;

// Validates that a slide is a valid image slide
const isImageSlide = (value: unknown): value is CardImageSlide => {
  return true;
};

// Validates that a slide is a valid video slide
const isVideoSlide = (value: unknown): value is CardImageSlide => {
  return true;
};

// Validates that an object is a valid slide
const isCarouselSlide = (value: unknown): value is CardCarouselSlide => {
  if (value == undefined) {
    return false;
  }

  const asSlide = value as CardCarouselSlide;
  if (!Object.hasOwn(value, 'type') || typeof asSlide.type !== 'string') {
    return false;
  }

  if (!Object.hasOwn(value, 'src')) {
    return false;
  }

  const acceptableTypes = ['image', 'video'];
  if (!acceptableTypes.includes(asSlide.type)) {
    return false;
  }

  if (asSlide.type == 'image' && !isImageSlide(value)) {
    return false;
  }

  if (asSlide.type == 'image' && !isVideoSlide(value)) {
    return false;
  }

  return true;
};

// Validates that a payload is a valid array of slides
const isCarouselSlides = (value: unknown): value is CardCarouselSlide[] => {
  if (value == undefined) {
    return false;
  }

  if (!Array.isArray(value)) {
    return false;
  }

  if (
    value.some(element => {
      return !isCarouselSlide(element);
    })
  ) {
    return false;
  }

  return true;
};

// Compares a filterString to a given category and occasion
const validateCarouselSlideCategoryFilter = (filterList: string[], category: StationeryTemplateCategoryEnum, occasion = 'wedding'): boolean => {
  return filterList.some(filterString => {
    const filterStringComponents = filterString.split('/');
    const [filterOccasion, filterCategory] = filterStringComponents;

    // Not valid if occasions do not match.
    if (filterOccasion !== '*' && filterOccasion !== occasion) {
      return false;
    }

    // Not valid if categories do not match.
    if (filterCategory !== '*' && filterCategory !== category) {
      return false;
    }

    return true;
  });
};

// Accepts the current category and occasion, filters slides to active slides.
export const filterCardCarouselValues = (slides: CardCarouselSlide[], category: StationeryTemplateCategoryEnum, occasion = 'wedding'): CardCarouselSlide[] => {
  return slides.filter(slide => {
    return validateCarouselSlideCategoryFilter(slide.categoryList, category, occasion);
  });
};

export const useCardCarouselValues = (): CardCarouselSlide[] => {
  const { value, payload } = useFeatureValue('printGalleryCardTileCarouselContent');

  if (value !== 'on') {
    return [];
  }

  if (!isCarouselSlides(payload)) {
    return [];
  }

  return payload;
};
