import { useSalesConfig } from '@apps/card/routes/CardCustomizer/useSalesConfig';
import { useCardCategoryContext } from '../../CardCategoryProvider';

const isValidColor = (payload: unknown): payload is { colorCode: string } => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'string') {
    return false;
  }

  if (!(payload.includes('#') || payload.includes('rgb('))) {
    return false;
  }

  return true;
};

export const useSaleColor = () => {
  const { currentCategory: category } = useCardCategoryContext();
  const { isEnabled, currentSale } = useSalesConfig(category);

  if (isEnabled && currentSale && isValidColor(currentSale?.color)) {
    return currentSale.color;
  }

  // Default Joy Purple color
  return '#6A36FF';
};
