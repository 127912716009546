import React from 'react';
import { useCarouselContext } from '../../Carousel.controller';
import { StyledCarouselItem, styles } from './CarouselItem.styles';

export interface CarouselItemProps {
  children: React.ReactNode;
  columnSpan?: number;
}

const CarouselItem = ({ children, columnSpan }: CarouselItemProps) => {
  const { itemRef, elementsPerPage, itemSpacing, enablePeekSpacing } = useCarouselContext();
  return (
    <StyledCarouselItem
      ref={itemRef}
      __css={styles.itemContainer(itemSpacing)}
      $columnSpan={columnSpan ?? 1}
      $itemsPerPage={elementsPerPage}
      $itemSpacing={enablePeekSpacing ? itemSpacing : null}
    >
      {children}
    </StyledCarouselItem>
  );
};

export { CarouselItem };
