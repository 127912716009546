import { useCallback, useContext, useMemo } from 'react';
import { useRemoteFeatureFlag } from '@shared/core/featureFlags/remoteFeatureFlags';
import Fuse from 'fuse.js';
import { AnalyticsContext } from '@shared/core';

export interface PreDefinedGooglePlace {
  readonly placeId: string;
  readonly name: string;
  readonly address: string;
  readonly address2?: string;
  readonly formattedAddress?: string;
  readonly lat?: number;
  readonly lng?: number;
  readonly placeTypes?: string[];
  readonly city?: string;
  readonly stateRegionProvince?: string;
  readonly country?: string;
  readonly postalCode?: string;
  readonly phone?: string;
  readonly url?: string;
  readonly photoUrl?: string;
  readonly rating?: number;
  readonly shouldOverrideExisting?: boolean;
}

/**
 * We noticed issue where hotel is available in google map but its not showing up in google location autocomplete.
 * We are added places(with json PreDefinedGooglePlace) in amplitude which is not available in google autocomplete api.
 * If in case cx team notice similar issue in future, they could simply add place in amplitude and solve user issue quickly.
 *
 * Amplitude FF - https://app.amplitude.com/experiment/joylife/142658/config/364804/configure
 */
export const usePreDefinedGooglePlaces = () => {
  const analytics = useContext(AnalyticsContext);
  const { value: supportAutocompletePreDefinedPlacesValue, payload } = useRemoteFeatureFlag('supportAutocompletePreDefinedPlacesEnabled');
  const supportAutocompletePreDefinedPlacesEnabled = supportAutocompletePreDefinedPlacesValue === 'on';

  const supportAutocompletePreDefinedPlaces = useMemo(() => (Object(payload)?.places as ReadonlyArray<PreDefinedGooglePlace>) || [], [payload]);

  const getSearchPreDefinedPlaces = useCallback(
    (searchTerm: string, placePredictionTypes?: string[]) => {
      if (!supportAutocompletePreDefinedPlacesEnabled || !supportAutocompletePreDefinedPlaces) {
        return [];
      }

      const fuse = new Fuse(supportAutocompletePreDefinedPlaces, {
        keys: ['name'],
        threshold: 0.3
      });
      const results = fuse.search(searchTerm);

      if (!placePredictionTypes || placePredictionTypes?.length === 0) {
        analytics.track({
          category: 'admin',
          action: 'LocationAutocompletePreDefined',
          extraInfo: { input: searchTerm, placeResults: results?.map(place => place.name) || [] }
        });
        return results;
      }

      const filteredResults = results.filter(result => {
        return result.placeTypes?.some(type => placePredictionTypes.includes(type));
      });
      analytics.track({
        category: 'admin',
        action: 'LocationAutocompletePreDefined',
        extraInfo: { input: searchTerm, placeResults: filteredResults?.map(place => place.name) || [] }
      });
      return filteredResults;
    },
    [analytics, supportAutocompletePreDefinedPlacesEnabled, supportAutocompletePreDefinedPlaces]
  );

  const getPreDefinedPlaceById = useCallback(
    (placeId: string) => {
      if (!supportAutocompletePreDefinedPlacesEnabled || !supportAutocompletePreDefinedPlaces) {
        return null;
      }

      const placeDetails = supportAutocompletePreDefinedPlaces.find(hotel => hotel.placeId === placeId);
      analytics.track({
        category: 'admin',
        action: 'LocationAutocompletePreDefinedById',
        extraInfo: { placeId, name: placeDetails?.name }
      });
      if (!placeDetails) {
        return placeDetails;
      }
      const cleanedPlace = {
        // Mandatory fields
        placeId: placeDetails.placeId,
        name: placeDetails.name,
        address: placeDetails.address,
        shouldOverrideExisting: placeDetails.shouldOverrideExisting,
        // Optional fields
        address2: placeDetails.address2 ?? '',
        formattedAddress: placeDetails.formattedAddress ?? '',
        placeTypes: placeDetails.placeTypes ?? [],
        city: placeDetails.city ?? '',
        stateRegionProvince: placeDetails.stateRegionProvince ?? '',
        country: placeDetails.country ?? '',
        postalCode: placeDetails.postalCode ?? '',
        phone: placeDetails.phone ?? '',
        url: placeDetails.url ?? '',
        photoUrl: placeDetails.photoUrl ?? '',
        // Number fields
        rating: placeDetails.rating ?? undefined,
        lat: placeDetails.lat ?? undefined,
        lng: placeDetails.lng ?? undefined
      };
      return cleanedPlace;
    },
    [analytics, supportAutocompletePreDefinedPlacesEnabled, supportAutocompletePreDefinedPlaces]
  );

  return {
    preDefinedPlacesEnabled: supportAutocompletePreDefinedPlacesEnabled,
    preDefinedPlaces: supportAutocompletePreDefinedPlaces ?? [],
    getSearchPreDefinedPlaces,
    getPreDefinedPlaceById
  };
};
