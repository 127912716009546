import { Box } from '@withjoy/joykit';
import { CardCarouselSlide } from '../../useCardCarouselValues';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VimeoContainer } from '../CardTileConcierge';
import Player from '@vimeo/player';
import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

type CarouselVideoProps = {
  slide: CardCarouselSlide;
  isCarouselActive: boolean;
  isActiveSlide: boolean;

  onRequestPause: () => void;
  onRequestPlay: () => void;
};

// Loads video on carousel hover
// Plays video when this slide is showing.
export const CarouselVideo = (props: CarouselVideoProps) => {
  const { slide, isActiveSlide, isCarouselActive, onRequestPause, onRequestPlay } = props;
  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [videoPlayer, setVideoPlayer] = useState<Player | undefined>(undefined);
  const playTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const duration = useRef<number | null>(null);
  const isMobile = useIsMobileScreen();

  const isActiveMobile = isMobile && isActiveSlide && isCarouselActive;
  const isActiveDesktop = !isMobile && isCarouselActive;
  const isActive = isActiveMobile || isActiveDesktop;

  const getDuration = async (player: Player) => {
    if (!duration.current) {
      duration.current = await player.getDuration();
    }
  };

  const setPlayTimeout = useCallback(
    (duration: number) => {
      const timeoutID = setTimeout(() => {
        onRequestPlay();

        if (playTimeoutRef.current) {
          clearTimeout(playTimeoutRef.current);
        }
      }, duration * 1000);

      playTimeoutRef.current = timeoutID;
    },
    [onRequestPlay]
  );

  const videoUrl = useMemo(() => {
    if (slide.type !== 'video') {
      return null;
    }

    const url = new URL(slide.src);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [slide]);

  const showAndPlay = useCallback(
    async (player?: Player) => {
      const video = player ?? videoPlayer;
      if (isActiveSlide && video?.getPaused()) {
        video.setCurrentTime(0);
        video.play().catch(() => {
          // Ignore the error because it's expected when the video is forcibly paused
        });

        onRequestPause();
        setPlayTimeout(duration.current ?? 0);
      }
    },
    [isActiveSlide, onRequestPause, setPlayTimeout, videoPlayer]
  );

  const onPlayerLoad = () => {
    if (playerRef && playerRef.current && VimeoPlayer && !videoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      setVideoPlayer(player);
      getDuration(player);
    }
  };

  useEffect(() => {
    if (isActiveSlide && isCarouselActive) {
      showAndPlay(videoPlayer);
    } else {
      videoPlayer?.pause();

      if (playTimeoutRef.current) {
        clearTimeout(playTimeoutRef.current);
      }
    }
  }, [isActiveSlide, isCarouselActive, showAndPlay, videoPlayer]);

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  if (slide.type !== 'video') {
    return null;
  }

  return (
    <Box width="100%" height="100%" overflow="hidden" position="relative" pointerEvents="none">
      {isActive && <VimeoContainer src={videoUrl!} r={playerRef} onLoad={onPlayerLoad} />}
    </Box>
  );
};
