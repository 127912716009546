import { useEffect } from 'react';
import { ThemeJson } from '../../CardCustomizer.types';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useGetEventPrivacyForCardCustomizerQuery } from '@graphql/generated';

export const usePopulateEventLinkAndPassword = (onPrivacyDataChange: (cb: (draft: ThemeJson['card']['back'] | ThemeJson['card']['front']) => void) => void) => {
  const { eventInfo, eventHandle } = useEventInfo();

  const { data: privacyData } = useGetEventPrivacyForCardCustomizerQuery({
    variables: {
      eventId: eventInfo?.eventId!
    },
    batchMode: 'fast',
    skip: !eventInfo?.eventId
  });
  const populateEventLinkAndPassword = (draft: ThemeJson['card']['back'] | ThemeJson['card']['front']) => {
    if (!privacyData) return;

    const env = process.env.NODE_ENV === 'development' ? 'dev.' : '';
    const shouldShowPassword = privacyData.eventById?.settings.privacyMode !== 'public';
    const eventPassword = privacyData.eventById?.info.eventPassword || '';

    // QR code fails in joy app if it does not have URL with protocol like https://
    const link = `https://${env}withjoy.com/${eventHandle}`;

    for (const layer of draft.layers) {
      if (layer.type === 'text') {
        if (layer.name === 'URL') {
          layer.textData.content = link;
        }
        if (layer.name === 'PASSWORD') {
          layer.textData.content = shouldShowPassword ? `Password: ${eventPassword}` : '';
        }
      }
      if (layer.type === 'qrcode') {
        const hasPlaceholderLink = !layer.qrcodeData.link || layer.qrcodeData.link === 'withjoy.com/name-and-name';
        const isJoyEventLink = layer.qrcodeData.link.includes('withjoy.com');
        const endsWithCorrectHandle = layer.qrcodeData.link.endsWith(`withjoy.com/${eventHandle}`);
        const hasEventHandleInUrlWithPageSelected = layer.qrcodeData.link.includes(`withjoy.com/${eventHandle}/`);

        const hasWrongEventHandle = isJoyEventLink && !endsWithCorrectHandle && !hasEventHandleInUrlWithPageSelected;

        if (hasPlaceholderLink || hasWrongEventHandle) {
          layer.qrcodeData.link = link;
        }

        const password = shouldShowPassword ? eventPassword : undefined;

        layer.qrcodeData.password = isJoyEventLink ? password : undefined;
      }
    }
  };

  useEffect(() => {
    onPrivacyDataChange(populateEventLinkAndPassword);
    // Disabling-next-line because `update` is not stable (larger issue),
    // however the stale `update` still works.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyData]);

  return { populateEventLinkAndPassword };
};
