import { StationeryDraftFormat, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { NavigationStep } from '../routes/CardCustomizer/CardCustomizer.types';
import { useCallback, useMemo } from 'react';

interface CardCustomizerConfig {
  steps: NavigationStep[];
}

const CONFIG_BASE: Record<StationeryDraftFormat, CardCustomizerConfig> = {
  [StationeryDraftFormat.digital]: {
    steps: ['cardFront', 'cardBack', 'envelope', 'review', 'postCheckout', 'success']
  },
  [StationeryDraftFormat.print]: {
    steps: ['cardFront', 'cardBack', 'envelope', 'recipients', 'review', 'postCheckout']
  }
};

const OVERRIDES: Record<StationeryDraftFormat, Partial<Record<StationeryTemplateCategoryEnum, CardCustomizerConfig>>> = {
  [StationeryDraftFormat.digital]: {},
  [StationeryDraftFormat.print]: {
    [StationeryTemplateCategoryEnum.enclosure]: {
      steps: ['cardFront', 'cardBack', 'review', 'postCheckout']
    }
  }
};

const useConfigHelpers = (config: CardCustomizerConfig) => {
  const isStepEnabled = useCallback(
    (step: NavigationStep) => {
      return config.steps.includes(step);
    },
    [config]
  );

  return useMemo(
    () => ({
      isStepEnabled
    }),
    [isStepEnabled]
  );
};

export const useCardCustomizerConfig = (format: StationeryDraftFormat, category: StationeryTemplateCategoryEnum) => {
  const baseConfig = CONFIG_BASE[format];
  const overrides = OVERRIDES[format]?.[category];

  const config = useMemo(() => ({ ...baseConfig, ...(overrides || {}) }), [baseConfig, overrides]);
  const helpers = useConfigHelpers(config);

  return {
    config,
    helpers
  };
};
