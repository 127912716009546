import React, { useMemo } from 'react';
import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';
import { CardPreviewFavoriteButton } from '../CardCustomizationContent/CardPreviewFavoriteButton';
import { useCardPreviewPaneAssets } from '../useCardPreviewPaneAssets';
import { PremiumPDPReveal } from './PremiumPDPReveal';
import { Flex } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import { Shadow } from '@apps/card/routes/CardCustomizer/steps/CardDesign/CardPagePreview';
import { StationeryDraftFormat } from '@graphql/generated';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useGalleryGridStyle } from '../../../DesignsGallery/useGalleryGridStyle';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const isMobile = useIsMobileScreen();
  const { cardJson, cardFront, cardShape, thumbnailUrl, eventId, templateId, isFavorite, themeId, templateCategory } = props;
  const { aspectRatioFrameBoxShadow } = useGalleryGridStyle();
  const extraAssets = useCardPreviewPaneAssets('wedding', templateCategory, 'joy_digital');
  const { value: interactivePreviewValue } = useFeatureValue('printPdpEnableInteractiveDigitalPreview');
  const isInteractivePreviewEnabled = interactivePreviewValue === 'on';

  const previewContent = useMemo(() => {
    // Old static preview
    const classicPreviewContent = (
      <Shadow shadow={aspectRatioFrameBoxShadow as string} shape={cardShape}>
        <Page width={5} height={7} page={cardFront} shape={cardShape} format={StationeryDraftFormat.digital} />
      </Shadow>
    );

    // Returns new interactive preview, or appropriate static preview for mobile / desktop.
    return isInteractivePreviewEnabled ? (
      <Flex flexDirection="column" marginY={7} marginX="auto" width="100%" alignItems="center" marginTop={-32}>
        <PremiumPDPReveal cardJSON={cardJson} />
      </Flex>
    ) : isMobile ? (
      <PageScaler>{classicPreviewContent}</PageScaler>
    ) : (
      <FitScaler>{classicPreviewContent}</FitScaler>
    );
  }, [aspectRatioFrameBoxShadow, cardShape, cardFront, isInteractivePreviewEnabled, cardJson, isMobile]);

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => {
          return (
            <AssetContainer
              key="cardFront"
              label="Front of the Card"
              enforceAspectRatio={isInteractivePreviewEnabled ? true : !isMobile}
              badge={<CardPreviewFavoriteButton themeId={themeId} templateCategory={templateCategory} eventId={eventId || ''} templateId={templateId} isFavorite={isFavorite} />}
            >
              {previewContent}
            </AssetContainer>
          );
        },
        thumbnailUrl: thumbnailUrl
      },
      ...extraAssets
    ];
  }, [thumbnailUrl, extraAssets, isInteractivePreviewEnabled, isMobile, themeId, templateCategory, eventId, templateId, isFavorite, previewContent]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
