import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { PriceConfig } from './CardCustomizer.types';
import { getEntries } from '@shared/utils/object';
import { useFeatureValue } from '@shared/core/featureFlags';
import { PRINT_SAMPLE_ORDER_QUANTITY } from '@apps/card/config';
import { useMemo } from 'react';
import { useCustomizationContext } from './useCardCustomization';
import { useFoilConfig } from '@apps/card/hooks/useFoilConfig';
import { deriveFullCustomizationsFromConfig } from '@apps/card/components/PriceSummary/PriceSummary';
import { useMinorUnitsToPriceString } from '@apps/card/hooks/useMinorUnitsToPriceString';

export const usePrintCardsQuantityOptions = (priceConfig: PriceConfig, templateCategory: StationeryTemplateCategoryEnum) => {
  const { value: printEnableSampleOrderValue } = useFeatureValue('printEnableSampleOrder');
  const [draft] = useCustomizationContext(draft => draft);
  const foilConfig = useFoilConfig();
  const minorUnitsToDollars = useMinorUnitsToPriceString();

  const extraPricePerCardInMinorUnits = useMemo(() => {
    const customizations = deriveFullCustomizationsFromConfig(draft.config);

    if (customizations.foilData) {
      return foilConfig.pricePerCardInMinorUnits;
    }

    return 0;
  }, [draft.config, foilConfig.pricePerCardInMinorUnits]);

  const options = useMemo(() => {
    const entries = getEntries(priceConfig.quantity[templateCategory]).filter(entry => {
      const [, config] = entry;

      if (printEnableSampleOrderValue !== 'on' && config.quantity === PRINT_SAMPLE_ORDER_QUANTITY) {
        return false;
      }

      return true;
    });

    return entries.reduce((acc, [, config]) => {
      const pricePerCard = config.individualPriceInMinorUnits + extraPricePerCardInMinorUnits;
      const totalPrice = pricePerCard * config.quantity;

      const pricePerCardString = minorUnitsToDollars(pricePerCard, { canShowFreeLabel: true });
      const totalPriceString = minorUnitsToDollars(totalPrice, { canShowFreeLabel: true });

      acc.push({
        key: config.quantity.toString(),
        label: `${config.quantity} Cards ${totalPriceString} (${pricePerCardString} each)`,
        value: config.quantity,
        pricePerCardString,
        totalPriceString
      });
      return acc;
    }, [] as Array<{ key: string; label: string; value: number; pricePerCardString: string; totalPriceString: string }>);
  }, [priceConfig.quantity, templateCategory, printEnableSampleOrderValue, extraPricePerCardInMinorUnits, minorUnitsToDollars]);

  const selectedOption = useMemo(() => {
    return options.find(opt => opt.value === draft.config.customizations.quantity);
  }, [options, draft.config.customizations.quantity]);

  return { options, selectedOption };
};
