import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, useToast } from '@withjoy/joykit';
import { Cell, FocalPoint, HighlightMask } from './FocusPointEditor.styles';
import { useTranslation } from '@shared/core';
import { WarningTriangle } from '@withjoy/joykit/icons';
import { FocusPointEditorProps, PhotoAlignmentX, PhotoAlignmentY } from './FocusPointEditor.types';
import { pxToRem } from '@withjoy/joykit/theme';
import {
  EventPageType,
  PhotoXPosition,
  PhotoYPosition,
  useSetPageContainerPhotoAlignmentMutation,
  useSetPhotoAlignmentMutation,
  useUploadPhotoFromUrlMutation
} from '@graphql/generated';
import { useTransition } from 'react-spring';
import { CheckIcon } from '@assets/icons';
import { TempSpinner } from '../TempSpinner';
import globalWindow from '@shared/core/globals';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';
import { EditorLayout } from './EditorLayout';
import { findMatchingPageType } from './utils';

const YPositions = [PhotoYPosition.top, PhotoYPosition.center, PhotoYPosition.bottom];
const XPositions = [PhotoXPosition.left, PhotoXPosition.center, PhotoXPosition.right];

enum Orientation {
  LANDSCAPE,
  PORTRAIT,
  SQUARE
}

const RATIOS_ARRAY = ['1:1', '2:3', '3:2', '3:4', '4:3', '4:5', '5:4', '11:10', '10:11'];

const SIZE_SETTING: Record<Orientation, { container: [number, number]; [key: string]: [number, number] }> = {
  [Orientation.LANDSCAPE]: {
    container: [400, 400],
    '3:2': [400, 266], // [width, height]
    '4:3': [400, 300],
    '5:4': [400, 320]
  },
  [Orientation.PORTRAIT]: {
    container: [400, 400],
    '2:3': [266, 400],
    '3:4': [300, 400],
    '4:5': [320, 400]
  },
  [Orientation.SQUARE]: {
    container: [400, 440],
    '1:1': [400, 400],
    '11:10': [440, 400],
    '10:11': [400, 440]
  }
};

const closest = (ratio: number, ratiosArray: number[]) => {
  let curr = ratiosArray[0];
  let diff = Math.abs(ratio - curr);
  for (let val = 0; val < ratiosArray.length; val++) {
    const newdiff = Math.abs(ratio - ratiosArray[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = ratiosArray[val];
    }
  }
  return curr;
};

const ratioFloatsArray = RATIOS_ARRAY.map(item => {
  const temp = item.split(':');
  return parseFloat(temp[0]) / parseFloat(temp[1]);
});

const calculateAspectRatio = (width: number, height: number) => {
  const currentRatioFloat = width / height;
  const matchedRatioFloat = closest(currentRatioFloat, ratioFloatsArray);
  const matchedRatio = RATIOS_ARRAY[ratioFloatsArray.indexOf(matchedRatioFloat)];
  let orientation;
  if (['1:1', '11:10', '10:11'].includes(matchedRatio)) {
    orientation = Orientation.SQUARE;
  } else if (matchedRatioFloat > 1) {
    orientation = Orientation.LANDSCAPE;
  } else {
    orientation = Orientation.PORTRAIT;
  }

  // Get the size settings based on the orientation
  const size = SIZE_SETTING[orientation];
  const imageSize = [...size[matchedRatio]];
  const containerSize = [...size.container];

  const windowWidth = globalWindow.innerWidth;
  const windowHeight = globalWindow.innerHeight;

  const IMG_PADDINNG_X = 40;
  const DESKTOP_THRESHOLD_HEIGHT = 332;

  // Adjust the image size for mobile devices
  if (windowWidth && windowWidth <= 768) {
    if (imageSize[0] + IMG_PADDINNG_X > windowWidth) {
      imageSize[0] = windowWidth - IMG_PADDINNG_X;
      imageSize[1] = imageSize[0] / matchedRatioFloat;
    }
  }
  // Adjust the image size for desktop devices
  else if (windowWidth && windowWidth > 768 && windowHeight && imageSize[1] + DESKTOP_THRESHOLD_HEIGHT > windowHeight) {
    imageSize[1] = windowHeight - DESKTOP_THRESHOLD_HEIGHT;
    imageSize[0] = imageSize[1] * matchedRatioFloat;
    containerSize[0] = imageSize[0];
    containerSize[1] = imageSize[1];
  }

  return {
    orientation,
    aspectRatio: matchedRatio,
    size: {
      container: containerSize,
      image: imageSize
    }
  };
};

const Mask: FC<{ isActive: boolean }> = ({ isActive }) => {
  const transition = useTransition(isActive, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 }
  });
  return <>{transition.map(({ item, key, props: style }) => item && <HighlightMask key={key} style={style} />)}</>;
};

export const FocusPointEditor = (props: FocusPointEditorProps) => {
  const {
    isOpen,
    handleOnCancel,
    imageData,
    pageType,
    pageId,
    pageIdsData,
    eventId,
    isLoadingPhoto,
    handleSaveUploadedPhoto,
    isUpdatingEventPhoto,
    updatePagePhotoPreview,
    uploadedImageData,
    onFocusPointChange,
    telemetryCategory,
    pageSlug,

    handleCloseClick,
    isDeletingPagePhoto,
    handleRemovePhoto,
    handleChangePhoto,
    handleCancelClick
  } = props;
  const alignX = (imageData?.layout.alignX as PhotoAlignmentX) || 'center';
  const alignY = (imageData?.layout.alignY as PhotoAlignmentY) || 'center';

  const [focusPoint, setFocusPoint] = useState<[PhotoXPosition, PhotoYPosition]>([PhotoXPosition[alignX], PhotoYPosition[alignY]]);
  const [aspectRatio, setAspectRatio] = useState({
    orientation: Orientation.SQUARE,
    aspectRatio: '1:1',
    size: {
      container: [400, 400],
      image: [400, 400]
    }
  });

  useEffect(() => {
    const checkModalPosition = () => {
      setTimeout(() => {
        setAspectRatio(calculateAspectRatio(imageData?.width || 1, imageData?.height || 1));
      }, 250);
    };
    window.addEventListener('resize', checkModalPosition);
    checkModalPosition();
    setAspectRatio(calculateAspectRatio(imageData?.width || 1, imageData?.height || 1));
    return () => window.removeEventListener('resize', checkModalPosition);
  }, [isOpen, imageData?.width, imageData?.height]);

  const { toast } = useToast();
  const { t2 } = useTranslation('joykit');
  const dialogTrans = t2('focusPointDialog');

  const telemetry = useFocusPointEditorTelemetry();

  const [uploadPhotoFromUrl, { loading: waitingUploadPhoto }] = useUploadPhotoFromUrlMutation();
  const [setPageContainerPhotoAlignment, { loading: waitingForUpdatePageContainer }] = useSetPageContainerPhotoAlignmentMutation({
    onCompleted: () => toast(`${dialogTrans.photoSettingsUpdateSuccess}`, { icon: <CheckIcon /> }),
    onError: () => toast(`${dialogTrans.photoSettingsUpdateFailure}`, { icon: <WarningTriangle /> })
  });
  const [updatePhotoAlignment, { loading: isUpdatingEventPhotoAlignment }] = useSetPhotoAlignmentMutation({
    onCompleted: () => toast(`${dialogTrans.photoSettingsUpdateSuccess}`, { icon: <CheckIcon /> }),
    onError: () => toast(`${dialogTrans.photoSettingsUpdateFailure}`, { icon: <WarningTriangle /> })
  });

  const isUpdatingPhotoAlignment = isUpdatingEventPhotoAlignment || waitingForUpdatePageContainer || waitingUploadPhoto;

  const handleFocusPointChange = (x: PhotoXPosition, y: PhotoYPosition) => () => {
    setFocusPoint([x, y]);
    onFocusPointChange && onFocusPointChange(x, y);
  };

  const handleSaveFocusPoint = async () => {
    await handleSaveUploadedPhoto();
    // Custom and Normal page photo have different end points, we have to maintain both in global level
    if (pageType === EventPageType.custom) {
      let mainPhotoId: string | null | undefined = null;
      if (uploadedImageData && imageData?.url && pageId && uploadedImageData.assetId) {
        const { data } = await uploadPhotoFromUrl({
          variables: {
            payload: {
              assetId: uploadedImageData.assetId,
              containerId: pageId,
              url: imageData.url
            }
          }
        });
        mainPhotoId = data?.uploadPhotoFromUrl?.id;
        telemetry.pagePhotoUpdated(telemetryCategory, 'Update', 'succeeded', { pageType: pageSlug, actualPageType: pageType });
      }
      await setPageContainerPhotoAlignment({
        variables: {
          photoAlignmentpayload: {
            id: pageId ?? '',
            ...(mainPhotoId && { mainPhotoId }),
            ...(focusPoint && { mainPhotoAlignment: { x: focusPoint[0], y: focusPoint[1] } })
          },
          photoPresentationPayload: {
            pageId: pageId ?? '',
            presentationLayout: 'center'
          }
        },
        refetchQueries: ['PageContainerBySlug', 'WebsiteInfo'],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'FocusChanged', 'succeeded', {
            previousFocus: [(imageData?.layout.alignX as PhotoXPosition) || PhotoXPosition.center, (imageData?.layout.alignY as PhotoYPosition) || PhotoYPosition.center],
            currentFocus: focusPoint
          });
          handleOnCancel();
          const mainPhoto = info.data?.updatePageContainer.mainPhoto;
          const mainPhotoAlignment = info.data?.updatePageContainer.mainPhotoAlignment;
          const photoPresentation =
            info.data?.websiteCreateOrUpdatePagePhotoPresentation?.__typename === 'websiteCreateOrUpdatePagePhotoPresentationResponse'
              ? info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation
              : null;
          if (updatePagePhotoPreview && mainPhoto && mainPhotoAlignment) {
            const assetId = uploadedImageData?.assetId || imageData?.assetId || mainPhoto.id;
            updatePagePhotoPreview(
              assetId,
              {
                id: mainPhotoId || mainPhoto.id,
                url: mainPhoto.url,
                assetId,
                height: mainPhoto.height,
                width: mainPhoto.width,
                layout: {
                  alignX: mainPhotoAlignment?.x,
                  alignY: mainPhotoAlignment?.y
                }
              },
              photoPresentation
            );
          }
        }
      });
    } else {
      telemetry.pagePhotoUpdated(telemetryCategory, 'FocusChanged', 'succeeded', {
        previousFocus: [(imageData?.layout.alignX as PhotoXPosition) || PhotoXPosition.center, (imageData?.layout.alignY as PhotoYPosition) || PhotoYPosition.center],
        currentFocus: focusPoint
      });
      const pageTypeToUse = findMatchingPageType(pageType) || pageType;
      const pageIdToUse = pageIdsData.find(page => page.type === pageTypeToUse)?.id || pageId;
      updatePhotoAlignment({
        variables: {
          eventId: eventId || '',
          photoAlignmentpayload: {
            page: pageTypeToUse,
            isMobile: false,
            alignment: {
              x: focusPoint[0],
              y: focusPoint[1]
            }
          },
          photoPresentationPayload: {
            pageId: pageIdToUse || '',
            presentationLayout: 'center'
          }
        }
      }).then(info => {
        if (!info.errors) {
          handleOnCancel();
          if (updatePagePhotoPreview && info.data?.setPhotoAlignment) {
            const photoPresentation =
              info.data.websiteCreateOrUpdatePagePhotoPresentation?.__typename === 'websiteCreateOrUpdatePagePhotoPresentationResponse'
                ? info.data.websiteCreateOrUpdatePagePhotoPresentation.photoPresentation
                : null;
            updatePagePhotoPreview(uploadedImageData?.assetId || info.data.setPhotoAlignment.assetId, info.data.setPhotoAlignment, photoPresentation);
          }
        }
      });
    }
  };
  return (
    <EditorLayout
      onCloseButtonClick={handleCloseClick}
      isCloseButtonDisabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
      content={() => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          width="100%"
          aspectRatio={aspectRatio.aspectRatio.replace(':', '/')}
          maxWidth={aspectRatio.size.container[0]}
          maxHeight={aspectRatio.size.container[1]}
        >
          <Box
            background={imageData?.url ? `url("${imageData.url}") lightgray 50%` : 'lightgray 50%'}
            backgroundSize="cover"
            backgroundPosition="center"
            position="relative"
            backgroundRepeat="no-repeat"
            width="100%"
            height="100%"
            borderRadius={3}
            overflow="hidden"
            maxWidth={aspectRatio.size.image[0]}
            maxHeight={aspectRatio.size.image[1]}
            __css={{ ['.loading > div']: { width: pxToRem(40), height: pxToRem(40) } }}
          >
            {isLoadingPhoto && <TempSpinner position={'absolute'} className="loading" />}
            <Box
              display="grid"
              justifyContent="center"
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              gridTemplateColumns="repeat(3, 1fr)"
              background="rgba(0, 0, 0, 0.48)"
              visibility={isLoadingPhoto ? 'hidden' : 'visible'}
            >
              {YPositions.map(y =>
                XPositions.map(x => (
                  <Cell
                    key={`align-${y}-${x}`}
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    isActive={focusPoint[0] === x && focusPoint[1] === y}
                    onClick={handleFocusPointChange(PhotoXPosition[x], PhotoYPosition[y])}
                  >
                    <FocalPoint justifyContent="center" alignItems="center" backgroundColor="white" width={4} height={4} borderRadius="full" border="0 none" />
                    <Mask isActive={focusPoint[0] === x && focusPoint[1] === y} />
                  </Cell>
                ))
              )}
            </Box>
          </Box>
        </Flex>
      )}
      note={dialogTrans.note}
      isDeletePhotoButtonDisabled={isLoadingPhoto || isDeletingPagePhoto}
      isDeletePhotoButtonLoading={isDeletingPagePhoto}
      onDeletePhotoButtonClick={handleRemovePhoto}
      onReplacePhotoButtonClick={handleChangePhoto}
      isReplacePhotoButtonDisabled={isLoadingPhoto}
      onSavePhotoButtonClick={handleSaveFocusPoint}
      isSavePhotoButtonDisabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto || isLoadingPhoto}
      isSavePhotoButtonLoading={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
      onCancelButtonClick={handleCancelClick}
      isCancelButtonDisabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
    />
  );
};
