import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex } from '@withjoy/joykit';
import React from 'react';
import { activePrintFiltersCacheApi } from '../../../utils/activePrintFiltersCache';

export const MobileFiltersTrigger = React.forwardRef<HTMLButtonElement, { label: string; onClick?: () => void }>(({ label, onClick }, ref) => {
  const activeFiltersCount = activePrintFiltersCacheApi.getTotalActiveFilterCount();

  return (
    <Box
      backgroundColor="mono2"
      color="mono14"
      as="button"
      height={40}
      paddingX={5}
      borderRadius={24}
      transition={animationTransition('background-color')}
      onClick={onClick}
      ref={ref}
    >
      <Box as="span" display="inline-flex" borderRadius="inherit" alignItems="center" columnGap={3} typographyVariant="button1">
        {activeFiltersCount > 0 && (
          <Flex borderRadius="inherit" backgroundColor="black" color="white" size={30} justifyContent="center" alignItems="center">
            {activeFiltersCount}
          </Flex>
        )}

        {label}
      </Box>
    </Box>
  );
});
