import { CardCarouselSlide } from '../../useCardCarouselValues';
import { CarouselImage } from './CarouselImage';
import React from 'react';
import { CarouselVideo } from './CarouselVideo';

type CarouselSlideProps = {
  slide: CardCarouselSlide;
  isActiveSlide: boolean;
  isCarouselActive: boolean;

  onRequestPause: () => void;
  onRequestPlay: () => void;
};

export const CarouselSlide = (props: CarouselSlideProps) => {
  const { slide, isCarouselActive, isActiveSlide, onRequestPause, onRequestPlay } = props;
  const { type } = slide;

  switch (type) {
    case 'image':
      return <CarouselImage isCarouselActive={isCarouselActive} isActiveSlide={isActiveSlide} slide={slide} />;
      break;
    case 'video':
      return <CarouselVideo onRequestPause={onRequestPause} onRequestPlay={onRequestPlay} isCarouselActive={isCarouselActive} isActiveSlide={isActiveSlide} slide={slide} />;
      break;
  }

  return null;
};
