import React from 'react';
import { Box, Flex, PopoverV2, styled, useDisclosure } from '@withjoy/joykit';
import { ChevronDown, ChevronUp } from '@withjoy/joykit/icons';

import { animationTransition } from '@shared/utils/animationTransition';
import { ListCheckboxRow } from './ListCheckboxRow';
import { activePrintFiltersCacheApi } from '../../../utils/activePrintFiltersCache';
import { FilterFacet } from '../Filter.types';
import { LearnMoreLink } from '../../LearnMoreLink';

interface FilterPillProps {
  facet: FilterFacet;
  onFilterChange: React.ChangeEventHandler<HTMLInputElement>;
  interaction: 'popover' | 'drawer';
  onTriggerFilterDrawer: (groupCode: string) => void;
}

const PopoverBodyOverride = styled(Box)`
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.mono5};
  }
`;

const Trigger = React.forwardRef<HTMLButtonElement, { isOpen: boolean; activeFiltersCount: number; label: string; onClick?: () => void }>(
  ({ activeFiltersCount, isOpen, label, onClick }, ref) => {
    const ArrowIcon = isOpen ? ChevronUp : ChevronDown;
    return (
      <Box
        backgroundColor={isOpen ? 'mono3' : 'mono2'}
        color="mono14"
        as="button"
        height={40}
        paddingX={5}
        borderRadius={24}
        transition={animationTransition('background-color')}
        onClick={onClick}
        ref={ref}
      >
        <Box as="span" display="inline-flex" borderRadius="inherit" alignItems="center" columnGap={3} typographyVariant="button1">
          {activeFiltersCount > 0 && (
            <Flex borderRadius="inherit" backgroundColor="black" color="white" size={30} justifyContent="center" alignItems="center">
              {activeFiltersCount}
            </Flex>
          )}
          {label}
          <ArrowIcon size={16} />
        </Box>
      </Box>
    );
  }
);

export const FilterPill = React.memo((props: FilterPillProps) => {
  const { facet, interaction, onFilterChange, onTriggerFilterDrawer } = props;
  const { label, options } = facet;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const activeFiltersCount = activePrintFiltersCacheApi.getActiveFilterCountByGroupCode(facet.groupCode);

  if (interaction === 'drawer') {
    return <Trigger isOpen={false} activeFiltersCount={activeFiltersCount} label={label} onClick={() => onTriggerFilterDrawer(facet.groupCode)} />;
  }

  return (
    <PopoverV2
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottomStart"
      overrides={{
        Body: {
          props: {
            maxHeight: 320,
            maxWidth: 264,
            overflowY: 'scroll'
          },
          component: PopoverBodyOverride
        }
      }}
    >
      <PopoverV2.Trigger>
        <Trigger isOpen={isOpen} activeFiltersCount={activeFiltersCount} label={label} />
      </PopoverV2.Trigger>
      <PopoverV2.Content>
        <Flex flexDirection="column" paddingX={5} paddingY={4} rowGap={2}>
          {options.map(option => (
            <ListCheckboxRow key={option.compositeCode} {...option} onChange={onFilterChange} />
          ))}
          {facet.learnMoreUrl && <LearnMoreLink url={facet.learnMoreUrl} marginTop={4} />}
        </Flex>
      </PopoverV2.Content>
    </PopoverV2>
  );
});
