import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { NavLink } from '@shared/core';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { animationTransition } from '@shared/utils/animationTransition';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { pxToRem } from '@withjoy/joykit/theme';
import { useStationeryTemplateCategoryTranslations } from '@apps/card/hooks/useStationeryTemplateCategoryTranslations';

const NavItem = ({ category }: { category: StationeryTemplateCategoryEnum }) => {
  const { getShopPath } = useCardsRouterContext();
  const { viewCategoryDesigns } = useCardTelemetry();
  const isMobile = useIsMobileOrTablet();

  const getCategoryTitle = useStationeryTemplateCategoryTranslations('dashboardCategoryNav');

  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      typographyVariant={{ md: 'hed2' }}
      as={NavLink}
      to={getShopPath('wedding', category)}
      onClick={() => {
        viewCategoryDesigns(category);
      }}
      cursor="pointer"
      paddingY={4}
      _after={{
        content: '" "',
        position: 'absolute',
        bottom: isMobile ? 0 : pxToRem(11),
        width: '100%',
        height: isMobile ? '2px' : '1px',
        backgroundColor: 'mono12',
        opacity: 0,
        transition: animationTransition('opacity')
      }}
      __css={{
        ['&.active']: {
          _after: {
            opacity: 1
          },
          cursor: 'default',
          pointerEvents: 'none'
        },
        ['&:not(.active)']: {
          _hover: {
            _after: {
              opacity: 0.2
            }
          }
        }
      }}
    >
      {getCategoryTitle(category)}
    </Box>
  );
};

export const CategoryNav = () => {
  const { value: showHolidayCategory } = useFeatureValue('printHolidayCategoryEnabled');
  const { value: showEnclosureCategory } = useFeatureValue('printEnclosureCardEnabled');

  return (
    <Flex
      as="ul"
      justifyContent={{ _: 'flex-start', md: 'center' }}
      height={10}
      whiteSpace="nowrap"
      overflowX="auto"
      paddingX={6}
      borderBottom={'1px solid'}
      borderBottomColor={'mono3'}
      columnGap={6}
      style={{
        scrollbarWidth: 'none'
      }}
    >
      <NavItem category={StationeryTemplateCategoryEnum.saveTheDate} />
      <NavItem category={StationeryTemplateCategoryEnum.invitation} />
      {showEnclosureCategory === 'on' && <NavItem category={StationeryTemplateCategoryEnum.enclosure} />}
      <NavItem category={StationeryTemplateCategoryEnum.thankYou} />
      {showHolidayCategory === 'on' && <NavItem category={StationeryTemplateCategoryEnum.holiday} />}
    </Flex>
  );
};
