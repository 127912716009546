import { useCallback } from 'react';
import { CardDieCut, CardPaperType } from './CardCustomizer.types';
import { useCustomizationContext } from './useCardCustomization';

const DISABLED_MAP: Record<CardPaperType, CardDieCut[]> = {
  [CardPaperType.signature]: [],
  [CardPaperType.smooth]: [],
  [CardPaperType.pearlescent]: [],
  [CardPaperType.doubleThick]: [CardDieCut.rounded, CardDieCut.arch],
  [CardPaperType.tripleThick]: [CardDieCut.rounded, CardDieCut.arch]
};

const useAllowedTrims = (paperType: CardPaperType, dieCut: CardDieCut) => {
  const [_, update] = useCustomizationContext(draft => draft.config.customizations);

  const getDisabledPaperTypes = useCallback((cardDieCut: CardDieCut) => {
    const values = [];

    for (const [key, value] of Object.entries(DISABLED_MAP)) {
      if (value.includes(cardDieCut)) {
        values.push(key);
      }
    }

    return values;
  }, []);

  const reflectChange = ({ newPaperType, newDieCut }: { newPaperType?: CardPaperType; newDieCut?: CardDieCut }) => {
    if (newPaperType) {
      const disabledDieCuts = DISABLED_MAP[newPaperType];

      if (disabledDieCuts.includes(dieCut)) {
        update(draft => {
          draft.shape = CardDieCut.rectangle;
        });
      }
    }

    if (newDieCut) {
      const disabledPaperTypes = getDisabledPaperTypes(newDieCut);

      if (disabledPaperTypes.includes(paperType)) {
        update(draft => {
          draft.paperType = CardPaperType.signature;
        });
      }
    }
  };

  return {
    reflectChange,
    disabledTrims: DISABLED_MAP[paperType],
    disabledPaperTypes: getDisabledPaperTypes(dieCut)
  };
};

export default useAllowedTrims;
