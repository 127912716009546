import React from 'react';
import { useTranslation } from '@shared/core';
import { InheritSizeLinkTerms } from '@shared/createEvent/CreateAccount/CreateAccount.styles';

export const TermsOfServiceLink = () => {
  const { t } = useTranslation('createEvent');

  return (
    <InheritSizeLinkTerms
      _hover={{ color: 'mono12' }}
      _active={{ color: 'mono8' }}
      _visited={{ color: 'mono8' }}
      typographyVariant="label1"
      target="_blank"
      href="https://withjoy.com/eula/"
    >
      {t('createAccount', 'termsOfService')()}
    </InheritSizeLinkTerms>
  );
};
