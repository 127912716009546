import React from 'react';
import { PRICE_CONFIG } from '@apps/card/config';
import { usePrintCardsQuantityOptions } from '../../usePrintCardsQuantityOptions';
import { useCustomizationContext } from '../../useCardCustomization';
import { ChoiceGroup } from '../../components/ChoiceGroup';
import { StyledSelect } from './CardDesign.styles';
import { useEditingMode } from '../../useEditingMode';
import { useQuantityDialog } from '../../useQuantityDialog';
import { useCardCustomizerTelemetry } from '../../CardCustomizer.telemetry';

interface QuantityChoiceGroupProps {
  icon?: React.ReactNode;
  disabled?: boolean;
  /** Used to force a list of recipients for Recipients screens (fallbacks to card.envelope.recipientIdList.length if not provided) */
  recipientsCount?: number;
  /** Used to prevent the quantity dialog from being opened when the quantity is decreased (RecipientsV2 case) */
  ignoreQuantityDialog?: boolean;
}

export const QuantityChoiceGroup = ({ icon, disabled, recipientsCount, ignoreQuantityDialog = false }: QuantityChoiceGroupProps) => {
  const { mode } = useEditingMode();
  const { customizationButtonInteracted } = useCardCustomizerTelemetry();

  const [templateCategory] = useCustomizationContext(draft => draft.stationeryTemplateCategory);
  const [cardEnvelope] = useCustomizationContext(draft => draft.config.card.envelope);
  const [general, update] = useCustomizationContext(draft => draft.config.customizations, mode === 'order');

  const { openQuantityDialog } = useQuantityDialog();
  const { options: quantityOptionsCalculated, selectedOption } = usePrintCardsQuantityOptions(PRICE_CONFIG, templateCategory);

  const { quantity } = general;

  return (
    <ChoiceGroup title="Quantity" value={`${quantity} Cards`} price={selectedOption?.totalPriceString} icon={icon}>
      <StyledSelect
        disabled={disabled}
        value={quantity}
        onChange={e => {
          const nextQty = Number(e.target.value);
          update(draft => {
            const count = recipientsCount ?? cardEnvelope.recipientIdList?.length ?? 0;

            if (nextQty < count && !ignoreQuantityDialog) {
              openQuantityDialog(nextQty, count);
            } else {
              draft.quantity = nextQty;
            }
          });
          customizationButtonInteracted({ property: 'quantityOption', value: nextQty });
        }}
      >
        {quantityOptionsCalculated.map(opt => (
          <option value={opt.value} key={opt.key}>
            {opt.label}
          </option>
        ))}
      </StyledSelect>
    </ChoiceGroup>
  );
};
