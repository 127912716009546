import { Page, PageProps } from '@apps/card/routes/CardCustomizer/components/Page';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { usePointerUnitPosition } from '@apps/card/routes/CardCustomizer/components/usePointerUnitPosition';
import { PAPER_TEXTURE_URL } from '@apps/greetingCard/components/Reveal/premiumEnvelope.styles';
import { StationeryDraftFormat } from '@graphql/generated';
import { Box } from '@withjoy/joykit';
import React from 'react';

interface InteractiveCardProps extends Pick<PageProps, 'width' | 'height' | 'page' | 'shape' | 'format'> {
  link?: string;
  onLinkClick?: () => void;
}

export const InteractiveCard: React.FC<InteractiveCardProps> = props => {
  const { width, height, page, shape, link, onLinkClick } = props;
  const { unitPosition, elementRef } = usePointerUnitPosition();
  const { x, y } = unitPosition;

  const handleOpenLink = () => {
    if (!link) return;
    function normalizeLink(link: string) {
      // Approximate email regex. A fully correct regex gets complicated.
      if (/^\S+@\S+$/.test(link)) return `mailto:${link}`;
      if (link.startsWith('http://')) return link;
      if (link.startsWith('https://')) return link;
      return `https://${link}`;
    }
    onLinkClick?.();
    window.open(normalizeLink(link.trim()), '_blank');
  };

  return (
    <Box
      ref={elementRef}
      style={{
        width,
        height,
        transform: `perspective(504px) rotateX( ${-y * 2}deg) rotateY(${x * 2}deg)`,
        filter: `drop-shadow(${-x * 20 - 10}px ${-y * 20 + 15}px  15px rgba(0,0, 0, 0.35))`,
        cursor: link ? 'pointer' : 'default'
      }}
      onClick={handleOpenLink}
      onKeyPress={handleOpenLink}
      role={link ? 'button' : undefined}
    >
      <PageScaler>
        <Page
          width={5}
          height={7}
          page={page}
          shape={shape}
          format={StationeryDraftFormat.digital}
          texture={`
            url(${PAPER_TEXTURE_URL}),
            radial-gradient(
              ellipse at ${(1 - (x + 0.5)) * 100}% ${(1 - (y + 0.5)) * 100}%,
              hsla(0, 0%, 100%, 0.2),
              hsla(0, 0%, 100%, 0.0)
            )
          `}
        />
      </PageScaler>
    </Box>
  );
};
