import { CardCustomizerTelemetryProvider, CustomizationProvider, useCardCustomization } from '@apps/card/routes/CardCustomizer';

import React, { useEffect } from 'react';
import { enrichCardCutomizerTelemetryExtraInfo } from '@apps/card/routes/CardCustomizer';
import { Code, Envelope, Invitation, MailingAddress, Share, Website, Edit, Photos, DesignEmail } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
// import { InfoTabs } from './InfoTabs';
import { CustomizeNowButton } from '../../CustomizeNowButton';
import { DraftData, ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { InlineValueProps, ListValueProps } from '../ValueProps/ValueProps';
import { useCustomizationPriceSummary } from '../../useCustomizationPriceSummary';
import { StationeryDraftFormat, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { CardFrontCustomizations } from '../../../../../PaperCardCustomizer/steps/CardDesign/PaperCardFrontConfigPanel';
import { BestDeliveryDayEstimation } from './BestDeliveryDayEsmation';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useCardCustomizerConfig } from '@apps/card/hooks/useCardCustomizedConfig';

interface CustomizationPaneProps {
  onCustomizeClick: () => void;
  isCreatingDraft: boolean;
  customizeButtonRef: React.RefObject<HTMLButtonElement>;
  hideCustomizeNowButton?: boolean;
  category: StationeryTemplateCategoryEnum;
  themeId: string;
}

const useInlineValuePropsByCategory = (category: StationeryTemplateCategoryEnum) => {
  const { t } = useTranslation('stationery');
  switch (category) {
    case StationeryTemplateCategoryEnum.holiday: {
      return [
        {
          icon: <Envelope />,
          description: 'Free Envelope Printing'
        },
        {
          icon: <MailingAddress />,
          description: 'Contact Collector!'
        },
        {
          icon: <DesignEmail />,
          description: 'Exclusive Designs'
        }
      ];
    }
    default: {
      const trans = t('pdpModal', 'customizationPane', 'printTemplateConfig');
      const inlineValuePropsTrans = trans.inlineValueProps;
      return [
        {
          icon: <Website />,
          description: inlineValuePropsTrans.websiteDesign()
        },
        {
          icon: <MailingAddress />,
          description: inlineValuePropsTrans.contactCollector()
        },
        {
          icon: <Code />,
          description: inlineValuePropsTrans.qrCodes()
        }
      ];
    }
  }
};

const useListValuePropsByCategory = (category: StationeryTemplateCategoryEnum) => {
  const { t } = useTranslation('stationery');
  const trans = t('pdpModal', 'customizationPane', 'printTemplateConfig');
  const { helpers } = useCardCustomizerConfig(StationeryDraftFormat.print, category);

  const lisValuePropsTrans = trans.listValueProps;
  switch (category) {
    case StationeryTemplateCategoryEnum.holiday: {
      return {
        sectionTitle: lisValuePropsTrans.sectionTitle(),
        items: [
          {
            icon: <Invitation size={24} />,
            description: 'Digital printing on our luxurious signature card stock with a subtle velvety texture'
          },
          {
            icon: <Edit size={24} />,
            description: 'Add rounded corners and customize your card the way you want'
          },
          {
            icon: <Photos size={24} />,
            description: 'Full color back of card designs with a variety of pattern and photo layouts'
          },
          {
            icon: <Envelope size={24} />,
            description: 'Premium white envelopes with complimentary address printing'
          }
        ]
      };
    }
    default: {
      return {
        sectionTitle: lisValuePropsTrans.sectionTitle(),
        items: [
          {
            icon: <Website size={24} />,
            description: lisValuePropsTrans.paperType()
          },
          {
            icon: <Invitation size={24} />,
            description: lisValuePropsTrans.silhouette()
          },
          {
            icon: <Share size={24} />,
            description: lisValuePropsTrans.qrCodes()
          },
          ...(helpers.isStepEnabled('envelope')
            ? [
                {
                  icon: <Envelope size={24} />,
                  description: lisValuePropsTrans.envelope()
                }
              ]
            : [])
        ]
      };
    }
  }
};

const Root = (props: CustomizationPaneProps) => {
  const { category, customizeButtonRef, hideCustomizeNowButton, onCustomizeClick, isCreatingDraft, themeId } = props;

  const { summary } = useCustomizationPriceSummary();

  enrichCardCutomizerTelemetryExtraInfo({
    stationeryDraftId: null,
    stationeryTemplateCategory: category,
    themeId,
    step: null,
    pageOverride: 'pdpModal',
    orderNumber: null,
    stationeryDraftFormat: null
  });

  const listValueProps = useListValuePropsByCategory(category);
  const inlineValueProps = useInlineValuePropsByCategory(category);
  const isBestDateEstimationExperimentEnabled = useFeatureValue('printPdpShippingDateEstimationExperiment').value === 'treatment';
  const isValuePropPlacementExperimentEnabled = useFeatureValue('printPdpValuePropPlacementExperiment').value === 'treatment';

  const valueProps = (
    <>
      <InlineValueProps items={inlineValueProps} />
      <ListValueProps items={listValueProps.items} sectionTitle={listValueProps.sectionTitle} marginBottom={isValuePropPlacementExperimentEnabled ? 7 : 0} />
    </>
  );

  return (
    <>
      <CustomizeNowButton
        hide={hideCustomizeNowButton}
        ref={customizeButtonRef}
        isLoading={isCreatingDraft}
        onCustomizeClick={onCustomizeClick}
        marginTop={0}
        marginBottom={isBestDateEstimationExperimentEnabled ? 5 : 7}
      />
      {isBestDateEstimationExperimentEnabled && <BestDeliveryDayEstimation marginBottom={isValuePropPlacementExperimentEnabled ? 0 : 7} />}
      {isValuePropPlacementExperimentEnabled && valueProps}
      <CardFrontCustomizations subtotal={summary.total} isInsidePDP={true} />
      <CustomizeNowButton isLoading={isCreatingDraft} onCustomizeClick={onCustomizeClick} marginTop={7} marginBottom={isBestDateEstimationExperimentEnabled ? 5 : 0} />
      {isBestDateEstimationExperimentEnabled && <BestDeliveryDayEstimation />}
      {!isValuePropPlacementExperimentEnabled && valueProps}
    </>
  );
};

interface CardCustomizationProps {
  intersectionRef: React.RefObject<HTMLButtonElement>;
  hideCustomizeNowButton: boolean;
  isCreatingDraft: boolean;
  onCardCustomizeClick: () => void;
  onCardCustomization: (draft: DraftData) => void;
  customizationConfig: ThemeJson;
  stationeryTemplateCategory: StationeryTemplateCategoryEnum;
  themeName: string;
  themeId: string;
}

export const CardCustomizationPane = (props: CardCustomizationProps) => {
  const {
    customizationConfig,
    hideCustomizeNowButton,
    intersectionRef,
    isCreatingDraft,
    onCardCustomizeClick,
    onCardCustomization,
    stationeryTemplateCategory,
    themeId,
    themeName
  } = props;
  const { scope, state } = useCardCustomization({
    config: customizationConfig,
    template: {
      themeId,
      name: themeName
    },
    stationeryTemplateCategory
  });

  useEffect(() => {
    onCardCustomization(state);
  }, [state, onCardCustomization]);
  return (
    <CustomizationProvider value={scope}>
      <CardCustomizerTelemetryProvider>
        <Root
          hideCustomizeNowButton={hideCustomizeNowButton}
          customizeButtonRef={intersectionRef}
          themeId={themeId}
          category={stationeryTemplateCategory}
          isCreatingDraft={isCreatingDraft}
          onCustomizeClick={onCardCustomizeClick}
        />
      </CardCustomizerTelemetryProvider>
    </CustomizationProvider>
  );
};
