import React, { useMemo } from 'react';
import { Flex, PopoverV2, TextV2, useDisclosure } from '@withjoy/joykit';
import { ArrowLeft, ArrowRight, ChevronDown } from '@withjoy/joykit/icons';
import { animationTransition } from '@shared/utils/animationTransition';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface GalleryPaginationProps {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

export const GalleryPagination = ({ currentPage, pageCount, onPageChange }: GalleryPaginationProps) => {
  const { t } = useTranslation('stationery');
  const { galleryPageChange } = useCardTelemetry();
  const tPagination = t('dashboard', 'designsGallery', 'pagination');
  const paginationPopover = useDisclosure();

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;

  const handleSetPage = (page: number) => {
    onPageChange(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavigateForward = () => {
    galleryPageChange({ action: 'navigateForward', currentPage: currentPage, nextPage: currentPage + 1 });
    handleSetPage(currentPage + 1);
  };

  const handleNavigateBackward = () => {
    galleryPageChange({ action: 'navigateBackward', currentPage: currentPage, nextPage: currentPage - 1 });
    handleSetPage(currentPage - 1);
  };

  const handleSpecificPageChange = (page: number) => {
    galleryPageChange({ action: 'navigateToPage', currentPage: currentPage, nextPage: page });
    paginationPopover.onClose();
    handleSetPage(page);
  };

  const pagesArray = useMemo(() => {
    return new Array(pageCount).fill(null).map((_, index) => index + 1);
  }, [pageCount]);

  return (
    <Flex gap={3} alignItems="center" justifyContent="center" paddingBottom={8}>
      <Flex
        transition={animationTransition('opacity')}
        as="button"
        alignItems="center"
        gap={2}
        opacity={isFirstPage ? 0.5 : 1}
        cursor={isFirstPage ? 'not-allowed' : 'pointer'}
        onClick={maybeApplyCallback(!isFirstPage, handleNavigateBackward)}
        height={9}
        paddingX={4}
      >
        <ArrowLeft size={16} />
        {tPagination.previous()}
      </Flex>
      <PopoverV2 isOpen={paginationPopover.isOpen} onOpen={paginationPopover.onOpen} onClose={paginationPopover.onClose} placement="bottom">
        <PopoverV2.Trigger>
          <Flex
            as="button"
            gap={3}
            height={9}
            paddingLeft={5}
            paddingRight={4}
            alignItems="center"
            justifyContent="center"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="mono3"
            borderRadius={2}
          >
            <TextV2>{tPagination.page({ page: currentPage, totalPages: pageCount })}</TextV2>
            <ChevronDown transition={animationTransition('transform')} transform={paginationPopover.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'} />
          </Flex>
        </PopoverV2.Trigger>
        <PopoverV2.Content>
          <Flex flexDirection="column" width={pxToRem(135)} maxHeight={pxToRem(200)} overflowY="auto">
            {pagesArray.map(page => (
              <Flex
                as="button"
                transition={animationTransition('background-color')}
                paddingX={5}
                paddingY={4}
                key={page}
                onClick={() => handleSpecificPageChange(page)}
                _hover={{ backgroundColor: 'mono2' }}
                borderRadius={2}
              >
                {page}
              </Flex>
            ))}
          </Flex>
        </PopoverV2.Content>
      </PopoverV2>
      <Flex
        transition={animationTransition('opacity')}
        as="button"
        alignItems="center"
        gap={2}
        opacity={isLastPage ? 0.5 : 1}
        cursor={isLastPage ? 'not-allowed' : 'pointer'}
        onClick={maybeApplyCallback(!isLastPage, handleNavigateForward)}
        height={9}
        paddingX={4}
      >
        {tPagination.next()}
        <ArrowRight size={16} />
      </Flex>
    </Flex>
  );
};
