import { useEffect, useMemo, useRef } from 'react';
import {
  StationeryTemplateCategoryEnum,
  useCardDashboardStationeryTemplatesContainerQuery,
  StationeryDesignGalleryFilterTagsFragment,
  usePrintDesignsGalleryEventInfoByEventHandleQuery
} from '@graphql/generated';

import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { StationeryTemplate } from './DesignsGallery.types';
import {
  BASIC_TEMPLATE_STATIONERY_TAG,
  PREMIUM_TEMPLATE_STATIONERY_TAG,
  WITHOUT_FOIL_STATIONERY_TAG,
  WITHOUT_PHOTO_STATIONERY_TAG,
  WITH_FOIL_STATIONERY_TAG,
  processInitialQueryData
} from './utils';
import { useApplyFiltersToTemplates } from './useApplyFiltersToTemplates';
import { FilterFacet } from './components/Filters/Filter.types';
import { useActivePrintFiltersVar } from './utils/activePrintFiltersCache';
import { useTranslation } from '@shared/core';
import { useDesignsGalleryHealth } from './DesignsGallery.health';

type UseDesignTemplatesArgs = {
  /**
   * Marking as optional to prepare for the future when card app is no longer tied to the admin view
   */
  eventId?: string;
  eventHandle?: string;
};

export type StationeryTemplateWithThemeJson = StationeryTemplate & { themeJSON: ThemeJson };

const themeIdWithOrientation = (template: StationeryTemplate) => {
  if (template.widthInches > template.heightInches) {
    return `wide ${template.themeId}`;
  }
  if (template.heightInches > template.widthInches) {
    return `tall ${template.themeId}`;
  }
  return template.themeId;
};

/*
 * This method allows setting order of top N items in the gallery based on array of themeIds coming from Amplitude Feature flag
 */
const setGalleryOrder = (templates: StationeryTemplate[], themeOrder: string[]): StationeryTemplate[] => {
  if (templates?.length < 1 || themeOrder?.length < 1) return templates;
  try {
    const themeLookup = new Map(themeOrder.map((themeId, index) => [themeId, index]));
    return [...templates].sort((a, b) => {
      const aIndex = themeLookup.get(themeIdWithOrientation(a)) ?? themeLookup.get(a?.themeId) ?? Number.POSITIVE_INFINITY;
      const bIndex = themeLookup.get(themeIdWithOrientation(b)) ?? themeLookup.get(b?.themeId) ?? Number.POSITIVE_INFINITY;
      return aIndex - bIndex;
    });
  } catch {
    console.error('Error sorting templates by printGalleryThemeDisplayOrder.  Using default sort order');
    return templates;
  }
};

const useFilterFacets = ({ stationeryTags, templates }: { stationeryTags: Maybe<StationeryDesignGalleryFilterTagsFragment[]>; templates: StationeryTemplate[] }) => {
  const { t } = useTranslation('stationery');
  const tFilters = t('dashboard', 'designsGallery', 'filters');

  const activePrintFilters = useActivePrintFiltersVar();
  const { tagNameToTemplates, photoTemplateCounts, premiumTemplateCounts, foilTemplatesCount } = useMemo(() => {
    const tagNameToTemplates: Record<string, StationeryTemplate[]> = {};
    // Need to manually count the number of templates that support + don't support photos
    const photoTemplateCounts = {
      with: 0,
      without: 0
    };

    const premiumTemplateCounts = {
      premium: 0,
      basic: 0
    };

    const foilTemplatesCount = {
      with: 0,
      without: 0
    };

    templates.forEach(template => {
      let templateSupportsPhoto = false;
      template.tags.forEach(tag => {
        if (tag === 'photo') {
          // Because there are more templates that aren't photo compared to those that are,
          // we only tagged the ones that have photo with `photo` tag.
          templateSupportsPhoto = true;
        }
        if (!tagNameToTemplates[tag]) {
          tagNameToTemplates[tag] = [];
        }
        tagNameToTemplates[tag].push(template);
      });

      if (templateSupportsPhoto) {
        photoTemplateCounts.with++;
      } else {
        photoTemplateCounts.without++;
      }

      if (template.premium || template.format === 'paper') {
        premiumTemplateCounts.premium++;
      } else {
        premiumTemplateCounts.basic++;
      }

      if (template.mappedTags.includes(WITH_FOIL_STATIONERY_TAG)) {
        foilTemplatesCount.with++;
      } else {
        foilTemplatesCount.without++;
      }
    });

    return { tagNameToTemplates, photoTemplateCounts, premiumTemplateCounts, foilTemplatesCount };
  }, [templates]);

  const { filterFacets } = useMemo(() => {
    const categoryToFacets: Record<string, FilterFacet> = {};
    stationeryTags?.forEach(tag => {
      if (tag.category === 'Styles' || tag.category === 'Colors' || tag.category === 'Photo') {
        if (!categoryToFacets[tag.category]) {
          categoryToFacets[tag.category] = {
            groupCode: tag.category,
            label: tag.category === 'Photo' ? 'Photo' : tag.category === 'Styles' ? 'Style' : 'Color',
            options: []
          };
        }

        const compositeCode = `${tag.category}:${tag.name}`;

        if (tagNameToTemplates[tag.name]?.length > 0) {
          if (tag.category === 'Photo') {
            // Special handling for photo category since non-photo templates are not tagged as such
            categoryToFacets[tag.category].options.push({
              compositeCode,
              isActive: !!activePrintFilters[compositeCode],
              label: tFilters.photo.with(),
              matchingItemCount: photoTemplateCounts.with
            });
            categoryToFacets[tag.category].options.push({
              compositeCode: WITHOUT_PHOTO_STATIONERY_TAG,
              isActive: !!activePrintFilters[WITHOUT_PHOTO_STATIONERY_TAG],
              label: tFilters.photo.without(),
              matchingItemCount: photoTemplateCounts.without
            });
          } else {
            categoryToFacets[tag.category].options.push({
              compositeCode: compositeCode,
              isActive: !!activePrintFilters[compositeCode],
              label: tag.displayLabel,
              matchingItemCount: tagNameToTemplates[tag.name]?.length || 0
            });
          }
        }
      }
    });

    categoryToFacets['Price'] = {
      groupCode: 'Price',
      label: tFilters.price.label(),
      options: [
        {
          compositeCode: PREMIUM_TEMPLATE_STATIONERY_TAG,
          isActive: !!activePrintFilters[PREMIUM_TEMPLATE_STATIONERY_TAG],
          label: tFilters.price.premium(),
          matchingItemCount: premiumTemplateCounts.premium
        },
        {
          compositeCode: BASIC_TEMPLATE_STATIONERY_TAG,
          isActive: !!activePrintFilters[BASIC_TEMPLATE_STATIONERY_TAG],
          label: tFilters.price.basic(),
          matchingItemCount: premiumTemplateCounts.basic
        }
      ]
    };

    if (foilTemplatesCount.with > 0) {
      categoryToFacets['Foil'] = {
        groupCode: 'Foil',
        label: tFilters.foil.label(),
        options: [
          {
            compositeCode: WITH_FOIL_STATIONERY_TAG,
            isActive: !!activePrintFilters[WITH_FOIL_STATIONERY_TAG],
            label: tFilters.foil.with(),
            matchingItemCount: foilTemplatesCount.with
          },
          {
            compositeCode: WITHOUT_FOIL_STATIONERY_TAG,
            isActive: !!activePrintFilters[WITHOUT_FOIL_STATIONERY_TAG],
            label: tFilters.foil.without(),
            matchingItemCount: foilTemplatesCount.without
          }
        ]
      };
    }

    Object.entries(categoryToFacets).forEach(([category, facet]) => {
      facet.options.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();
        return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
      });
    });

    return {
      filterFacets: [categoryToFacets['Styles'], categoryToFacets['Colors'], categoryToFacets['Photo'], categoryToFacets['Foil'], categoryToFacets['Price']].filter(
        maybeFacet => !!maybeFacet && maybeFacet.options.length > 0
      ) as FilterFacet[]
    };
  }, [
    stationeryTags,
    tFilters.price,
    tFilters.photo,
    tFilters.foil,
    activePrintFilters,
    premiumTemplateCounts.premium,
    premiumTemplateCounts.basic,
    foilTemplatesCount.with,
    foilTemplatesCount.without,
    tagNameToTemplates,
    photoTemplateCounts.with,
    photoTemplateCounts.without
  ]);

  return { filterFacets };
};

export const useDesignTemplates = (args: UseDesignTemplatesArgs) => {
  const { eventId, eventHandle } = args;
  const { currentCategory } = useCardCategoryContext();
  const [themeQueryParam] = useManagedSearchParam('theme');
  const themeQueryParamRef = useRef(themeQueryParam);
  const { value, payload } = useFeatureValue('printGalleryThemeDisplayOrder');

  const { onLoadComplete, onLoadFailure } = useDesignsGalleryHealth();

  const { applyFiltersToTemplates, deriveFilterCountFromTemplates } = useApplyFiltersToTemplates();

  const { data: eventInfoData } = usePrintDesignsGalleryEventInfoByEventHandleQuery({
    batchMode: 'fast',
    variables: {
      eventHandle: eventHandle || ''
    },
    fetchPolicy: 'cache-first',
    skip: !eventHandle
  });

  const shouldSkipFetchingDigitalDesignData = !(currentCategory === StationeryTemplateCategoryEnum.invitation || currentCategory === StationeryTemplateCategoryEnum.saveTheDate);

  const eventDesignThemeId = eventInfoData?.eventByName?.eventDesign?.theme.themeId;

  const { data: templateData, error } = useCardDashboardStationeryTemplatesContainerQuery({
    variables: {
      filter: {
        categories: [currentCategory],
        side: 'front'
      },
      // Requesting a number larger than what we have in the DB while we wait on
      // designs for pagination
      first: 20000,
      skipDigitalDesigns: shouldSkipFetchingDigitalDesignData,
      includeOptions: {
        includeFavoritesByEventIds: eventId ? [eventId] : undefined
      }
    },
    batchMode: 'fast',
    fetchPolicy: 'cache-first',
    ssr: false
  });

  useEffect(() => {
    if (error) {
      onLoadFailure(error);
    } else if (templateData?.stationeryTemplatesContainer) {
      onLoadComplete();
    }
  }, [error, templateData, onLoadFailure, onLoadComplete]);

  const templates = useMemo(() => {
    const mappedTemplates = processInitialQueryData(templateData, currentCategory);
    let sortedTemplates: StationeryTemplate[] = [...mappedTemplates];

    if (value === 'on') {
      if (Array.isArray(payload)) {
        sortedTemplates = setGalleryOrder(mappedTemplates, payload);
      } else if (payload && typeof payload === 'object') {
        // Expected format:
        // {
        //   "saveTheDate": [ ... ],
        //   "invitation": [ ... ],
        //   "enclosure": [
        //     "wide modern_monogram_navy",
        //     "tall upload_your_design",
        //     "weekend_events_blush",
        //     "tall modern_monogram_navy"
        //   ],
        //   "thankYou": [ ... ],
        //   "holiday": [ ... ]
        // }

        const themeIdListByCategory = payload as Record<string, string[]>;
        const themeIdList = themeIdListByCategory[currentCategory];
        if (Array.isArray(themeIdList)) {
          sortedTemplates = setGalleryOrder(mappedTemplates, themeIdList);
        }
      }
    }

    if (themeQueryParamRef.current || eventDesignThemeId) {
      // Sorting in place since we're already creating a new array above when we map the templates

      sortedTemplates.sort((a, b) => {
        // Prioritize the theme query param
        if (a.themeId === themeQueryParamRef.current) {
          return -1;
        } else if (b.themeId === themeQueryParamRef.current) {
          return 1;
        }

        // If no theme query param, prioritize the event design theme
        if (a.themeId === eventDesignThemeId) {
          return -1;
        } else if (b.themeId === eventDesignThemeId) {
          return 1;
        }

        return 0;
      });
    }

    return sortedTemplates;
  }, [templateData, currentCategory, eventDesignThemeId, value, payload]);

  const { filterFacets } = useFilterFacets({ stationeryTags: templateData?.stationeryTags, templates });

  return {
    filterMatchCounts: deriveFilterCountFromTemplates(templates),
    templates: applyFiltersToTemplates(templates),
    filterFacets,
    eventDesignThemeId,
    currentCategory
  };
};
