import globalWindow from '@shared/core/globals';
import { useEffect, useState } from 'react';

let shouldDeferInitialRender = !!globalWindow?.__INITIAL_APOLLO_STATE__;

/**
 * Because we can't load fonts when SSR, we need to delay the initial render of the preview.
 */
export const useSsrPreviewRenderDelay = () => {
  const [canRenderPreview, setCanRenderPreview] = useState(!shouldDeferInitialRender);

  useEffect(() => {
    if (!canRenderPreview) {
      setTimeout(() => {
        shouldDeferInitialRender = false;
        setCanRenderPreview(true);
      }, 500);
    }
  }, [canRenderPreview]);

  return { canRenderPreview };
};
