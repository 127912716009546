import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { VariablePriceDiscount } from '../useCardDiscountContext';
import { CardTileDiscountedPriceLabel } from './CardTileDiscountedPriceLabel';
import { useTranslation } from '@shared/core';

type CardTilePriceLabelProps = {
  paperPrice: ReturnType<VariablePriceDiscount['getPrice']>;
  premiumDigitalPrice: ReturnType<VariablePriceDiscount['getPrice']>;
  hasBasic?: boolean;
};

export const CardTilePriceLabel = (props: CardTilePriceLabelProps) => {
  const { paperPrice, premiumDigitalPrice, hasBasic } = props;
  const { t } = useTranslation('stationery');
  const tFilters = t('dashboard', 'designsGallery', 'filters');

  return (
    <Flex flexDirection="column" gap="4px" justifyContent="center">
      <div>
        {paperPrice.isEnabled ? (
          <CardTileDiscountedPriceLabel
            formatString="Paper"
            alignment="center"
            showCode={false}
            discountedPrice={paperPrice.discountedPrice}
            preDiscountPrice={paperPrice.originalPrice}
            sale={paperPrice.currentSale!}
          />
        ) : (
          <TextV2 typographyVariant="label2" margin="auto 0 auto 0">{`Paper ${paperPrice.originalPrice}`}</TextV2>
        )}
      </div>
      <div>
        {hasBasic ? (
          <TextV2 typographyVariant="label2" margin="auto 0 auto 0">
            {tFilters.price.basic()}
          </TextV2>
        ) : premiumDigitalPrice.isEnabled ? (
          <CardTileDiscountedPriceLabel
            formatString="Digital"
            alignment="center"
            showCode={false}
            discountedPrice={premiumDigitalPrice.discountedPrice}
            preDiscountPrice={premiumDigitalPrice.originalPrice}
            sale={premiumDigitalPrice.currentSale!}
          />
        ) : (
          <TextV2 typographyVariant="label2" margin="auto 0 auto 0">{`Digital ${premiumDigitalPrice.originalPrice}`}</TextV2>
        )}
      </div>
    </Flex>
  );
};
