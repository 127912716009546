import React, { useMemo, useState } from 'react';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { useHistory, useIdentify, useTranslation } from '@shared/core';
import { ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { SurveyOption, SurveyWrapper } from './Survey.styled';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { useAuth } from '@shared/components/AuthProvider';
import { wrapWithSurveyHealthProvider } from './Survey.health';
import { SurveyItems, SurveyOptions } from '@apps/createWedding/routes/Survey/Survey.types';
import { useSurveyController } from '@apps/createWedding/routes/Survey/Survey.controller';
import { useEventState } from '@apps/createWedding/EventState';
import queryString from 'query-string';
import { useNativeState } from '@shared/core/native';
import { dispatchWeddingCreationResultsToNativeApps } from '@shared/core/native/utils';

const Survey = wrapWithSurveyHealthProvider(() => {
  const { addEventCreatedListener } = useEventState();
  const history = useHistory();
  const { t2 } = useTranslation('createWedding');
  const surveyI18n = t2('survey');
  const telemetry = useCreateWeddingTelemetry();
  const { identify } = useIdentify();
  const { currentUser } = useAuth();
  const { isNative } = useNativeState();
  const { setSelectedOptions, selectedOptions, handleEventCreated, surveyOptions, surveyOptionIds } = useSurveyController(currentUser.profile?.id ?? '');
  const selection = useMemo(
    () =>
      Object.keys(selectedOptions).filter(option => {
        if (option in selectedOptions) {
          return !!selectedOptions[option as SurveyItems];
        }

        return false;
      }),
    [selectedOptions]
  );

  const [isProceedButtonLoading, setIsProceedButtonLoading] = useState(false);

  const toggleOption = (id: SurveyOptions['id']) => {
    const newValue = !selectedOptions[id];
    setSelectedOptions({ ...selectedOptions, [id]: newValue });
  };

  const sendMessageToNativeApps = (eventId?: string) => {
    if (isNative) {
      if (!eventId) {
        telemetry.trackError('SurveyMessageToNativeApps', 'Message send attempt to native apps failed; eventId does not exist.');
        return;
      }

      // If the user doesn't select any, all are sent.
      const optsSelected = selection.length ? selection : surveyOptionIds;
      dispatchWeddingCreationResultsToNativeApps(eventId, optsSelected);
    }
  };

  /**
   *  If no survey item is selected, all items are sent.
   * @returns
   */
  const proceedFurther = () => {
    const queryParams = queryString.stringify({ onboardingInterests: selection.length ? selection : surveyOptionIds });
    return history.push(`/${CREATE_WEDDING_APP_NAME}/tying-the-knot?${queryParams}`);
  };

  const onSurveySkip = () => {
    identify({ initialInterests: [] });
    addEventCreatedListener(async (event, {}) => {
      sendMessageToNativeApps(event.createEventV0301?.id);
      telemetry.surveySubmitClicked({
        action: 'SurveySkip',
        label: surveyI18n.cta.skip,
        selectedOptions: surveyOptionIds,
        email: currentUser?.profile?.email,
        eventId: event.createEventV0301?.eventId
      });
    });

    proceedFurther();
  };

  const onSurveyProceed = () => {
    identify({ initialInterests: selection });
    setIsProceedButtonLoading(true);
    addEventCreatedListener(async (event, {}) => {
      let selectionToSend = selection;
      if (!selectionToSend.length) {
        selectionToSend = surveyOptionIds;
      }
      sendMessageToNativeApps(event.createEventV0301?.id);
      telemetry.surveySubmitClicked({
        action: 'SurveyProceed',
        label: surveyI18n.cta.proceed,
        selectedOptions: selectionToSend,
        email: currentUser?.profile?.email,
        eventId: event.createEventV0301?.eventId
      });

      await handleEventCreated(event);
    });

    proceedFurther();
  };

  return (
    <SurveyWrapper spacing={8}>
      <SpacingStack spacing={1}>
        <TextV2 textAlign="center" as="h1" typographyVariant="hed6">
          {surveyI18n.title}
        </TextV2>
        <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
          {surveyI18n.subtitle}
        </TextV2>
      </SpacingStack>
      <SpacingStack spacing={5}>
        {surveyOptions.map(({ labelKey, SurveyOptionIcon, id }) => {
          return (
            <SurveyOption tabIndex={0} key={id} onClick={() => toggleOption(id)} selected={!!selectedOptions[id]}>
              <SurveyOptionIcon />
              <TextV2 marginLeft={5} typographyVariant="button2">
                {surveyI18n[labelKey as keyof typeof surveyI18n]}
              </TextV2>
            </SurveyOption>
          );
        })}
      </SpacingStack>
      <SpacingStack spacing={5}>
        <ButtonV2 shape="rounded" onClick={onSurveyProceed} loading={isProceedButtonLoading} disabled={isProceedButtonLoading}>
          {surveyI18n.cta.proceed}
        </ButtonV2>
        <ButtonV2 onClick={onSurveySkip} variant="ghost" color="mono14">
          {surveyI18n.cta.skip}
        </ButtonV2>
      </SpacingStack>
    </SurveyWrapper>
  );
});

export default Survey;
