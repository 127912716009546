import { templateToTitle } from '@apps/card/Card.utils';
import { CardPdpSeoDataQuery, StationeryTemplateCategoryEnum, useCardPdpSeoDataQuery } from '@graphql/generated';
import { Seo } from '@shared/components/Seo';
import React from 'react';

type PdpSeoProps = {
  themeId: string;
  currentCategory: StationeryTemplateCategoryEnum;
};

const TEMPLATE_CATEGORY_TO_TITLE = {
  [StationeryTemplateCategoryEnum.saveTheDate]: 'Save the Date',
  [StationeryTemplateCategoryEnum.invitation]: 'Invitation',
  [StationeryTemplateCategoryEnum.thankYou]: 'Thank You',
  [StationeryTemplateCategoryEnum.holiday]: 'Holiday',
  [StationeryTemplateCategoryEnum.enclosure]: 'Enclosure'
};

type SeoContentProps = {
  data: CardPdpSeoDataQuery['stationeryTemplatesByThemeId'][0];
  currentCategory: StationeryTemplateCategoryEnum;
};

const SeoContent = ({ data, currentCategory }: SeoContentProps) => {
  const templateTitle = templateToTitle(data);
  const title = `Shop the '${templateTitle}' ${
    (currentCategory in TEMPLATE_CATEGORY_TO_TITLE && TEMPLATE_CATEGORY_TO_TITLE[currentCategory as keyof typeof TEMPLATE_CATEGORY_TO_TITLE]) || ''
  } Card | Joy`;
  return <Seo title={title} ogImage={data.thumbnailUrl || undefined} />;
};

export const PdpSeo = (props: PdpSeoProps) => {
  const { themeId, currentCategory } = props;
  const { data } = useCardPdpSeoDataQuery({ variables: { themeId, category: currentCategory }, batchMode: 'fast' });

  const templateData = data?.stationeryTemplatesByThemeId[0];

  return <>{templateData && <SeoContent data={templateData} currentCategory={currentCategory} />}</>;
};
