import React from 'react';
import { Box, BoxProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';

export const LearnMoreLink: React.FC<BoxProps & { url: string }> = ({ url, ...props }) => {
  const { t } = useTranslation('stationery');

  return (
    <Box as="a" href={url} target="_blank" fontSize={pxToRem(13)} textDecoration="underline" textDecorationColor="#D9D9D9" {...props}>
      {t('dashboard', 'designsGallery', 'learnMore')()}
    </Box>
  );
};
