import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { StationeryDraftFormat } from '@graphql/generated';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { InteractiveCard } from '../../../GalleryTile/InteractiveCard';
import { Flex } from '@withjoy/joykit';
import { getDimensions } from '@apps/card/Card.utils';

type InteractiveCardPreviewProps = {
  cardJson: ThemeJson;
  cardFront: ThemeJson['card']['front'];
  padding?: number;
};

export const InteractiveCardPreview = (props: InteractiveCardPreviewProps) => {
  const { cardJson, cardFront, padding = 25 } = props;

  const revealContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    const container = revealContainerRef.current;
    if (!container) {
      return;
    }

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      if (container) {
        const width = container.getBoundingClientRect().width;
        const height = container.getBoundingClientRect().height;
        setContainerWidth(width);
        setContainerHeight(height);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, []);

  const dimensions = getDimensions(cardJson.card);
  const cardSizeX = dimensions.width;
  const cardSizeY = dimensions.height;
  const cardAspectRatio = cardSizeY / cardSizeX;
  const shape = cardJson.customizations.shape;

  let cardWidth = (containerWidth ?? 0) - padding;
  let cardHeight = cardWidth * cardAspectRatio;

  if (cardHeight > cardWidth) {
    cardHeight = (containerHeight ?? 0) - padding;
    cardWidth = cardHeight / cardAspectRatio;
  }

  return (
    <Flex justifyContent="center" alignItems="center" width="100%" height="100%" position="relative" marginX="auto" marginY="auto" ref={revealContainerRef}>
      <InteractiveCard width={cardWidth} height={cardHeight} format={StationeryDraftFormat.digital} page={cardFront} shape={shape} />
    </Flex>
  );
};
