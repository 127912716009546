import React from 'react';
import { useWeddingAlreadyExistsController } from '@apps/createWedding/routes/WeddingAlreadyExists/WeddingAlreadyExists.controller';
import { useTranslation } from '@shared/core';
import { ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { ButtonWrapper } from '@apps/createWedding/routes/WeddingAlreadyExists/WeddingAlreadyExists.styles';
import { wrapWithWeddingAlreadyExistsHealthProvider } from './WeddingAlreadyExists.health';

export const WeddingAlreadyExists = wrapWithWeddingAlreadyExistsHealthProvider(() => {
  const { website, signOut } = useWeddingAlreadyExistsController();
  const { t2 } = useTranslation('createWedding');

  const translations = t2('weddingAlreadyExists');

  return (
    <SpacingStack spacing={5} alignItems="center">
      <TextV2 textAlign="center" typographyVariant="hed6">
        {translations.alreadyMade}
      </TextV2>
      <TextV2 textAlign="center" typographyVariant="hed4">
        withjoy.com/{website}
      </TextV2>
      <TextV2 textAlign="center" typographyVariant="body1">
        {translations.onlyOneWebsiteSupported}
      </TextV2>
      <TextV2 textAlign="center" color="gray6" typographyVariant="label2">
        {translations.createSecondSite}
      </TextV2>
      <ButtonWrapper>
        <a href={`${website}/edit`}>
          <ButtonV2 shape="rounded" fullWidth intent="primary">
            {translations.proceedWebsite}
          </ButtonV2>
        </a>
        <ButtonV2 shape="rounded" onClick={signOut} intent="primary">
          {translations.logout}
        </ButtonV2>
      </ButtonWrapper>
    </SpacingStack>
  );
});
